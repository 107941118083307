<template>
  <ion-menu
    content-id="main"
    menuId="firstMenu"
    side="end"
    swipe-gesture="false"
    type="overlay"
  >
    <ion-content>
      <div class="menuSpCont">
        <div class="menuSpHeader header_top">
          <div class="menuSpHeaderButton">
            <img
              @click="callButtonMenuSp"
              :src="iconoCerrarGris"
              alt=""
              srcset=""
              class="cerrar"
            />
          </div>
        </div>
        <div class="menuSp">
          <div
            class="menuSpListItem text_basico f_w_500 basic"
            @click="callMisDatos"
          >
            {{ t("app.menuSp.mis_datos") }}
          </div>
          <div
            class="menuSpListItem text_basico f_w_500 basic"
            @click="callMisDestinatarios"
          >
            {{ t("app.menuSp.mis_destinatarios") }}
          </div>
          <div
            class="menuSpListItem text_basico f_w_500 basic"
            @click="callMisTransac"
          >
            {{ t("app.menuSp.mis_transacciones") }}
          </div>
          <div
            class="menuSpListItem text_basico f_w_500"
            :class="toggleAjustes ? 'sp' : 'basic'"
            @click="toggleAjustes = !toggleAjustes"
          >
            {{ t("app.menuSp.ajustes") }}
            <span
              ><img
                :src="
                  toggleAjustes ? iconChevronAbajoAzul : iconChevronAbajoRojo
                "
                alt=""
                srcset=""
            /></span>
          </div>
          <div v-if="toggleAjustes">
            <!-- <div
              class="menuSpListItem menuSp_sub f_w_500 "
              :class="toggleAjustes ? 'sp' : 'basic'"
              @click="callMisIdioma"
            >
              {{ t("app.menuSp.idioma") }}
            </div> -->
            <div
              class="menuSpListItem menuSp_sub f_w_500 elemento_deshabilitado"
              :class="toggleAjustes ? 'sp' : 'basic'"
              @click.stop="presentAlert_i18n"
            >
              {{ t("app.menuSp.idioma") }}
            </div>
            <div
              class="menuSpListItem menuSp_sub f_w_500"
              :class="toggleAjustes ? 'sp' : 'basic'"
              @click="callMyPass"
            >
              {{ t("app.menuSp.change_pass") }}
            </div>
          </div>
          <div
            class="menuSpListItem text_basico f_w_500 basic"
            @click="callMyCentroAyuda"
          >
            {{ t("app.menuSp.centro_ayuda") }}
          </div>
        </div>

        <div class="menuSpListCerrar menuSp_sub f_w_500" @click="cerrar_sesion">
          {{ t("app.menuSp.salir") }}
        </div>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script>
import {
  menuController,
  IonMenu,
  IonContent,
  modalController,
  alertController,
} from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
// import cerrar_sesion_modal from "./cerrar_sesion_modal.vue";
import estas_seguro from "../../views/modales/estas_seguro.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "navigation",
  components: {
    IonMenu,
    IonContent,
  },
  data() {
    return {
      iconChevronAbajoRojo: require("../../assets/imgs/menuSp/iconChevronAbajoRojo.svg"),
      iconChevronAbajoAzul: require("../../assets/imgs/menuSp/iconChevronAbajoAzul.svg"),
      iconoCerrarGris: require("../../assets/imgs/icono_cerrarGris.svg"),
      toggleAjustes: false,
      plataforma_local:"",
    };
  },
  computed: {
    ...mapState([
      "plataforma",
      "nav_stack",
      "menuSpDesde",
      "idiomaPreferencia",
      "widthScreen",
      "resp_signOff",
      "url_base_web",
    ]),
  },
  methods: {
    ...mapMutations([
      "updateBloquearNavegacion",
      "updateMenuSpDesde",
      "updateMenuSpDesde_menu_sp",
      "set_nav_stack",
      "setshowSpinner",
    ]),
    ...mapActions(["signOff", "traer_ocupaciones", "traer_regiones"]),

    callButtonMenuSp() {
      menuController.close("firstMenu");
    },
    async callMisDatos() {
      menuController.close("firstMenu");
      this.setshowSpinner(true);
      await this.traer_ocupaciones();
      await this.traer_regiones("152");
      this.updateMenuSpDesde_menu_sp(true);

      this.set_nav_stack({ desde: [this.menuSpDesde.desde], menuSp: true });
      this.setshowSpinner(false);
      this.$router.push({ name: "misDatos", replace: true });
    },
    callMisDestinatarios() {
      menuController.close("firstMenu");
      this.updateMenuSpDesde_menu_sp(true);
      this.set_nav_stack({ desde: [this.menuSpDesde.desde], menuSp: true });
      this.$router.push({ name: "destinatarios", replace: true });
    },
    callMisTransac() {
      menuController.close("firstMenu");
      this.updateMenuSpDesde_menu_sp(true);
      this.set_nav_stack({ desde: [this.menuSpDesde.desde], menuSp: true });
      this.$router.push({ name: "misTransac", replace: true });
    },
    callMisIdioma() {
      menuController.close("firstMenu");
      this.updateMenuSpDesde_menu_sp(true);
      this.set_nav_stack({ desde: [this.menuSpDesde.desde], menuSp: true });
      this.$router.push({ name: "idioma", replace: true });
    },
    callMyPass() {
      menuController.close("firstMenu");
      this.updateMenuSpDesde_menu_sp(true);
      this.set_nav_stack({ desde: [this.menuSpDesde.desde], menuSp: true });
      this.$router.push({ name: "myDataRecoverPass", replace: true });
    },
    callMyCentroAyuda() {
      menuController.close("firstMenu");
      this.updateMenuSpDesde_menu_sp(true);
      this.set_nav_stack({ desde: [this.menuSpDesde.desde], menuSp: true });
      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },
    async presentAlert_i18n() {
      let alert = await alertController.create({
        // header: this.title,
        subHeader: this.t("app.logIn.i18n_proximamente"),
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();
    },
    async cerrar_sesion() {
      this.updateBloquearNavegacion(true);
      // console.log(this.widthScreen);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.menuSp.seguro_cerrar_sesion"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "gris_claro",
          text: "",
          btnText_1: this.t("app.menuSp.salir"),
          btnText_2: "", //Volver
          colorIcon: "rojoOscuro",
          // ancho: "250"
        },
        cssClass:
          this.widthScreen < 600
            ? [
                "myModalAbandonar",
                "myModalAbandonar_ancho_80",
                "myModalAbandonar_alto_50",
              ]
            : [
                "myModalAbandonar",
                "myModalAbandonar_ancho_40",
                "myModalAbandonar_alto_40",
              ],
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
        // swipeToClose: true,
        // mode: "ios",
      });

      modal.onDidDismiss().then(async (data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          menuController.close("firstMenu");
          this.setshowSpinner(true);
          this.plataforma_local = this.plataforma;
          this.signOff();
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });

      return modal.present();
    },
  },
  watch: {
    resp_signOff() {
      if (this.resp_signOff.status) {
        if (this.resp_signOff.resp) {
          this.updateMenuSpDesde_menu_sp(false);
          this.set_nav_stack({ desde: [], menuSp: false });
          if (this.plataforma_local == "web") {
            // console.log(`${this.url_base_web}/${this.idiomaPreferencia.cod}/`);
            // prompt(`${this.url_base_web}/${this.idiomaPreferencia.cod}/`);
            window.location.replace(
              `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
            );
          } else {
            this.$router.push({ name: "logIn", replace: true });
          }
        }
      }
    },
  },
};
</script>
<style>
ion-menu {
  --min-width: 70%;
  --max-width: 70%;
  --width: 70%;
}
.menuSpHeader {
  /* padding-top: 2.75rem; */
  padding-right: 2.25rem;
  text-align: right;
}
.menuSp {
  margin-top: 4.5rem;
  padding-left: 1rem;
}
.menuSpCont {
  position: relative;
  height: 95%;
}
.menuSpListItem {
  margin-top: 1.5625rem;
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px; */
  text-transform: uppercase;
  cursor: pointer;
}
.menuSp .basic {
  color: #801b1c;
}
.menuSp .sp {
  color: #614ad3;
}
.menuSpListItem span {
  padding-left: 1rem;
}
.menuSpListCerrar {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-bottom: 2.3125rem;
  padding-left: 1rem;
  cursor: pointer;
  text-decoration-line: underline;
  color: #787878;
}
.menuSp_sub {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.menuSpHeaderButton {
  width: 2rem;
  margin-right: 0;
  margin-left: auto;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  ion-menu {
    --min-width: 40%;
    --max-width: 40%;
    --width: 40%;
  }
}
</style>