<template>
  <ion-modal> </ion-modal>
  <div class="abandonarContainer2">
    <div class="abandonarCerrar">
      <headerCerrar @cancelar="cerrarAlertModal" :colorIcon="colorIcon" />
    </div>

    <div class="abandonar_body">
      <div
        class="abandonarTitle title"
        :class="
          color_title == 'rojo'
            ? 'rojo'
            : color_title == 'azul'
            ? 'azul'
            : 'gris'
        "
      >
        {{ title }}
      </div>
      <div class="abandonarText text_basico f_w_400" v-if="text != ''">
        {{ text }}
      </div>

      <div class="abandonarBtn_separador"></div>
      <div class="abandonarBtn" v-if="btnText_1 != ''">
        <btnAction
          :textBtn="btnText_1"
          :disabled="false"
          @action="call_action_1"
          :colorBack="color_btn_1"
          colorText="blco"
          estadoBtn="active"
        />
      </div>
      <div class="abandonarBtn" v-if="btnText_2 != ''">
        <btnAction
          :textBtn="btnText_2"
          :disabled="false"
          @action="call_action_2"
          :colorBack="color_btn_2"
          colorText="blco"
          estadoBtn="active"
        />
      </div>
    </div>
  </div>
</template>

<script>
import btnAction from "../../components/generales/btnAction.vue";
import headerCerrar from "../../components/generales/headerCerrar.vue";
import { modalController } from "@ionic/vue";
import { mapMutations } from "vuex";

export default {
  props: {
    title: String,
    color_title: String,
    color_btn_1: String,
    color_btn_2: String,
    text: String,
    btnText_1: String,
    btnText_2: String,
    colorIcon: String,
  },
  components: {
    btnAction,
    headerCerrar,
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),

    cerrarAlertModal() {
      modalController.dismiss();
    },
    call_action_1() {
      this.setshowSpinner(false);
      modalController.dismiss({ action: "action_1" });
    },
    call_action_2() {
      this.setshowSpinner(false);
      modalController.dismiss({ action: "action_2" });
    },
  },
};
</script>

<style>
/* .abandonarContainer {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
} */
.abandonarContainer2 {
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  border-radius: 20px;
}
.rojo {
  color: var(--customA-801B1C);
}
.gris {
  color: var(--customH-4C4C4C);
}
.azul {
  color: var(--customC-614AD3);
}

.abandonarTitle {
  margin-top: 2rem;

  text-align: center;
  color: #801b1c;
}

.abandonarText {
  margin-top: 1.7rem;
  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #787878;
}
.abandonarBtn_separador {
  min-height: 2rem;
  height: 2rem;
}
.abandonarBtn {
  margin-top: 1.5rem;
  width: 100%;
}
.abandonar_body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
}
</style>