<template>
  <div class="btn_action_big_cont">
    <div class=" msg_completar" :class="show_msg_completar? 'msg_completar_transition' : ''" > 
      <!-- v-if="show_msg_completar" -->
      {{ msg_completar }}
    </div>
    <div class="btnActionFondo" :style="btn_container" @click="actionLocal">

      <div class="btnActionBtn" :style="btn_text">
        {{ textBtn }}
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapMutations } from "vuex";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    textBtn: { type: String },
    colorBack: String,
    colorText: String,
    estadoBtn: String,
    msg_completar: String,
  },
  data() {
    return {
      btnActive: true,
      // msg_completar: "",
      show_msg_completar: false,
    };
  },
  computed: {
    btn_container() {
      let estilo = {
        background: "",
        border: "none",
      };
      if (this.colorBack == "rojo") {
        if (this.estadoBtn == "react") {
          estilo.background = "#FFFFFF";
          estilo.border = "1px solid #801b1c";
        } else if (this.estadoBtn == "inactive") {
          estilo.background = "#C4C4C4";
          estilo.border = "1px solid #C4C4C4";
        } else if (this.estadoBtn == "active") {
          estilo.background = "#801B1C";
          estilo.border = "1px solid #801B1C";
        }
      } else if (this.colorBack == "azul") {
        if (this.estadoBtn == "react") {
          estilo.background = "#FFFFFF";
          estilo.border = "1px solid #614ad3";
        } else if (this.estadoBtn == "inactive") {
          estilo.background = "#C4C4C4";
          estilo.border = "1px solid #C4C4C4";
        } else if (this.estadoBtn == "active") {
          estilo.background = "#614ad3";
          estilo.border = "1px solid #614ad3";
        }
      } else if (this.colorBack == "gris") {
        if (this.estadoBtn == "react") {
          estilo.background = "#FFFFFF";
          estilo.border = "1px solid #787878";
        } else if (this.estadoBtn == "inactive") {
          estilo.background = "#C4C4C4";
          estilo.border = "1px solid #C4C4C4";
        } else if (this.estadoBtn == "active") {
          estilo.background = "#C4C4C4";
          estilo.border = "1px solid #C4C4C4";
        }
      }

      if (!this.btnActive) {
        if (this.colorBack == "rojo") {
          estilo.background = "#FFFFFF";
          estilo.border = "1px solid #801b1c";
        } else if (this.colorBack == "azul") {
          estilo.background = "#FFFFFF";
          estilo.border = "1px solid #614ad3";
        } else if (this.colorBack == "gris") {
          estilo.background = "#FFFFFF";
          estilo.border = "1px solid #787878";
        }
      }

      return estilo;
    },
    btn_text() {
      let estilo = { color: "#FFFFFF" };
      if (this.estadoBtn == "react") {
        if (this.colorBack == "azul") {
          estilo.color = "#614ad3";
        } else if (this.colorBack == "rojo") {
          estilo.color = "#801B1C";
        } else if (this.colorBack == "gris") {
          estilo.color = "#787878";
        }
      }
      if (!this.btnActive) {
        if (this.colorBack == "azul") {
          estilo.color = "#614ad3";
        } else if (this.colorBack == "rojo") {
          estilo.color = "#801B1C";
        } else if (this.colorBack == "gris") {
          estilo.color = "#787878";
        }
      }
      return estilo;
    },
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),

    actionLocal() {
      
      if (this.estadoBtn == "active") {
        this.setshowSpinner(true);
        this.$emit("action");
        this.btnActive = false;
        setTimeout(() => {
          this.btnActive = true;
        }, 150);
      } else {
        // this.msg_completar = "Completar formulario";
        this.show_msg_completar = true;
        setTimeout(() => {
          this.show_msg_completar = false;
        }, 2000);
      }
    },
  },
};
</script>

<style>
.btnActionFondo {
  width: 100%;
  border-radius: 10px;
  height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  cursor: pointer;
}

.btnActionBtn {
  width: 100%;
  text-align: center;
  background: transparent;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 1px;
}

.msg_completar {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 1px;
  color: white;
  padding-bottom: 0.5rem;
  height: 0;
  transition: all 0.5s ease-in;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.msg_completar_transition{
  height: 2.3rem;
  color: #801B1C;
  
}

.btnActionFondoRojo {
  background-color: #801b1c;
}

.btnActionFondoAzul {
  background-color: #614ad3;
}

.btnActionFondoGris_claro {
  background-color: #c4c4c4;
}

.btn_border_rojo {
  border: 1.5px solid #801b1c;
}

.btn_border_azul {
  border: 1.5px solid #614ad3;
}

.react {
  color: white;
}

.inactive {
  color: white;
}

.active_azul {
  color: white;
}

.active_rojo {
  color: white;
}
</style>