// /* eslint-disable no-console */

// import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready () {
//       console.log(
//         'App is being served from cache by a service worker.\n' +
//         'For more details, visit https://goo.gl/AFskqB'
//       )
//     },
//     registered () {
//       console.log('Service worker has been registered.')
//     },
//     cached () {
//       console.log('Content has been cached for offline use.')
//     },
//     updatefound () {
//       console.log('New content is downloading.')
//     },
//     updated () {
//       console.log('New content is available; please refresh.')
//     },
//     offline () {
//       console.log('No internet connection found. App is running in offline mode.')
//     },
//     error (error) {
//       console.error('Error during service worker registration:', error)
//     }
//   })
// }

/* eslint-disable no-console */

import { register } from 'register-service-worker';

function implementar() {
      if (process.env.NODE_ENV === 'production') {
        register(`${process.env.BASE_URL}service-worker.js`, {
          ready() {
            console.log(
              'App is being served from cache by a service worker.\n' +
              'For more details, visit https://goo.gl/AFskqB'
            );
          },
          registered(e) {
            console.log('Service worker has been registered.');
            window.dispatchEvent(
              new CustomEvent('swUpdated', { detail: e })
            );
          },
          cached() {
            console.log('Content has been cached for offline use.');
            // self.parent.postMessage({ msg: 'newContentCached' });
            window.dispatchEvent(
              new CustomEvent('newContentCached', { detail: 'e' })
            );
          },
          updatefound() {
            console.log('New content is downloading.')
          },
          updated() {
            console.log('New content is available; please refresh.');
            // self.parent.postMessage({ msg: 'actualiza' });
            window.dispatchEvent(
              new CustomEvent('actualiza', { detail: 'e' })
            );
          },
          offline() {
            console.log('No internet connection found. App is running in offline mode.')
          },
          error(error) {
            console.error('Error during service worker registration:', error)
          }
        })
      }
    }


  export default   implementar;

