<template>
  <div class="spinnerContainer">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- <div></div> -->
</template>

<script>
// import { loadingController } from "@ionic/vue";
import { mapState } from "vuex";

export default {
  name: "spinner",
  data() {
    return {
      loading: null,
      montado:false
    };
  },
  computed: {
    ...mapState(["showSpinner"]),
  },
  methods: {
    // async showLoading() {
    //   this.loading = await loadingController.create({
    //     message: "Cargando ...",
    //     mode: "ios",
    //     duration: 0,
    //     cssClass: "custom-loading",
    //   });

    //   if (this.showSpinner){
    //     this.loading.present();
    //   }else{
    //     this.loading = null;
    //     this.montado = false;
    //   }
      
    // },
  },
  // watch: {
  //   async showSpinner() {
  //     // console.log(this.showSpinner);
  //     if (!this.showSpinner) {
  //       if (this.loading) {
  //         this.loading.dismiss();
  //         this.loading = null;
  //         this.montado = false;
  //       }
  //     } else {
  //       if (!this.loading && !this.montado){
  //       this.montado = true;
  //       await this.showLoading();
  //       }
  //     }
  //   },
  // },
  watch: {
    // async showSpinner() {
    //   if (!this.showSpinner) {
    //     console.log("1");
    //     if (this.loading) {
    //       console.log("3");
    //       this.loading.dismiss();
    //       this.loading = null;
    //       this.montado = false;
    //     }
    //   } else {
    //     console.log("2");
    //     if (!this.loading && ! this.montado){
    //     this.montado = true;
    //     await this.showLoading();
    //     }
    //   }
    // },
  },
  mounted() {
    // this.showLoading();
    // console.log("---------------------------------");
  },
  beforeUnmount() {
    // if(this.loading)this.loading.dismiss();
  },
};
</script>

<style scoped>
.spinner-border {
  display: block;
  margin: auto;
}
.spinnerContainer {
  height: 100vh;
  width: 100vw;
  background: var(--customF-C4C4C4);
  display: flex;
  align-content: center;
  justify-items: center;
}
</style>