<template>
  <ion-app @click="setClickFocusOut">
    <div @click="setClickFocusOut">
      <menuEspecial />
      <spinner class="spinnerLocal" v-if="showSpinner" />
      <!-- <spinner class="spinnerLocal" /> -->
      <ion-router-outlet id="main" />
    </div>
  </ion-app>
</template>

<script>
import { IonRouterOutlet, IonApp, alertController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import spinner from "@/components/spinner.vue";
import menuEspecial from "@/components/menuSp/menuEspecial.vue";

export default {
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    spinner,
    menuEspecial,
  },
  data() {
    return {
      updateAvailable: false,
      objSW: null,
    };
  },
  computed: {
    ...mapState([
      "obj_gf_base",
      "focusGlobal",
      "idiomaPreferencia",
      "configuracion",
      "showSpinner",
      "cookie_domain",
      "to_web",
      "data_mostrar_error",
    ]),
    idiomas_disponibles() {
      let aux = [];
      if (
        this.configuracion &&
        this.configuracion.data &&
        this.configuracion.data.auxiliares &&
        this.configuracion.data.auxiliares.idiomas
      ) {
        aux = this.configuracion.data.auxiliares.idiomas;
      }
      return aux;
    },
    data_inicial() {
      let aux = false;
      if (
        this.obj_gf_base &&
        this.obj_gf_base.paises &&
        this.obj_gf_base.data_base
      ) {
        aux = true;
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "set_state_inicial",
      "updateFocus",
      "updateIdiomaPreferencia",
      "setHeigthScreen",
      "setWidthScreen",
      "set_plataforma",
      "updateBloquearNavegacion",
      "set_to_web",
      "set_data_mostrar_error",
      "set_url_base_web",
      "set_url_base_app",
    ]),
    ...mapActions([
      "traer_paises",
      "traer_datos_simulacion",
      "create_config_others",
      "check_version",
      "guardar_datos_dinamicos",
    ]),
    setClickFocusOut() {
      if (!this.focusGlobal) this.updateFocus(true);
    },
    call_setHeigthScreen() {
      this.setHeigthScreen(window.innerHeight);
      // this.heigthScreen = window.innerHeight;
    },
    call_setWidthScreen() {
      this.setWidthScreen(window.innerWidth);
    },

    async guardar_datos() {
      // if(performance.getEntriesByType("navigation")[0].type == "reload"){
      await this.guardar_datos_dinamicos();
      console.log(">>>", performance.getEntriesByType("navigation")[0].type);
      // }
    },

    async mostrar_mensaje_error(data) {
      let alert = await alertController.create({
        header: data.title,
        subHeader: data.text,
        buttons: ["Ok"],
        backdropDismiss: false,
      });
      await alert.present();
      this.set_data_mostrar_error(null);
    },
  },
  watch: {
    data_inicial() {
      if (this.data_inicial) {
        this.create_config_others();
      }
    },
    // idiomas_disponibles() {
    //   if (this.idiomas_disponibles.length > 0) {
    //     const codigoIdioma = localStorage.getItem("idiomaPreferencia");
    //     if (codigoIdioma) {
    //       let selectedIdiomaObj =
    //         this.configuracion.data.auxiliares.idiomas[0].find((idioma) => {
    //           return idioma[0] == codigoIdioma;
    //         });
    //       this.updateIdiomaPreferencia({
    //         val: parseInt(codigoIdioma),
    //         text: selectedIdiomaObj[1],
    //         cod: selectedIdiomaObj[2],
    //       });
    //       this.locale = selectedIdiomaObj[2];
    //     } else {
    //       let langBrowser = navigator.language || navigator.userLanguage;
    //       let langBrowserArray = langBrowser.split("-");
    //       const codigoIdioma = langBrowserArray[0];
    //       let selectedIdiomaObj =
    //         this.configuracion.data.auxiliares.idiomas[0].find((idioma) => {
    //           return idioma[2] == codigoIdioma;
    //         });

    //       this.updateIdiomaPreferencia({
    //         val: selectedIdiomaObj[0],
    //         text: selectedIdiomaObj[1],
    //         cod: selectedIdiomaObj[2],
    //       });
    //       this.locale = selectedIdiomaObj[2];
    //     }
    //   }
    // },
    data_mostrar_error() {
      // console.log(this.data_mostrar_error);
      if (this.data_mostrar_error != null) {
        this.mostrar_mensaje_error(this.data_mostrar_error);
      }
    },
  },
  async created() {
    let x = document.cookie;
    let galletas = x.split(";");

    let reloading = false;
    for (let index = 0; index < galletas.length; index++) {
      const element = galletas[index];
      let item = element.split("=");

      if (item[0].trim() == "gf_reloading") {
        if (item[1] == "1") {
          reloading = true;
        }
      }
    }

    if (!reloading) {
      // console.log("Primera carga");
      this.set_state_inicial();
      this.traer_paises();
      this.traer_datos_simulacion();
      this.setHeigthScreen(window.innerHeight);
    } else {
      // console.log("Reloading");
      this.traer_paises();
      this.traer_datos_simulacion();
      this.setHeigthScreen(window.innerHeight);
    }

    setTimeout(() => {
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "dev"
      ) {
        this.set_url_base_web("http://localhost:3000");
        // this.set_url_base_app("http://localhost:8080");
      } else {
        // this.set_url_base_web("https://devel4.girofacil.cl/web");
        this.set_url_base_web("https://girofacil.com");
        // this.set_url_base_app("https://devel4.girofacil.cl/app");
      }
    }, 200);
  },
  async mounted() {
    window.addEventListener("focus", () => {
      console.log("***--> got_focus");
      if (this.updateAvailable) {
        this.objSW.update();
      }
    });
    window.addEventListener("message", (e) => {
      if (e.data.msg == "actualiza") {
        window.location.reload();
      }
      if (e.data.msg == "newContentCached") {
        this.updateAvailable = true;
      }
    });
    window.addEventListener("swUpdated", (e) => {
      this.objSW = e.detail;
      this.updateAvailable = true;
    });
    window.addEventListener("actualiza", () => {
      window.location.reload();
    });
    window.addEventListener("newContentCached", () => {
      this.updateAvailable = true;
    });

    window.addEventListener("focus", () => {
      console.log("***--> got_focus");
      this.check_version();
    });
    this.setHeigthScreen(window.innerHeight);
    window.addEventListener("resize", this.call_setHeigthScreen);

    this.setWidthScreen(window.innerWidth);
    window.addEventListener("resize", this.call_setWidthScreen);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get("from");
    if (from && from == "web") {
      this.set_plataforma("web");
    }

    window.onbeforeunload = () => {
      this.updateBloquearNavegacion(false);
      if (!this.to_web) {
        const d = new Date();
        d.setTime(d.getTime() + 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = `gf_reloading=1;${expires}; path=${this.cookie_domain}`;
      } else {
        this.set_to_web(false);
      }
    };
  },
  beforeUnmount() {
    this.updateBloquearNavegacion(false);
  },
  ionViewWillLeave() {},
};
</script>

<style >
.spinnerLocal {
  opacity: 0.75;
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
