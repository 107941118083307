<template>
  <div class="headerLogoCamp">
    <div class="headerPerfClose">
      <div class="cerrarCont" >
        <img
          :src="colorIcon == 'rojoOscuro' ? iconoCerrarRojoOscuro: iconoCerrarGris"
          alt=""
          srcset=""
          class="cerrar"
          @click="cancelar"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        colorIcon: {type: String}
    },
    data(){
      return{
        iconoCerrarRojoOscuro: require('../../assets/imgs/icono_cerrarRojoOscuro.svg'),
        iconoCerrarGris: require('../../assets/imgs/icono_cerrarGris.svg'),
      }
    },
    methods:{
        cancelar(){
            this.$emit("cancelar");
        }
    }
}
</script>

<style scoped>
.headerLogoCamp{
  display: flex;
  flex-direction: row-reverse;
}

.headerLogo img{
    width: 140px;
    
}
.headerLogo p{
    color: #C4C4C4;
    font-size: 0.7rem;
    margin-left: 3rem;
}
.headerPerfClose{
    /* height: 3rem; */
    display: flex;
    justify-content: space-between;
}
.perfCloseCont{
    z-index: 1;
    padding-right: 0.75rem;
}
.separadorCont{
    width: 0.1rem;
}
.cerrarCont{
    padding-left: 0.75rem;
    cursor: pointer;
}
</style>