import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router/router';
import store from './store/store';
import { IonicVue } from '@ionic/vue';
import { createI18n } from 'vue-i18n';
import es from '@/assets/data/i18n_es.json';
import en from '@/assets/data/i18n_en.json';
import ht from '@/assets/data/i18n_ht.json';
import pt from '@/assets/data/i18n_pt.json';

import './assets/css/global.css';
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import firebase from "firebase/app";
import implementar from './registerServiceWorker';

const firebaseConfig = {
  apiKey: "AIzaSyBKjeUCb0ShmZaRxJ2JdVr848rZvKtizzk",
  authDomain: "vue-ionic-pwa-21.firebaseapp.com",
  projectId: "vue-ionic-pwa-21",
  storageBucket: "vue-ionic-pwa-21.appspot.com",
  messagingSenderId: "1024792424457",
  appId: "1:1024792424457:web:6455f686479917307208d8"
};
firebase.initializeApp(firebaseConfig);
implementar();

let i18n = createI18n({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'es',
  messages: {
    es, en, ht, pt
  },

});

// import VueGtag from 'vue-gtag';
import VueGtm from 'vue-gtm'
import gtmConfig from './gtm'

createApp(App)
  .use(store)
  .use(router)
  .use(IonicVue)
  .use(i18n)
  // .use(VueGtag, {
  //   config: { id: 'G-5HQN9TGMY6' },
  // })
  .use(VueGtm, gtmConfig)
  .mount('#app')
