

import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store/store.js';

const routes = [
  {
    path: '',
    redirect: '/logIn'
  },
  {
    path: '/logIn',
    name: 'logIn',
    component: () => import('@/views/logIn.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/registroDisclaimer',
    name: 'registroDisclaimer',
    component: () => import('@/views/registroDisclaimer.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import('@/views/registro.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/recoverPass',
    name: 'recoverPass',
    component: () => import('@/views/recoverPass.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/autentificacionRP',
    name: 'autentificacionRP',
    component: () => import('@/views/autentificacionRecoverPass.vue'),
    meta: {
      logued: false
    }
  },

  {
    path: '/term_condi',
    name: 'term_condi',
    component: () => import('@/views/term_condi.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/politicas_privacidad',
    name: 'politicas_privacidad',
    component: () => import('@/views/politicas_privacidad.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/consulta_reg_civil',
    name: 'consulta_reg_civil',
    component: () => import('@/views/consulta_reg_civil.vue'),
    meta: {
      logued: false
    }
  },
  
  {
    path: '/registroFormInicial',
    name: 'registroFormInicial',
    component: () => import('@/views/registroFormInicial.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/registroFormEdit',
    name: 'registroFormEdit',
    component: () => import('@/views/registroFormEdit.vue'),
    meta: {
      logued: true
    }
  },
   
  {
    path: '/autentificacionRegistro',
    name: 'autentificacionRegistro',
    component: () => import('@/views/autentificacionRegistro.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/recoverPassForm',
    name: 'recoverPassForm',
    component: () => import('@/views/recoverPassForm.vue'),
    meta: {
      logued: false
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizador',
    name: 'cotizador',
    component: () => import('@/views/cotizador.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/ingresar_giro',
    name: 'ingresar_giro',
    component: () => import('@/views/ingresar_giro.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/repetir_giro',
    name: 'repetir_giro',
    component: () => import('@/views/repetir_giro.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/misDatos',
    name: 'misDatos',
    component: () => import('@/views/misDatos.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/destinatarios',
    name: 'destinatarios',
    component: () => import('@/views/destinatarios.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/destinatariosDetalle',
    name: 'destinatariosDetalle',
    component: () => import('@/views/destinatariosDetalle.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/destinMyDataForm',
    name: 'destinMyDataForm',
    component: () => import('@/views/destinMyDataForm.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorDestinatario',
    name: 'cotizadorDestinatario',
    component: () => import('@/views/cotizadorDestinatario.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/nuevoDestinatarioDisclaimer',
    name: 'nuevoDestinatarioDisclaimer',
    component: () => import('@/views/cotizadorNuevoDestinatarioDisclaimer.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorMotivoRemesa',
    name: 'cotizadorMotivoRemesa',
    component: () => import('@/views/cotizadorMotivoRemesa.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorResumen',
    name: 'cotizadorResumen',
    component: () => import('@/views/cotizadorResumen.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorEtapas',
    name: 'cotizadorEtapas',
    component: () => import('@/views/cotizadorEtapas.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorModoPago',
    name: 'cotizadorModoPago',
    component: () => import('@/views/cotizadorModoPago.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/nuevoDestinatarioForm',
    name: 'nuevoDestinatarioForm',
    component: () => import('@/views/nuevoDestinatarioForm.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorDatos',
    name: 'cotizadorDatos',
    component: () => import('@/views/cotizadorDatos.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/cotizadorFinalizar',
    name: 'cotizadorFinalizar',
    component: () => import('@/views/cotizadorFinalizar.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/nuevoDestinatarioFinalizar',
    name: 'nuevoDestinatarioFinalizar',
    component: () => import('@/views/nuevoDestinatarioFinalizar.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/destinMyDataForm_finalizar',
    name: 'destinMyDataForm_finalizar',
    component: () => import('@/views/destinMyDataForm_finalizar.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/misTransac',
    name: 'misTransac',
    component: () => import('@/views/misTransac.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/idioma',
    name: 'idioma',
    component: () => import('@/views/idioma.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/myDataRecoverPass',
    name: 'myDataRecoverPass',
    component: () => import('@/views/myDataRecoverPass.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/registroEditAutentificacion',
    name: 'registroEditAutentificacion',
    component: () => import('@/views/registroEditAutentificacion.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/centroAyuda',
    name: 'centroAyuda',
    component: () => import('@/views/centroAyuda.vue'),
    meta: {
      logued: true
    }
  },
  {
    path: '/recoverPassFinalizar',
    name: 'recoverPassFinalizar',
    component: () => import('@/views/recoverPassFinalizar.vue'),
    meta: {
      logued: false
    }
  },
  
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('@/views/logIn.vue')
  }
]

// let base_url = process.env.NODE_ENV === 'development' ? '/' : '/app/';
let base_url = process.env.NODE_ENV === 'development' ? '/' : '/';

const router = createRouter({
  history: createWebHistory(base_url),
  routes
});

router.beforeEach(async (to, from, next) => {
  let soloLogueado = to.matched.some(route => route.meta.logued);
  let clientaAutenticado = await store.state.remitente.id != undefined ? true : false;

  if (!store.state.bloquearNavegacion) {
    if (soloLogueado) {
      if (clientaAutenticado) {
        if (to.name == "cotizador") {
          await store.commit("setshowSpinner", true);
          await store.dispatch("reload_tasas_costo_serv_pausa");
          await store.commit("setshowSpinner", false);
          next();
        } else {
          next();
        }
      } else {
        next({
          path: "/logIn",
          query: { redirect: to.fullPath }
        });
      }
    } else {
      next();
    }
  }

});


export default router

