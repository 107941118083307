import { createStore } from "vuex";
import Axios from "axios";
import createPersistedState from "vuex-persistedstate";

const ObjGiroFacil = () => ({
  idiomaPreferencia: { val: 1, text: "Español", cod: "es" },
  plataforma: "",
  nav_bar_height: { height: '55px' },
  showSpinner: false,
  costos_pais: [],
  session_confirm: 0,
  remitenteTemp: {},
  rut_forgot_pass: "",
  bloquearNavegacion: false,
  respCodigoValidaMailLogin: { status: false, resp: false },
  respCodigoValidaMail: { status: false, resp: false },
  respCodigoValidaMailRegis: { status: false, resp: false },
  resp_borrar_destinatario: { status: false, resp: false },
  resp_signOff: { status: false, resp: false },
  resp_forma_entrega_update: {},
  resp_create_config_others: {},
  resp_destinatario_update: {},
  resp_forma_entrega_reg: {},
  resp_borrar_forma_entega: {},
  resp_validar_codigo_registro: {},
  resp_floid_verifica_rut: {},
  listaElementosDom: [],
  resp_pre_registro: {},
  focusGlobal: true,
  infoNodos: [],
  respRemitenteModif: {},
  resp_reload_tasas_costo_serv_pausa: {},
  resp_send_cod_change_mail: {},
  link_controlador:"https://app.girofacil.com/odin/",
  // registro_diclaimer:{
  //   acepto_term_cond: false,
  //   acepto_pol_priv: false,
  //   acepto_reg_civil: false,
  // },
  desde: "logIn",
  respCupon: {},
  respSelectDestinatario: {},
  dataResumen: [],
  detalleReciboTransferencia: [],
  listaElementosDomTemp: [],
  dataNewDestinatarioRegistrado: {},
  procesoGeneral: "",
  menuSpDesde: { desde: "", menuSp: false },
  nav_stack: { desde: [], menuSp: false },
  dataDestinatarioSelected: {},
  dataDestinSelected: {},
  fintocPaymentIntent: {},
  heigthScreen: window.innerHeight,
  widthScreen: 0,
  accept_term_cond: false,
  accept_poli_priv: false,
  accept_consulta_reg_civil: false,
  cookie_domain: "/",
  datos_consultados: {},
  obj_gf_base: {},
  transaccion: {
    porcentaje_descuento: 0,
    descuento: 0,
    pais_remitente: "152_1",
    region: 0,
    region_nombre: "",
    comuna_nombre: "",
    entidades_nombre: "",
    codigo_agencia: "0",
    tipo_cuenta_nombre: "",
    tipo_documento_nombre: "",
    codigo_agencia_brasil: "",
    motivo_transaccion: "",
    tipo_pago: 1,
    mensaje: ""
  },
  remitente: {},
  configuracion: {
    version: "3.31",
  },
  respForgotPass3: { status: false, resp: false },
  respForgotPass_sin_log: { status: false, resp: false },
  to_web: false,
  data_mostrar_error: null,
  url_base_web: "",
  url_base_app: "",
  i18n_locales: {
    es: {
      locales: "-CL",
      miles_str: ".",
      deci_str: ",",
      miles_cod: "46",
      deci_cod: "44"
    },
    en: {
      locales: "-US",
      miles_str: ",",
      deci_str: ".",
      miles_cod: "44",
      deci_cod: "46"
    },
    ht: {
      locales: "",
      miles_str: ".",
      deci_str: ",",
      miles_cod: "46",
      deci_cod: "44"
    },
    pt: {
      locales: "-BR",
      miles_str: ".",
      deci_str: ",",
      miles_cod: "46",
      deci_cod: "44"
    }
  },

  // ----------------------
  montos_pais_docs: [
    {
      pais: "PERÚ",
      min: 8000,
      max_giro: 5000000,
      max_mes: 5000000,
      max_year: "",
    },
    {
      pais: "COLOMBIA",
      min: 8000,
      max_giro: 2400000,
      max_mes: 5000000,
      max_year: "",
    },
    {
      pais: "BOLIVIA",
      min: 8000,
      max_giro: 5000000,
      max_mes: 5000000,
      max_year: 12000000,
    },
    {
      pais: "HAITÍ",
      min: 8000,
      max_giro: 700000,
      max_mes: 3300000,
      max_year: "",
    },
    {
      pais: "ARGENTINA",
      min: 8000,
      max_giro: 5000000,
      max_mes: 5000000,
      max_year: "",
    },
    {
      pais: "ECUADOR",
      min: 8000,
      max_giro: 5000000,
      max_mes: 5000000,
      max_year: "",
    },
  ],
  remitente_telef_prefijo: [
    ["152", "56", ["+56"], "Chile"],
    ["900", "54", ["+54"], "Argentina"],
    ["68", "591", ["+591"], "Bolivia"],
    ["800", "55", ["+55"], "Brazil"],
    ["170", "57", ["+57"], "Colombia"],
    ["218", "593", ["+593"], "Ecuador"],
    ["", "595", ["+595"], "Paraguay"],
    ["604", "51", ["+51"], "Perú"],
    ["862", "58", ["+58"], "Venezuela"],
    ["99", "0", ["+1 809", "+1 829", "+1 849"], "República Dominicana"],
    ["332", "0", ["+509"], "Haití"],
    ["400", "0", ["+86"], "China"],
    ["450", "0", ["+34"], "España"],
    ["", "0", "", "Otro"],
  ],


  datos_estaticos: {

    motivo_transaccion: [
      [
        ["0", "Remesas Familiares"],
        ["1", "Pago Facturas"],
        ["001", "Medical Expenses"],
        ["002", "Family Assistance"],
        ["003", "Education Expenses"],
        ["004", "Building a Home"],
      ],
    ],
    idiomas: [
      [
        [1, "Español", "es"],
        [2, "Inglés", "en"],
        [3, "Creole", "ht"],
        [4, "Portugués", "pt"],
      ],
    ],
    pep: "¿Tienes o tuviste un cargo jerárquico en el sector público con cualquier gobierno o un cargo jerárquico en una entidad propiedad del gobierno o tienes un pariente cercano o un asociado cercano a dicha persona?",
    datos_bancarios: [
      {
        razon_social: ["Nombre", "Inversiones GiroFacil SPA."],
        rut: ["RUT", "76.602.849-7"],
        banco_nombre: ["Banco Estado", ""],
        numero_cuenta: ["Cuenta Corriente", "8215219"],
        tipo_cuenta: ["Tipo de cuenta", "Cuenta Corriente"],
        mail: ["Correo", "deposito@girofacil.com"],
        monto_pagar: ["Monto a transferir", ""],
      },
      {
        razon_social: ["Nombre", "Inversiones GiroFacil SPA."],
        rut: ["RUT", "76.602.849-7"],
        banco_nombre: ["Banco Santander", ""],
        numero_cuenta: ["Cuenta Corriente", "86256509"],
        tipo_cuenta: ["Tipo de cuenta", "Cuenta Corriente"],
        mail: ["Correo", "deposito@girofacil.com"],
        monto_pagar: ["Monto a transferir", ""],
      },
    ],
    origen_fondos: [
      [
        [1, "Venta Bienes Inmuebles", 1],
        [2, "Venta Bienes Muebles", 1],
        [3, "Arriendos", 1],
        [4, "Remuneraciones", 1],
        [5, "Indemnización", 1],
        [6, "Premios de Azar", 1],
        [7, "Inversiones", 1],
        [8, "Participación en Sociedades", 1],
        [9, "Activos Financieros", 1],
        [10, "Herencias", 1],
        [11, "Otros", 1]
      ]
    ],
    reglasFormularioRemitente: [
      [0, 0, 0, 1, "numero_documento", "RUT", "icoInputTarjeta", "text", ["notempty", "charmin-8", "charmax-10", "rut-empresa"], "", 0, "", "Ej: XXXXXXXX-X", 0, 0, { edit: ["si", "no"], reg: ["si", "no"] },],
      [0, 0, 0, 1, "correo", "Correo de Contacto", "icoInputMail", "text", ["notempty", "correo", "charmax-150"], "", 0, "", "Ej: pedrogt@gmail.com", 0, 0, { edit: ["si", "si"], reg: ["si", "no"] },],
      // [0, 0, 0, 1, "nombres", "Nombres", "icoInputNombre", "text", ["notempty", "charmin-3", "charmax-100"], "", 0, "", "Ej: Pedro", 0, 0, { edit: ["si", "no"], reg: ["si", "no"] },],
      [0, 0, 0, 1, "nombre1", "Primer Nombre", "icoInputNombre", "text", ["notempty", "charmin-3", "charmax-100"], "", 0, "", "Ej: Pedro", 0, 0, { edit: ["si", "no"], reg: ["si", "si"] },],
      [0, 0, 0, 1, "nombre2", "Segundo Nombre", "icoInputNombre", "text", ["notempty", "charmin-3", "charmax-100"], "", 0, "", "Ej: Pedro", 0, 0, { edit: ["si", "no"], reg: ["si", "si"] },],
      [0, 0, 0, 1, "apellido1", "Primer Apellido", "icoInputApellidoPAt", "text", ["notempty", "charmin-3", "charmax-100"], "", 0, "", "Ej: Gonzales", 0, 0, { edit: ["si", "no"], reg: ["si", "si"] },],
      [0, 0, 0, 1, "apellido2", "Segundo Apellido", "icoInputApellidoMat", "text", ["charmax-100"], "", 0, "", "Ej: Tapia", 0, 0, { edit: ["si", "no"], reg: ["si", "si"] },],
      [0, 0, 0, 1, "telefono", "Teléfono", "icoInputFono", "tel", ["charmin-13"], "", 0, "telefono", "Ej: +569 9883 5317", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],

      [0, 0, 0, 2, "nacionalidad", "Nacionalidad", "icoInputNacionalidad", "selectDir", ["notempty"], "", 0, "", "", 0, 0, { edit: ["si", "no"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "residencia", "País de Residencia", "icoInputNacionalidad", "select", ["notempty"], "region", 0, "", "", 0, 0, { edit: ["si", "no"], reg: ["si", "no"] },],
      [0, 0, 0, 2, "region", "Región", "icoInputUbicacion", "select", ["notempty"], "comuna_id", 1, "", "", 0, "152", { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "comuna_id", "Comuna", "icoInputUbicacion", "select", ["notempty"], "", 1, "", "", 0, "152", { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "ciudad", "Ciudad", "icoInputUbicacion", "text", ["notempty", "charmin-3"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "direccion", "Dirección completa en país de residencia", "icoInputUbicacion", "text", ["notempty", "charmin-10"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], big: ["no"] }],
      [0, 0, 0, 2, "ocupacion", "Ocupación", "icoInputNombre", "selectDir", ["notempty", "valmin-100"], "", 0, "", "Detalla tu actividad", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "origen_fondos", "Origen de Fondos", "", "selectDir", ["notempty"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "pep", "Cargo Jerárquico", "app.comunes.cargoJerarText", "radioBtns", ["boolean"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],
    ],
    reglasFormularioDestinatario: [
      [0, 0, 0, 1, "pais_moneda_destino_id", "País Destino", "icoInputPais", "selectPais", ["notempty"], ["tipo_documento_id", "cuil"], 0, "", "", 0, 0, { edit: ["si", "no"], reg: ["si", "si"], res: ["si", ""], tipo: "personal" },],
      [0, 0, 0, 1, "tipo_documento_id", "Documento de identificación", "icoInputTipoDoc", "select", ["notempty"], "", 1, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["si", ""], tipo: "personal" },],
      [0, 0, 0, 1, "numero_documento", "Número de Documento", "icoInputDNI", "text", ["notempty", "charmin-7"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["si", ""], tipo: "personal" },],
      [0, 0, 0, 1, "cuil", "Código CUIL", "icoInputTarjeta", "number_hijo", ["notempty", "charmin-6"], "", 1, "", "Escriba su numero de cuenta sin guion.", 0, "900", { edit: ["si", "si"], reg: ["si", "si"], tipo: "personal" },
      ],
      [0, 0, 0, 1, "correo", "E-mail", "icoInputMail", "text", ["notempty", "correo"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["si", ""], tipo: "personal" },],

      [0, 0, 0, 1, "nombre1", "Primer nombre", "icoInputNombre1", "text", ["notempty", "charmin-2", "charmax-100"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["no", ""], tipo: "personal" },
      ],
      [0, 0, 0, 1, "nombre2", "Segundo nombre", "icoInputNombre2", "text", ["charmax-100"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["no", ""], tipo: "personal" },
      ],
      [0, 0, 0, 1, "apellido1", "Primer Apellido", "icoInpuApellido1", "text", ["notempty", "charmin-2", "charmax-100"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["no", ""], tipo: "personal" },
      ],
      [0, 0, 0, 1, "apellido2", "Segundo Apellido", "icoInputApellido2", "text", ["charmax-100"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["no", ""], tipo: "personal" },
      ],
      // [0, 0, 0, 1, "region", "Región", "icoInputRegion", "select", ["notempty"], "comuna_id", 1, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],
      // [0, 0, 0, 1, "comuna_id", "Comuna", "icoInputComuna", "select", ["notempty"], "", 1, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 1, "direccion", "Dirección completa", "icoInputDireccion", "text", ["notempty", "charmin-5"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["si", "si"], tipo: "personal" },
      ],
      [0, 0, 0, 1, "telefono", "Teléfono", "icoInputFono", "tel", ["phone-cl", "charmin-8"], "", 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], res: ["si", ""], tipo: "personal" },
      ],

      [0, 0, 0, 2, "forma_entrega_id", "Tipo de Entrega", "icoInputTipoEntrega", "select", ["notempty"], ["banco", "moneda_caja", "bco_wallet"], 0, "", "", 0, 0, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "banco", "Banco", "icoInputBanco", "select", ["notempty"], "tipo_cuenta", 1, "", "", 0, 1, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "tipo_cuenta", "Tipo de cuenta", "icoInputTarjeta", "select", ["notempty"], "numero_cuenta", 1, "", "", 0, 1, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "numero_cuenta", "Número de Cuenta", "icoInputTarjeta", "number_hijo", ["notempty", "charmin-6", "number"], "", 1, "", "Escriba su numero de cuenta sin guion.", 0, 1, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },
      ],

      [0, 0, 0, 2, "bco_wallet", "Banco", "icoInputBanco", "select", ["notempty"], "numero_cuenta_wallet", 1, "", "", 0, 6, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "numero_cuenta_wallet", "Número de Cuenta E-Wallet", "icoInputTarjeta", "number_hijo", ["notempty", "charmin-6", "number"], "", 1, "", "Escriba su numero de cuenta sin guion.", 0, 6, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },
      ],

      [0, 0, 0, 2, "moneda_caja", "Moneda de entrega", "icoInputRegion", "select", ["notempty"], "banco_caja", 1, "", "", 0, 2, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "banco_caja", "Banco", "icoInputBanco", "select", ["notempty"], "region_caja", 1, "", "", 0, 2, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "region_caja", "Región", "icoInputRegion", "select", ["notempty"], "comuna_caja", 1, "", "", 0, 2, { edit: ["si", "si"], reg: ["si", "si"], tipo: "forma_entrega" },],
      [0, 0, 0, 2, "comuna_caja", "Comuna", "icoInputComuna", "select", ["notempty"], "sucursales", 1, "", "", 0, 2, { edit: ["si", "si"], reg: ["si", "si"] },],
      [0, 0, 0, 2, "sucursales", "Comuna", "icoInputSucursal", "select", ["notempty"], "", 1, "", "", 0, 2, { edit: ["si", "si"], reg: ["si", "si"] },],

    ],
    reglasFormularioTransaccion: [
      [0, 0, 0, 1, "forma_entrega_id", "Entrega de Dinero", "icoInputTarjeta", "buttons", ["notempty"], "", 0, "", "", 0, 0,],
      [0, 0, 0, 2, "banco", "Banco", "icoInputTarjeta", "select", ["notempty"], "tipo_cuenta", 0, "", "", 0, 1,],
      [0, 0, 0, 2, "tipo_cuenta", "Tipo de cuenta", "icoInputTarjeta", "select", ["notempty"], "", 1, "", "", 0, 1,],
      [0, 0, 0, 2, "cuenta_corriente", "Numero de Cuenta", "icoInputTarjeta", "number", ["notempty", "charmin-6", "number"], "", 0, "", "Escriba su numero de cuenta sin guion.", 0, 1,],
      [800, 0, 0, 2, "codigo_agencia_brasil", "Código de agencia", "icoInputTarjeta", "text", ["notempty"], "", 0, "", "", 0, 0,],
      [0, 0, 0, 3, "motivo_transaccion", "Motivo Transaccion", "icoInputTarjeta", "selectDir", ["notempty", "valmin-1"], "", 0, "", "", 0, 0,],
      [0, 0, 0, 4, "tipo_pago", "Elije cómo pagar", "icoBotonTarjeta", "buttons", ["notempty"], "", 0, "", "", 0, 0,],
      [170, 0, 0, 2, "region", "Departamento", "icoInputUbicacion", "select", ["notempty"], "comuna_id", 0, "", "", 0, 2,],
      [170, 0, 0, 2, "comuna_id", "Ciudad", "icoInputUbicacion", "select", ["notempty"], "entidades", 1, "", "", 0, 2,],
      [170, 0, 0, 2, "entidades", "Agencia pagadora", "icoInputNombre", "select", ["notempty"], "", 1, "", "", 0, 2,],
    ],
  },
  monto_inicio: 500000,
  pais_destino_inicial: "604_2",
  monto_inicio_BD: 500000,
  pais_destino_inicial_BD: "604_2",
  // notifications: [
  //   {
  //     body: "Te informamos que por el momento, y hasta nuevo aviso, el envío de dinero a Argentina no está habilitado",
  //     cat: "c",
  //   },
  //   {
  //     body: "Hemos recibido tu dinero. Ver estado de transacciones",
  //     cat: "t",
  //   },
  //   {
  //     body: "Tu giro de ayer a Perico los Palotes ya fue completado.",
  //     cat: "t",
  //   },
  // ],


  flujo: {

    irPorPasosTipo: "Remitente",
  },
});

export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {


  },

  getters: {
    listaElementosDomGet(state) {
      return state.listaElementosDom;
    },
  },

  actions: {
    async limpiarTransaccion(context, vista) {

      const allowed_logIn = [
        "codigo_agencia",
        "codigo_agencia_brasil",
        "comuna_nombre",
        "descuento",
        "entidades_nombre",
        "motivo_transaccion",
        "pais_remitente",
        "porcentaje_descuento",
        "region",
        "region_nombre",
        "tipo_cuenta_nombre",
        "tipo_documento_nombre",
        "tipo_pago",
      ];

      const allowed_perfil = [...allowed_logIn];

      const allowed_perfilDeep = [...allowed_perfil];

      const allowed_simulador = [...allowed_logIn, ...allowed_perfil];
      const allowed_listaDestinatarios_pre = [
        "banco",
        "cuenta_corriente",
        "destinatario",
        "moneda_destino_cantidad_decimales",
        "moneda_destino_id",
        "moneda_destino_nombre",
        "moneda_destino_sigla",
        "moneda_destino_simbolo",
        "moneda_origen_cantidad_decimales",
        "moneda_origen_id",
        "moneda_origen_nombre",
        "moneda_origen_sigla",
        "moneda_origen_simbolo",
        "monto_destino",
        "monto_origen",
        "pais_destino",
        "tasa_invertida",
        "tipo_cuenta",
        "forma_entrega_id",
        "valor_cambio",
        "valor_maximo_origen",
        "valor_minimo_origen",
      ];

      const allowed_listaDestinatarios = [
        ...allowed_simulador,
        ...allowed_listaDestinatarios_pre,
      ];

      const allowed_motivoTransaccion_pre = [
        "monto_pagar",
        "giro_gastos_envio",
        "codigo_id",
        "cupon",
      ];

      const allowed_motivoTransaccion = [
        ...allowed_simulador,
        ...allowed_motivoTransaccion_pre,
      ];

      const objDatosVista = {
        logIn: allowed_logIn,
        perfil: allowed_perfil,
        perfilDeep: allowed_perfilDeep,
        simulador: allowed_simulador,
        listaDestinatarios: allowed_listaDestinatarios,
        motivoTransaccion: allowed_motivoTransaccion,
      };

      const filtered = Object.keys(context.state.transaccion)
        .filter((key) => objDatosVista[vista].includes(key))
        .reduce((obj, key) => {
          obj[key] = context.state.transaccion[key];
          return obj;
        }, {});

      context.state.transaccion = { ...filtered };

      switch (vista) {
        case "logIn":
          break;
        case "perfil":
          context.state.transaccion.monto_origen = "50.000";
          break;
        case "perfilDeep":
          break;
        case "simulador":
          context.state.transaccion.monto_origen = !context.state.transaccion
            .monto_origen
            ? "500.000"
            : context.state.transaccion.monto_origen;
          break;
        case "listaDestinatarios":
          context.state.resumen = [];
          context.state.transaccion.codigo_agencia = "";
          break;

        case "motivoTransaccion":
          context.state.transaccion.motivo_transaccion = "";
          break;
        default:
          break;
      }
    },

    traer_paises(context) {
      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/paises`,
      }).then(async (response) => {
        // console.log("1",response.data, context.state.objGirFacil);
        if (typeof response.data != "undefined" && response.data != null) {
          context.commit("load_paises_obj_gf_base", response.data.paises);
          // context.dispatch("create_configuracion");
        }

      }).catch(err => {
        console.log(err);

      });
    },
    async traer_datos_simulacion(context) {
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/datosSimulacion`,
      }).then(async (response) => {
        // console.log("1",response.data);
        if (!response.data.error && typeof response.data != "undefined" && response.data != null) {
          context.commit("load_data_base_obj_gf_base", response.data.pais);
          return true;
        } else {
          context.dispatch("enviar_data_error", {
            plataforma: "app",
            proceso: "Auxiliares/datosSimulacion",
            detalle: response.data
          });
          context.commit("set_data_mostrar_error", {
            title: "Error",
            text: "Problema de comunicación con el servidor."
          });
          context.commit("load_data_base_obj_gf_base", null);
          context.commit("setshowSpinner", false);
          return false;
        }

      }).catch(err => {
        console.log(err);

      });
    },
    enviar_data_error(context, data) {

      const params = new URLSearchParams();

      params.append("plataforma", data.plataforma);
      params.append("proceso", data.proceso);
      params.append("detalle", data.detalle);

      let cliente_id = "anonimus";
      if (context.state.remitente && context.state.remitente.id) {
        cliente_id = context.state.remitente.id;
      }
      params.append("cliente_id", cliente_id);




      console.log(params);


    },
    async traer_ocupaciones(context) {
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/ocupaciones`,
      }).then(async (response) => {
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.ocupaciones.length > 0) {
            context.state.configuracion.data.auxiliares.ocupacion = [];
            context.state.configuracion.data.auxiliares.ocupacion[0] = [];
            context.state.configuracion.data.auxiliares.ocupacion[1] = [];

            response.data.ocupaciones.sort((a, b) => {
              return Intl.Collator().compare(a.nombre, b.nombre);

            });


            response.data.ocupaciones.forEach(element => {
              context.state.configuracion.data.auxiliares.ocupacion[0].push([
                parseInt(element.id), element.nombre, 0]);

              element.hijos.sort((a, b) => {
                return Intl.Collator().compare(a.nombre, b.nombre);

              });

              element.hijos.forEach(item => {
                context.state.configuracion.data.auxiliares.ocupacion[1].push([
                  parseInt(item.id), item.nombre, parseInt(element.id)
                ]);
              });
            });
            return true;
          }

        }

      }).catch(err => {
        console.log(err);
        return false;
      });
    },

    async traer_regiones(context, pais) {

      const params = new URLSearchParams();
      params.append("pais", pais);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/regiones`,
        data: params
      }).then(async (response) => {
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.regiones.length > 0) {

            if (pais == "152") {
              let regiones = [];
              regiones = response.data.regiones;
              // console.log(regiones);
              regiones.sort((a, b) => {
                return Intl.Collator().compare(a.nombre, b.nombre);
              });

              let metropolitana_index = regiones.findIndex(reg => { return reg.id == "13" });
              if (metropolitana_index > -1) {
                let metropolitana = regiones[metropolitana_index];
                metropolitana.nombre = await context.dispatch("primera_letra_mayuscula", metropolitana.nombre);
                regiones.splice(metropolitana_index, 1);
                regiones.splice(0, 0, metropolitana);
              }
              let regns = [];
              for (let index = 0; index < regiones.length; index++) {
                const region = regiones[index];
                regns.push([
                  region.id, region.nombre, pais
                ]);
              }
              context.state.configuracion.pais["152_1"].region = [...regns];

            } else {
              return response.data.regiones;
            }
          } else {

            return [];
          }

        }

      }).catch(err => {
        console.log(err);

      });
    },
    async traer_regiones_v2(context, pais) {

      const params = new URLSearchParams();
      params.append("pais", pais);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/regiones`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params
      }).then(async (response) => {
        // console.log(response);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.regiones.length > 0) {

            if (!context.state.datos_consultados[pais]) {
              context.state.datos_consultados[pais] = {};
            }
            if (!context.state.datos_consultados[pais].region) {
              context.state.datos_consultados[pais].region = {};
            }
            // context.state.datos_consultados[pais].region.status = false;

            let destinos_relacionados = [];
            let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
            for (let w = 0; w < destinos_relacionados_array.length; w++) {
              if (destinos_relacionados_array[w][0].split("_")[0] == pais) {
                destinos_relacionados.push(destinos_relacionados_array[w][0]);
              }
            }

            for (let x = 0; x < destinos_relacionados.length; x++) {
              const destino_relacionado = destinos_relacionados[x];
              context.state.configuracion.pais[destino_relacionado].region = [];
            }

            let regiones = response.data.regiones;
            regiones.sort((a, b) => {
              return Intl.Collator().compare(a.nombre, b.nombre);
            });

            for (let index1 = 0; index1 < regiones.length; index1++) {
              const region = regiones[index1];
              for (let x = 0; x < destinos_relacionados.length; x++) {
                const destino_relacionado = destinos_relacionados[x];
                await context.state.configuracion.pais[destino_relacionado].region.push([
                  region.id, await context.dispatch("primera_letra_mayuscula", region.nombre) , destino_relacionado.split("_")[1]
                ]);
              }
            }


            // let regns = [];
            // for (let index1 = 0; index1 < regiones.length; index1++) {
            //   const region = regiones[index1];
            //   regns.push([
            //     region.id, region.nombre, ""
            //   ]);
            // }
            // for (let x = 0; x < destinos_relacionados.length; x++) {
            //   const destino_relacionado = destinos_relacionados[x];
            //   for (let index = 0; index < regns.length; index++) {
            //     const element = regns[index];
            //     element[2] = destino_relacionado;
            //   }
            //   context.state.configuracion.pais[destino_relacionado].region = [...regns];
            // }

            context.state.datos_consultados[pais].region.time = new Date().getTime();
            return true;
          } else {

            return false;
          }

        }

      }).catch(err => {
        console.log(err);
        return false;
      });
    },

    async traer_regiones_caja(context, pais) {
      const params = new URLSearchParams();
      params.append("pais", pais);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/regiones`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params
      }).then(async (response) => {
        // console.log(response);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.regiones.length > 0) {

            if (!context.state.datos_consultados[pais]) {
              context.state.datos_consultados[pais] = {};
            }
            if (!context.state.datos_consultados[pais].region_caja) {
              context.state.datos_consultados[pais].region_caja = {};
            }
            // context.state.datos_consultados[pais].region_caja.status = false;

            let destinos_relacionados = [];
            let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
            for (let w = 0; w < destinos_relacionados_array.length; w++) {
              if (destinos_relacionados_array[w][0].split("_")[0] == pais) {
                destinos_relacionados.push(destinos_relacionados_array[w][0]);
              }
            }
            let regiones = response.data.regiones;
            regiones.sort((a, b) => {
              return Intl.Collator().compare(a.nombre, b.nombre);
            });

            for (let index1 = 0; index1 < regiones.length; index1++) {
              const region = regiones[index1];
              for (let x = 0; x < destinos_relacionados.length; x++) {
                const destino_relacionado = destinos_relacionados[x];
                await context.state.configuracion.pais[destino_relacionado].region_caja.push([
                  region.id, await context.dispatch("primera_letra_mayuscula", region.nombre), destino_relacionado.split("_")[1]
                ]);
              }
            }


            context.state.datos_consultados[pais].region_caja.time = new Date().getTime();
            return true;
          } else {

            return false;
          }

        }

      }).catch(err => {
        console.log(err);
        return false;
      });
    },

    async traer_regiones_caja_v2(context, data) {
      const params = new URLSearchParams();
      params.append("banco", data.banco);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/sucursales`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        // console.log(response.data);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error ) {

            if (!context.state.datos_consultados[data.pais]) {
              context.state.datos_consultados[data.pais] = {};
            }
            if (!context.state.datos_consultados[data.pais].region_caja) {
              context.state.datos_consultados[data.pais].region_caja = {};
            }
            if (!context.state.datos_consultados[data.pais].region_caja[`${data.banco}`]) {
              context.state.datos_consultados[data.pais].region_caja[`${data.banco}`] = {};
            }
            // context.state.datos_consultados[pais].region_caja.status = false;

            let destinos_relacionados = [];
            let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
            for (let w = 0; w < destinos_relacionados_array.length; w++) {
              if (destinos_relacionados_array[w][0].split("_")[0] == data.pais) {
                destinos_relacionados.push(destinos_relacionados_array[w][0]);
              }
            }

            let regiones = [];
            let comunas = [];
            let sucursales = [];
            let regiones_id = [];
            let comunas_id = [];
            for (let index = 0; index < response.data.sucursales.length; index++) {
              const sucursal = response.data.sucursales[index];
              sucursales.push([sucursal.id, await context.dispatch("primera_letra_mayuscula", sucursal.nombre), await context.dispatch("primera_letra_mayuscula", sucursal.address), sucursal.comuna_id, sucursal.banco_id]);

              if(!regiones_id.includes(sucursal.region_id)){
                regiones_id.push(sucursal.region_id);
                regiones.push([sucursal.region_id, await context.dispatch("primera_letra_mayuscula", sucursal.region_nombre), sucursal.banco_id]);
              }

              if(!comunas_id.includes(sucursal.comuna_id)){
                comunas_id.push(sucursal.comuna_id);
                comunas.push([sucursal.comuna_id, await context.dispatch("primera_letra_mayuscula", sucursal.comuna_nombre), sucursal.region_id]);
              }

            }
            // let regiones = response.data.regiones;
            regiones.sort((a, b) => {
              return Intl.Collator().compare(a[1], b[1]);
            });
            comunas.sort((a, b) => {
              return Intl.Collator().compare(a[1], b[1]);
            });
            sucursales.sort((a, b) => {
              return Intl.Collator().compare(a[1], b[1]);
            });

            // for (let index1 = 0; index1 < regiones.length; index1++) {
            //   const region = regiones[index1];
            //   for (let x = 0; x < destinos_relacionados.length; x++) {
            //     const destino_relacionado = destinos_relacionados[x];
            //     await context.state.configuracion.pais[destino_relacionado].region_caja.push([
            //       region.id, region.nombre, destino_relacionado.split("_")[1]
            //     ]);
            //   }
            // }
            

            for (let x = 0; x < destinos_relacionados.length; x++) {
              const destino_relacionado = destinos_relacionados[x];
              if(x==0){
                context.state.configuracion.pais[destino_relacionado].region_caja = [];
                context.state.configuracion.pais[destino_relacionado].comuna_caja = [];
                context.state.configuracion.pais[destino_relacionado].sucursales = [];
                context.state.datos_consultados[data.pais].region_caja = {};
                context.state.datos_consultados[data.pais].region_caja[`${data.banco}`] = {};
              }
              for (let index = 0; index < regiones.length; index++) {
                const region = regiones[index];
                context.state.configuracion.pais[destino_relacionado].region_caja.push(region);
              }
              for (let index = 0; index < comunas.length; index++) {
                const comuna = comunas[index];
                context.state.configuracion.pais[destino_relacionado].comuna_caja.push(comuna);
              }
              for (let index = 0; index < sucursales.length; index++) {
                const sucursal = sucursales[index];
                context.state.configuracion.pais[destino_relacionado].sucursales.push(sucursal);
              }
            }

            context.state.datos_consultados[data.pais].region_caja[`${data.banco}`].time = new Date().getTime();
            return true;
          } else {

            return false;
          }

        }

      }).catch(err => {
        console.log(err);
        return false;
      });
    },

    async traer_comunas(context, region) {
      const params = new URLSearchParams();
      params.append("region", region);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/comunas`,
        data: params
      }).then(async (response) => {
        // console.log("/",response.data.comunas);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.comunas.length > 0) {
            return response.data.comunas;
          } else {
            // console.log("2 traer_comunas", region);
            return [];
          }

        }

      }).catch(err => {
        console.log(err);

      });
    },
    async traer_comunas_v2(context, data) {

      const params = new URLSearchParams();
      params.append("region", data.region);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/comunas`,
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.comunas.length > 0) {

            if (!context.state.datos_consultados[data.pais]) {
              context.state.datos_consultados[data.pais] = {};
            }
            if (!context.state.datos_consultados[data.pais].comuna_id) {
              context.state.datos_consultados[data.pais].comuna_id = {};
            }
            if (!context.state.datos_consultados[data.pais].comuna_id[`${data.region}`]) {
              context.state.datos_consultados[data.pais].comuna_id[`${data.region}`] = {};
            }
            // context.state.datos_consultados[data.pais].comuna_id[`${data.region}`].status = false;

            let comunas = response.data.comunas;
            // console.log(comunas);
            comunas.sort((a, b) => {
              return Intl.Collator().compare(a.nombre, b.nombre);
            });
            let paises = Object.entries(context.state.configuracion.pais);

            let comun = [];
            await comunas.forEach(async (item) => {
              comun.push([item.id, item.nombre, data.region]);
            });
            for (let index = 0; index < paises.length; index++) {
              const pais = paises[index];
              if (pais[0].split("_")[0] == data.pais) {
                context.state.configuracion.pais[pais[0]].comuna_id = [...comun];
              }
            }

            context.state.datos_consultados[data.pais].comuna_id[`${data.region}`].time = new Date().getTime();
            return true;
          } else {
            // console.log("2 traer_comunas", data.region);
            return [];
          }

        }

      }).catch(err => {
        console.log(err);

      });
    },

    async traer_comunas_caja(context, data) {
      const params = new URLSearchParams();
      params.append("region", data.region);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/comunas`,
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.comunas.length > 0) {

            if (!context.state.datos_consultados[data.pais]) {
              context.state.datos_consultados[data.pais] = {};
            }
            if (!context.state.datos_consultados[data.pais].comuna_caja) {
              context.state.datos_consultados[data.pais].comuna_caja = {};
            }
            if (!context.state.datos_consultados[data.pais].comuna_caja[`${data.region}`]) {
              context.state.datos_consultados[data.pais].comuna_caja[`${data.region}`] = {};
            }
            // context.state.datos_consultados[data.pais].comuna_caja[`${data.region}`].status = false;

            let comunas = response.data.comunas;
            comunas.sort((a, b) => {
              return Intl.Collator().compare(a.nombre, b.nombre);
            });
            let paises = Object.entries(context.state.configuracion.pais);
            for (let index = 0; index < paises.length; index++) {
              const pais = paises[index];
              // console.log(pais[0]);
              if (pais[0].split("_")[0] == data.pais) {
                await comunas.forEach(async (item) => {
                  context.state.configuracion.pais[pais[0]].comuna_caja.push([item.id, item.nombre, data.region]);
                });
              }
            }

            context.state.datos_consultados[data.pais].comuna_caja[`${data.region}`].time = new Date().getTime();
            return true;
          } else {
            // console.log("2 traer_comunas_caja", data.region);
            return [];
          }

        }

      }).catch(err => {
        console.log(err);

      });
    },

    async traer_tipo_doc(context, pais) {
      const params = new URLSearchParams();
      params.append("pais", pais);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/tipoDocumento`,
        data: params
      }).then(async (response) => {
        // console.log("/",response.data.comunas);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.tipo_documento.length > 0) {
            return response.data.tipo_documento;
          } else {
            // console.log("2 traer_tipo_doc", pais);
            return [];
          }
        }
      }).catch(err => {
        console.log(err);
      });
    },
    async traer_tipo_doc_v2(context, data) {
      const params = new URLSearchParams();
      params.append("pais", data.pais);
      return await Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/tipoDocumento`,
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.tipo_documento.length > 0) {

            if (!context.state.datos_consultados[data.pais]) {
              context.state.datos_consultados[data.pais] = {};
            }
            if (!context.state.datos_consultados[data.pais].tipo_documento_id) {
              context.state.datos_consultados[data.pais].tipo_documento_id = {};
            }

            let tipo_doc = response.data.tipo_documento;
            let destinos_relacionados = [];
            let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
            for (let w = 0; w < destinos_relacionados_array.length; w++) {
              if (destinos_relacionados_array[w][0].split("_")[0] == data.pais) {
                destinos_relacionados.push(destinos_relacionados_array[w][0]);
              }
            }
            tipo_doc.sort((a, b) => {
              return Intl.Collator().compare(a.nombre, b.nombre);
            });
            for (let x = 0; x < destinos_relacionados.length; x++) {
              const destino_relacionado = destinos_relacionados[x];
              context.state.configuracion.pais[destino_relacionado].tipo_documento_id = [];
              for (let index = 0; index < tipo_doc.length; index++) {
                const element = tipo_doc[index];
                context.state.configuracion.pais[destino_relacionado].tipo_documento_id.push([
                  element.id, element.nombre, 0
                ]);
              }
            }
            context.state.datos_consultados[data.pais].tipo_documento_id.time = new Date().getTime();
            return true;
          } else {
            // console.log("2 traer_tipo_doc", data.pais);
            return false;
          }
        }
      }).catch(err => {
        console.log(err);
      });
    },






    async create_config_others(context) {

      context.commit("set_resp_create_config_others", { resp: false });

      context.state.configuracion.data = {};
      context.state.configuracion.data.auxiliares = {};

      context.state.configuracion.data.auxiliares.ocupacion = context.state.datos_estaticos.ocupacion;

      context.state.datos_estaticos.motivo_transaccion[0].sort((a, b) => {
        return Intl.Collator().compare(a[1], b[1]);
      });

      context.state.configuracion.data.auxiliares.motivo_transaccion = context.state.datos_estaticos.motivo_transaccion;
      context.state.configuracion.data.auxiliares.idiomas = context.state.datos_estaticos.idiomas;
      context.state.configuracion.data.auxiliares.pais_moneda_destino_id = [];
      context.state.configuracion.data.auxiliares.pep = context.state.datos_estaticos.pep;
      context.state.configuracion.data.auxiliares.datos_bancarios = context.state.datos_estaticos.datos_bancarios;

      context.state.datos_estaticos.origen_fondos[0].sort((a, b) => {
        return Intl.Collator().compare(a[1], b[1]);
      });

      context.state.configuracion.data.auxiliares.origen_fondos = context.state.datos_estaticos.origen_fondos;

      context.state.configuracion.reglasFormularioRemitente = context.state.datos_estaticos.reglasFormularioRemitente;
      context.state.configuracion.reglasFormularioDestinatario = context.state.datos_estaticos.reglasFormularioDestinatario;
      context.state.configuracion.reglasFormularioTransaccion = context.state.datos_estaticos.reglasFormularioTransaccion;

      context.state.configuracion.pais = {};

      context.state.configuracion.monedas = [];
      context.state.configuracion.tasasCambio = [];
      context.state.configuracion.costosEnvio = [];

      let pais_moneda_destino_id_data = [];


      for (let w = 0; w < context.state.obj_gf_base.data_base.length; w++) {
        const pais = context.state.obj_gf_base.data_base[w];

        // console.log(pais);

        for (let xx = 0; xx < pais.monedas.length; xx++) {
          const moneda_desde_pais = pais.monedas[xx];


          let index_moneda = context.state.configuracion.monedas.findIndex(moneda => {
            return moneda.id == moneda_desde_pais.id
          });
          if (index_moneda == -1) {
            let data_monedas = {
              id: moneda_desde_pais.id,
              sigla: moneda_desde_pais.sigla,
              nombre_corto: moneda_desde_pais.nombre,
              simbolo: moneda_desde_pais.simbolo,
              cantidad_decimales: moneda_desde_pais.decimales,
            };
            context.state.configuracion.monedas.push(data_monedas);
          }

          let id_pais = `${pais.id}_${moneda_desde_pais.id}`;
          if (id_pais != "152_1") {
            context.state.configuracion.pais[id_pais] = {};
            context.state.configuracion.pais[id_pais].nombre = pais.nombre;
            context.state.configuracion.pais[id_pais].region = [];
            context.state.configuracion.pais[id_pais].region_caja = [];
            context.state.configuracion.pais[id_pais].moneda_caja = [];
            context.state.configuracion.pais[id_pais].comuna_id = [];
            context.state.configuracion.pais[id_pais].comuna_caja = [];
            context.state.configuracion.pais[id_pais].sucursales = [];
            context.state.configuracion.pais[id_pais].tipo_documento_id = [];
            context.state.configuracion.pais[id_pais].forma_entrega_id = [];
            context.state.configuracion.pais[id_pais].tipo_pago = [];
            context.state.configuracion.pais[id_pais].banco = [];
            context.state.configuracion.pais[id_pais].tipo_cuenta = [];
            context.state.configuracion.pais[id_pais].activo = "1";
            context.state.configuracion.pais[id_pais].pausa = "0";

            let formas_entrega_pais = {};
            let formas_pago_pais = {};

            if (pais.costos_servicio) //SOLO para SIMULAR
              for (let index = 0; index < pais.costos_servicio.length; index++) {
                const costo = pais.costos_servicio[index];
                if (!formas_entrega_pais[costo.formas_entrega_id]) {
                  formas_entrega_pais[costo.formas_entrega_id] = [costo.formas_entrega_id, costo.formas_entrega_nombre, pais.id];
                }
                if (!formas_pago_pais[costo.formas_pago_id]) {
                  formas_pago_pais[costo.formas_pago_id] = [costo.formas_pago_id, costo.formas_pago_nombre, pais.id];
                }
              }


            let formas_entrega_pais_resumen = Object.entries(formas_entrega_pais);
            for (let index = 0; index < formas_entrega_pais_resumen.length; index++) {
              const entregas = formas_entrega_pais_resumen[index];
              context.state.configuracion.pais[id_pais].forma_entrega_id.push(entregas[1]);
            }

            let formas_pago_pais_resumen = Object.entries(formas_pago_pais);
            for (let index = 0; index < formas_pago_pais_resumen.length; index++) {
              const pago = formas_pago_pais_resumen[index];
              context.state.configuracion.pais[id_pais].tipo_pago.push(pago[1]);
            }

            if (id_pais != "152_1") {
              pais_moneda_destino_id_data.push({
                pais_nom: pais.nombre,
                pais_val: id_pais,
                disable: false,
                activo: "1",
                pausa: "0",
                sigla: moneda_desde_pais.sigla
              });
            }
          }

          for (let index = 0; index < pais.tasas_cliente.length; index++) {

            if (pais.tasas_cliente[index].pais_id != "152") {
              const tasa = pais.tasas_cliente[index];
              let data_tasas = {
                id_moneda_destino: tasa.moneda_fin_id,
                id_moneda_origen: tasa.moneda_inicio_id,
                id_pais_destino: `${tasa.pais_id}_${tasa.moneda_fin_id}`,
                id_pais_origen: "152_1",
                valor_cambio: tasa.tasa,
                valor_maximo_origen: "5000000",
                valor_minimo_origen: "8000",
                tasa_transaccion_id: tasa.id
              }

              context.state.configuracion.tasasCambio.push(data_tasas);
            }

          }

          if (pais.costos_servicio) //SOLO para SIMULAR
            for (let index = 0; index < pais.costos_servicio.length; index++) {
              const costo = pais.costos_servicio[index];
              let data_costos = {
                monto_inicio: costo.monto_inicio,
                monto_fin: costo.monto_fin,
                pais_destino: costo.pais_id,
                valor_envio: "0",
                formas_pago_id: costo.formas_pago_id,
                formas_entrega_id: costo.formas_entrega_id,
                valor_porcentual: costo.valor_porcentual,
                costos_servicio: costo.id
              };
              context.state.configuracion.costosEnvio.push(data_costos);
            }

        }
      }

      let id_pais = "152_1";
      context.state.configuracion.pais[id_pais] = {};
      context.state.configuracion.pais[id_pais].nombre = "Chile";
      context.state.configuracion.pais[id_pais].region = [];
      context.state.configuracion.pais[id_pais].comuna_id = [];
      context.state.configuracion.pais[id_pais].tipo_documento_id = [];
      context.state.configuracion.pais[id_pais].activo = "1";
      context.state.configuracion.pais[id_pais].pausa = "0";

      context.state.configuracion.pais["152_1"].residencia = [];
      context.state.configuracion.data.auxiliares.nacionalidad = [];
      context.state.configuracion.data.auxiliares.nacionalidad[0] = [];

      context.state.configuracion.data.auxiliares.nacionalidad[0].push(["152", "Chile", 0]);
      context.state.configuracion.pais["152_1"].residencia.push(["152", "Chile", "152"]);

      for (let index = 0; index < context.state.obj_gf_base.paises.length; index++) {
        const pais = context.state.obj_gf_base.paises[index];
        context.state.configuracion.pais["152_1"].residencia.push([pais.id, pais.nombre, "152"]);
        context.state.configuracion.data.auxiliares.nacionalidad[0].push([pais.id, pais.nombre, 0]);
      }

      let data_monedas = {
        id: "1",
        sigla: "CLP",
        nombre_corto: "Peso",
        simbolo: "$",
        cantidad_decimales: "0",
      };
      context.state.configuracion.monedas.push(data_monedas);


      context.state.configuracion.data.auxiliares.nacionalidad[0].sort((a, b) => {
        return Intl.Collator().compare(a[1], b[1]);
      });

      context.state.configuracion.data.auxiliares.pais_moneda_destino_id = pais_moneda_destino_id_data;

      context.state.remitente.destinatarios = {};

      // console.log("*****", context.state.configuracion);

      context.commit("set_resp_create_config_others", { resp: true });
    },

    async reload_tasas_costo_serv_pausa(context) {

      await context.dispatch("traer_datos_simulacion");
      // console.log("%%%");
      context.state.configuracion.monedas = [];
      context.state.configuracion.tasasCambio = [];
      context.state.configuracion.costosEnvio = [];

      for (let w = 0; w < context.state.obj_gf_base.data_base.length; w++) {
        const pais = context.state.obj_gf_base.data_base[w];

        for (let xx = 0; xx < pais.monedas.length; xx++) {
          const moneda_desde_pais = pais.monedas[xx];

          let index_moneda = context.state.configuracion.monedas.findIndex(moneda => {
            return moneda.id == moneda_desde_pais.id
          });
          if (index_moneda == -1) {
            let data_monedas = {
              id: moneda_desde_pais.id,
              sigla: moneda_desde_pais.sigla,
              nombre_corto: moneda_desde_pais.nombre,
              simbolo: moneda_desde_pais.simbolo,
              cantidad_decimales: moneda_desde_pais.decimales,
            };
            context.state.configuracion.monedas.push(data_monedas);
          }

          let id_pais = `${pais.id}_${moneda_desde_pais.id}`;

          let formas_entrega_pais = {};
          let formas_pago_pais = {};

          if (pais.costos_servicio) //SOLO para SIMULAR
            for (let index = 0; index < pais.costos_servicio.length; index++) {
              const costo = pais.costos_servicio[index];
              if (!formas_entrega_pais[costo.formas_entrega_id]) {
                formas_entrega_pais[costo.formas_entrega_id] = [costo.formas_entrega_id, costo.formas_entrega_nombre, pais.id];
              }
              if (!formas_pago_pais[costo.formas_pago_id]) {
                formas_pago_pais[costo.formas_pago_id] = [costo.formas_pago_id, costo.formas_pago_nombre, pais.id];
              }
            }


          let formas_entrega_pais_resumen = Object.entries(formas_entrega_pais);
          context.state.configuracion.pais[id_pais].forma_entrega_id = [];
          for (let index = 0; index < formas_entrega_pais_resumen.length; index++) {
            const entregas = formas_entrega_pais_resumen[index];
            context.state.configuracion.pais[id_pais].forma_entrega_id.push(entregas[1]);
          }

          let formas_pago_pais_resumen = Object.entries(formas_pago_pais);
          context.state.configuracion.pais[id_pais].tipo_pago = [];
          for (let index = 0; index < formas_pago_pais_resumen.length; index++) {
            const pago = formas_pago_pais_resumen[index];
            context.state.configuracion.pais[id_pais].tipo_pago.push(pago[1]);
          }

        }

        for (let index = 0; index < pais.tasas_cliente.length; index++) {

          if (pais.tasas_cliente[index].pais_id != "152") {
            const tasa = pais.tasas_cliente[index];
            let data_tasas = {
              id_moneda_destino: tasa.moneda_fin_id,
              id_moneda_origen: tasa.moneda_inicio_id,
              id_pais_destino: `${tasa.pais_id}_${tasa.moneda_fin_id}`,
              id_pais_origen: "152_1",
              valor_cambio: tasa.tasa,
              valor_maximo_origen: "5000000",
              valor_minimo_origen: "8000",
              tasa_transaccion_id: tasa.id
            }

            context.state.configuracion.tasasCambio.push(data_tasas);
          }

        }

        let pais_min_envio = 0;
        let pais_max_envio = 0;
        let pais_costo_envio_temp = [];
        // console.log(pais.costos_servicio);
        if (pais.costos_servicio) { //SOLO para SIMULAR
          for (let index = 0; index < pais.costos_servicio.length; index++) {
            const costo = pais.costos_servicio[index];
            if (index == 0) {
              pais_min_envio = parseInt(costo.monto_inicio);
              pais_max_envio = parseInt(costo.monto_fin);
            } else {
              if (parseInt(costo.monto_inicio) < pais_min_envio) {
                pais_min_envio = parseInt(costo.monto_inicio);
              }
              if (costo.monto_fin > pais_max_envio) {
                pais_max_envio = costo.monto_fin;
              }
            }
            let data_costos = {
              monto_inicio: costo.monto_inicio,
              monto_fin: costo.monto_fin,
              pais_destino: costo.pais_id,
              valor_envio: "0",
              formas_pago_id: costo.formas_pago_id,
              formas_entrega_id: costo.formas_entrega_id,
              valor_porcentual: costo.valor_porcentual,
              costos_servicio_id: costo.id
            };
            // context.state.configuracion.costosEnvio.push(data_costos);
            pais_costo_envio_temp.push(data_costos);
          }
          for (let index = 0; index < pais_costo_envio_temp.length; index++) {
            const costo = pais_costo_envio_temp[index];
            costo.valor_maximo_origen = pais_max_envio;
            costo.valor_minimo_origen = pais_min_envio;
            context.state.configuracion.costosEnvio.push(costo);
          }

        }
      }

      let data_monedas = {
        id: "1",
        sigla: "CLP",
        nombre_corto: "Peso",
        simbolo: "$",
        cantidad_decimales: "0",
      };
      context.state.configuracion.monedas.push(data_monedas);

      return true;

      // context.commit("set_resp_reload_tasas_costo_serv_pausa", { resp: true });
    },

    async repetirTransaccion(context, transac) {

      // console.log("--++ repetirTransaccion", transac);

      await context.dispatch("validarDestinoSelected", {
        nameBanderas:
          transac.pais_destino_nombre,
        pais_val: `${transac.pais_destino}_${transac.moneda_destino_id}`,
      });

      let moneda_origen_sigla = "";
      let moneda_origen_sigla_index = context.state.configuracion.monedas.findIndex(
        (moneda) => {
          return moneda.id == "1";
        }
      );
      if (moneda_origen_sigla_index > -1) {
        moneda_origen_sigla =
          context.state.configuracion.monedas[moneda_origen_sigla_index].sigla;
      }
      let moneda_destino_sigla = "";
      let moneda_destino_sigla_index = context.state.configuracion.monedas.findIndex(
        (moneda) => {
          return (
            moneda.id == transac.moneda_destino_id
          );
        }
      );
      if (moneda_destino_sigla_index > -1) {
        moneda_destino_sigla =
          context.state.configuracion.monedas[moneda_destino_sigla_index].sigla;
      }

      await context.commit("updateTransaccion", {
        moneda_origen_sigla: moneda_origen_sigla,
        moneda_destino_sigla: moneda_destino_sigla,
      });

      let tasa_entrega = 0;
      let tasa_mostrar = 0;
      let tasa_entrega_index = context.state.configuracion.tasasCambio.findIndex(
        (moneda_pais) => {
          return (
            moneda_pais.id_moneda_destino ==
            transac.moneda_destino_id
          );
        }
      );
      if (tasa_entrega_index > -1) {
        tasa_entrega =
          context.state.configuracion.tasasCambio[tasa_entrega_index].valor_cambio;
      }

      if (tasa_entrega < 1) {
        let decimales_index = context.state.configuracion.monedas.findIndex((moneda) => {
          return moneda.id == context.state.transaccion.moneda_destino_id;
        });
        let decimales = 0;
        if (decimales_index > -1) {
          decimales =
            context.state.configuracion.monedas[decimales_index].cantidad_decimales;
        }
        let tasa_calc = 1 / tasa_entrega;
        let formato = new RegExp(
          "^-?\\d+(?:.\\d{0," + (decimales || -1) + "})?"
        );
        tasa_mostrar = `1${context.state.transaccion.moneda_destino_sigla}=${tasa_calc.toString().match(formato)[0]
          }${context.state.transaccion.moneda_origen_sigla}`;
      } else {
        let decimales_index = context.state.configuracion.monedas.findIndex((moneda) => {
          return moneda.id == context.state.transaccion.moneda_destino_id;
        });
        let decimales = 0;
        if (decimales_index > -1) {
          decimales =
            context.state.configuracion.monedas[decimales_index].cantidad_decimales;
        }
        let tasa_calc = tasa_entrega;
        let formato = new RegExp(
          "^-?\\d+(?:.\\d{0," + (decimales || -1) + "})?"
        );
        tasa_mostrar = `1${context.state.transaccion.moneda_origen_sigla}=${tasa_calc.toString().match(formato)[0]
          }${context.state.transaccion.moneda_destino_sigla}`;
      }


      let datos_calc_orig = await context.dispatch("calcular_monto_origen", {
        destino: transac.monto_destino,
        descuentoPorcentaje: 0,
        descuento: 0,
        valor_cambio: tasa_entrega,
      });

      await context.commit("updateTransaccion", {
        monto_pagar: datos_calc_orig.origen_temp,
        monto_destino: transac.monto_destino,
        monto_origen: await context.dispatch("formatNumber", datos_calc_orig.origen_temp),
        tasa_mostrar: tasa_mostrar,
      });

      await context.dispatch("validacionesAux", {
        porcentaje_descuento: 0,
        id: null,
        cupon: null,
        veces_usado: null,
        descuento: 0,
      });

      let destinatario = {
        accion: "seleccionar_transaccion",
        id_persona: transac.id_persona,
        valBanderas: transac.pais_destino,
        forma_entrega_id:
          transac.forma_entrega_id,
        banco: transac.banco,
        detalle_entrega_id:
          transac.detalle_entrega_id,
        id_banco_destino: transac.bancos_id,
        tipo_cuenta:
          transac.bancos_tipo_cuentas_id,
        nro_cuenta: transac.nro_cuenta,
        motivo_transaccion:
          transac.motivo_transaccion,
        bancos_nombre: transac.bancos_nombre,
      };

      // await this.validarTarjetaBeneficiario(destinatario);
      await context.dispatch("validarTarjetaBeneficiario", destinatario);

      setTimeout(() => {
        // this.set_nav_stack({ desde: ["home"], menuSp: false });
        context.commit("set_nav_stack", { desde: ["home"], menuSp: false });
        return true;

      }, 200);
    },
    //--------------------------------------------------------------------------------------------





    async check_version(context) {
      let recargar = false;
      let evento = "";
      if (
        typeof context.state.configuracion.version == "undefined" ||
        context.state.configuracion.version == ""
      ) {
        recargar = true;
        evento = 1;
      } else {
        //TODO-Simular
        // await Axios({
        //   method: 'GET',
        //   url: 'v2/assets/js/current5.json'

        // }).then((response) => {
        // let response = {};
        // response.data = context.state.current5;

        // if (
        //   typeof response.data.gf == "undefined" ||
        //   response.data.gf != context.state.configuracion.version
        // ) {
        //   recargar = true;
        //   evento = 2;
        //   // console.log("response", response.data);
        // }
        // }).catch(err => {
        //   console.log(err);
        //   evento = 3;
        // });
      }
      if (recargar) {

        console.log(">>>>> requiere recuperar nueva version ", evento);
        context.dispatch("getNewObjGF");
      }
    },

    getNewObjGF(context) {
      console.log("getNewObjGF, desde ", context.state.configuracion.version);

    },

    async validarString(context, data) {

      let string = data.string;
      let validaciones = data.validaciones;

      let errores = [];

      for (let i = 0; i < validaciones.length; i++) {
        let validacion = validaciones[i];

        if (validacion == "boolean") {
          if (string != "Si" && string != "No") errores.push("boolean");
          continue;
        }

        if (validacion == "notempty") {
          if (string == null || string.length == 0) errores.push("notempty");
          continue;
        }

        if (string == null) {
          errores.push("null");
          continue;
        }

        if (validacion == "number") {
          let res = Number(string);
          if (isNaN(res) || string.length == 0) errores.push("number");
          continue;
        }

        if (validacion == "correo") {

          let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
          if (!string.match(mailformat)) errores.push("correo");
          continue;
        }

        /* Valor Minimo y Numerico */
        if (validacion.indexOf("valmin-") >= 0) {
          let valMin = Number(validaciones[i].substring(7));
          let valor = Number(string);
          if (isNaN(valor) || string.length == 0 || valMin > valor)
            errores.push("valmin");
          continue;
        }

        /* Valor Maximo y Numerico */
        if (validacion.indexOf("valmax-") >= 0) {
          let valMax = Number(validacion.substring(7));
          let valor = Number(string);
          if (isNaN(valor) || string.length == 0 || valMax < valor)
            errores.push("valmax");
          continue;
        }

        /* Cantidad Caracteres Minima */
        if (validacion.indexOf("charmin-") >= 0) {
          let charlimit = Number(validacion.substring(8));
          let valor = string.toString().length;

          if (charlimit > valor) errores.push("charmin");
          continue;
        }

        /* Cantidad Caracteres Maxima */
        if (validacion.indexOf("charmax-") >= 0) {
          let charlimit = Number(validacion.substring(8));
          let valor = string.toString().length;
          if (charlimit < valor) errores.push("charmax");
          continue;
        }

        /* Cantidad Caracteres Exacta */
        if (validacion.indexOf("chareq-") >= 0) {
          let charlimit = Number(validacion.substring(7));
          let valor = string.toString().length;
          if (charlimit != valor) errores.push("chareq");
          continue;
        }

        /* Validador Telefono */
        if (validacion.indexOf("phone-") >= 0) {
          let charlimit = Number(validacion.substring(6));
          let valor = string.toString().length;
          if (charlimit < valor) errores.push("charmax");
          continue;
        }

        // Nombre con apellido
        if (validacion.indexOf("nom-ape") >= 0) {
          let array = string.split(" ");
          if (array.length !== 2) {
            errores.push("chareq");
          }
          continue;
        }

        // Rut empresa
        if (validacion.indexOf("rut-empresa") >= 0) {
          let array = string.split("-");
          if (array.length !== 2) {

            errores.push("chareq");
          }
          if (parseInt(array[0]) > 50000000) {
            errores.push("rutemp");
          }
          if (
            !(await context.dispatch("validarRut", array[0] + "-" + array[1]))
          ) {
            errores.push("rutemp");
          }
          continue;
        }

      }

      return errores;
    },

    async validarRut(context, r) {
      let rut = r.length > 0 ? r.replace(/[^0-9kK]/gi, "") : 0;

      let estado = false;
      if (await context.dispatch("formatoValidoRut", rut)) {
        let rut_in = rut.substring(0, rut.length - 1);
        let dv_in = rut.substring(rut.length - 1);
        let rut_full = rut_in;
        let sumatoria = 0;
        let multiplicador = 2;
        let indice = 0;
        for (let i = rut_full.length - 1; i >= 0; i--) {
          if (multiplicador == 8) multiplicador = 2;
          indice = parseInt(rut_full.charAt(i));
          sumatoria = sumatoria + indice * multiplicador;
          multiplicador++;
        }
        let dv = 11 - (sumatoria % 11);
        switch (dv) {
          case 10:
            dv = "k";
            break;
          case 11:
            dv = "0";
            break;
        }
        dv = "" + dv + "";
        if (dv_in == "K") {
          dv_in = "k";
        }
        estado = dv == dv_in;
      }
      return estado;
    },

    formatoValidoRut(context, r) {
      var expreg = /^([0-9]{7,8}[0-9kK])$/;
      return expreg.test(r);
    },

    async logIn(context, usuario) {

      context.commit("updateRespLogIn", { resp: false, msg: "" });
      context.commit("updateRemitente", {
        destinatarios: {},
        genero: "",
        ultimas_transacciones: [],
        via_contacto: "",
      });

      context.commit("updateFaltanDatos", false);
      const params = new URLSearchParams();
      params.append("usuario", usuario.usuario);
      params.append("clave", usuario.contrasena);

      Axios({
        method: "POST",
        url: `${context.state.link_controlador}cliente/login`,
        data: params,
        withCredentials: false,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(async (resp) => {
          const response = resp.data;
          // console.log(response);
          if (response.cliente && response.token) {

            context.commit("updateSessionConfirm", 1);

            let propiedades = Object.entries(response.cliente);

            for (let index = 0; index < propiedades.length; index++) {
              const element = propiedades[index];
              if (element[0] == "comuna_id") {
                context.state.remitente.comuna_id = element[1];
              } else if (element[0] == "correo") {
                context.state.remitente.correo = element[1];
              } else if (element[0] == "nacionalidad_pais_id") {
                context.state.remitente.nacionalidad = element[1];
              } else if (element[0] == "nombre1") {
                context.state.remitente.nombre1 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "nombre2") {
                context.state.remitente.nombre2 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "apellido1") {
                context.state.remitente.apellido1 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "apellido2") {
                context.state.remitente.apellido2 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "ciudad") {
                context.state.remitente.ciudad = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "ocupacion_id") {
                context.state.remitente.ocupacion = element[1];
              } else if (element[0] == "residencia_pais_id") {
                context.state.remitente.residencia = element[1];
              } else if (element[0] == "region_id") {
                context.state.remitente.region = element[1];
              } else if (element[0] == "telefono") {
                if (element[1] && element[1].indexOf("-") > -1) {
                  context.state.remitente.telefono = element[1].replaceAll(" ", "");
                } else {
                  context.state.remitente.telefono = "";
                }
              } else {
                context.state.remitente[element[0]] = element[1];
              }
            }

            context.state.remitente.token = response.token.token;
            context.state.remitente.fecha_loin = response.token.fecha;
            context.state.remitente.validacion_mensual = { estado: true, maximo_clp: 5000000 };

            if (context.state.remitente.estado_registro && context.state.remitente.estado_registro == "2") {
              context.state.remitente.residencia = "152";
              context.state.remitente.region = "";
            }

            context.dispatch("traer_destinatarios");

            const d = new Date();

            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));

            let expires = "expires=" + d.toUTCString();

            try {

              document.cookie = `gf_token=${response.token.token};${expires}; path=${context.state.cookie_domain}`;
              document.cookie = `gf_name=${context.state.remitente.nombres};${expires}; path=${context.state.cookie_domain}`;
              document.cookie = `gf_document=${context.state.remitente.numero_documento};${expires}; path=${context.state.cookie_domain}`;
            } catch (error) {
              console.log(error);
            }

            let niveles = Math.max.apply(
              Math,
              context.state.configuracion.reglasFormularioRemitente.map(
                (campo) => {
                  return campo[3];
                }
              )
            );

            let dataFaltante = false;
            for (let index = 1; index <= niveles; index++) {
              if (
                await context.dispatch("tieneErroresNivel", {
                  tipo_persona: context.state.flujo.irPorPasosTipo,
                  id_persona: 0,
                  nivel: index,
                })
              ) {
                dataFaltante = true;
              }
            }

            if (dataFaltante) {
              context.commit("updateFaltanDatos", true);
            } else {
              context.commit("updateRespLogIn", { resp: true, msg: "" });
            }
          } else {
            context.commit("updateRespLogIn", {
              resp: false,
              msg: response.codigo,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    logIn_con_token(context, token) {
      // console.log("logIn_con_token");
      context.commit("updateRespLogIn", { resp: false, msg: "" });
      context.commit("updateRemitente", {
        destinatarios: {},
        genero: "",
        ultimas_transacciones: [],
        via_contacto: "",
      });

      context.commit("updateFaltanDatos", false);
      const params = new URLSearchParams();
      params.append("token", token);

      Axios({
        method: "POST",
        url: `${context.state.link_controlador}cliente/loginToken`,
        data: params,
        withCredentials: false,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(async (resp) => {
          const response = resp.data;
          // console.log(response);
          if (response.cliente && response.token) {

            context.commit("updateSessionConfirm", 1);

            let propiedades = Object.entries(response.cliente[0]);
           

            for (let index = 0; index < propiedades.length; index++) {
              const element = propiedades[index];
              if (element[0] == "comuna_id") {
                context.state.remitente.comuna_id = element[1];
              } else if (element[0] == "correo") {
                context.state.remitente.correo = element[1];
              } else if (element[0] == "nacionalidad_pais_id") {
                context.state.remitente.nacionalidad = element[1];
              } else if (element[0] == "nombre1") {
                context.state.remitente.nombre1 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "nombre2") {
                context.state.remitente.nombre2 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "apellido1") {
                context.state.remitente.apellido1 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "apellido2") {
                context.state.remitente.apellido2 = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "ciudad") {
                context.state.remitente.ciudad = await context.dispatch("primera_letra_mayuscula", element[1]);
              } else if (element[0] == "ocupacion_id") {
                context.state.remitente.ocupacion = element[1];
              } else if (element[0] == "residencia_pais_id") {
                context.state.remitente.residencia = element[1];
              } else if (element[0] == "region_id") {
                context.state.remitente.region = element[1];
              } else if (element[0] == "telefono") {
                if (element[1] && element[1].indexOf("-") > -1) {
                  context.state.remitente.telefono = element[1].replaceAll(" ", "");
                } else {
                  context.state.remitente.telefono = "";
                }
              } else {
                context.state.remitente[element[0]] = element[1];
              }
            }

            context.state.remitente.token = response.token;
            // context.state.remitente.fecha_loin = response.token.fecha;
            context.state.remitente.validacion_mensual = { estado: true, maximo_clp: 5000000 };

            context.dispatch("traer_destinatarios");


            const d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            try {
              // document.cookie = `token=${response.token.token};rut=${context.state.remitente.numero_documento};${expires}`;
              document.cookie = `gf_token=${response.token};${expires}; path=${context.state.cookie_domain}`;
              document.cookie = `gf_name=${context.state.remitente.nombre1} ${context.state.remitente.nombre2};${expires}; path=${context.state.cookie_domain}`;
            } catch (error) {
              console.log(error);
            }

            let niveles = Math.max.apply(
              Math,
              context.state.configuracion.reglasFormularioRemitente.map(
                (campo) => {
                  return campo[3];
                }
              )
            );

            let dataFaltante = false;
            for (let index = 1; index <= niveles; index++) {
              if (
                await context.dispatch("tieneErroresNivel", {
                  tipo_persona: context.state.flujo.irPorPasosTipo,
                  id_persona: 0,
                  nivel: index,
                })
              ) {
                dataFaltante = true;
              }
            }

            if (dataFaltante) {
              context.commit("updateFaltanDatos", true);
            } else {
              context.commit("updateRespLogIn", { resp: true, msg: "" });
            }
          } else {

            document.cookie = `gf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
            document.cookie = `gf_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
            document.cookie = `gf_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
            document.cookie = `gf_name=; path=${context.state.cookie_domain}`;
            document.cookie = `gf_document=; path=${context.state.cookie_domain}`;

            context.commit("setshowSpinner", false);

            context.commit("updateRespLogIn", {
              resp: false,
              msg: "",
            });
          }
        })
        .catch((err) => {
          context.commit("updateRespLogIn", { resp: false, msg: "" });
          console.log("err", err);
        });

    },

    traer_destinatarios(context) {

      const params = new URLSearchParams();
      params.append("id", context.state.remitente.id);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/buscar`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.data && response.data.data.length > 0) {

            context.dispatch("traer_transacciones");

            // await context.dispatch("datos_minimos_destinatarios", response.data.data);      posponer

            for (let index = 0; index < response.data.data.length; index++) {
              const desti = response.data.data[index];

              if (desti.activo == "1") {
                let desti_remi = Object.entries(desti);
                let pais_ = "";
                let pais_index = desti_remi.findIndex(item => { return item[0] == "pais_id" });
                if (pais_index > -1) { pais_ = desti_remi[pais_index][1]; }
                let moneda_ = "";
                let moneda_index = desti_remi.findIndex(item => { return item[0] == "moneda_id" });
                if (moneda_index > -1) { moneda_ = desti_remi[moneda_index][1]; }

                let id_destinatario_index = desti_remi.findIndex(item => { return item[0] == "id" });
                let id_destinatario = "";

                if (id_destinatario_index > -1) {
                  id_destinatario = desti_remi[id_destinatario_index][1];
                } else {
                  return;
                }
                

                if (!context.state.remitente.destinatarios) {
                  context.state.remitente.destinatarios = {};
                }

                context.state.remitente.destinatarios[id_destinatario] = {};
                context.state.remitente.destinatarios[id_destinatario]["pais_moneda_destino_id"] = `${pais_}_${moneda_}`;
                await desti_remi.forEach(async (destinatario) => {
                  if (destinatario[0] == "cliente_id") {
                    context.state.remitente.destinatarios[id_destinatario]["id_cliente"] = destinatario[1];
                  }
                  // else if (destinatario[0] == "region_id") {
                  //   context.state.remitente.destinatarios[id_destinatario]["region"] = destinatario[1];
                  // }
                  else if (destinatario[0] == "region_id") {
                    context.state.remitente.destinatarios[id_destinatario]["region"] = destinatario[1];
                  }
                  // else if (destinatario[0] == "comuna_nombre") {
                  //   context.state.remitente.destinatarios[id_destinatario]["comuna_nombre"] = destinatario[1];
                  // } 
                  else if (destinatario[0] == "tipo_documento_id") {
                    context.state.remitente.destinatarios[id_destinatario]["tipo_documento_id"] = destinatario[1];
                  } else if (destinatario[0] == "correo") {
                    context.state.remitente.destinatarios[id_destinatario]["correo"] = destinatario[1];
                  } else if (destinatario[0] == "numero_documento") {
                    context.state.remitente.destinatarios[id_destinatario]["numero_documento"] = destinatario[1];
                  } else if (destinatario[0] == "nombre1") {
                    context.state.remitente.destinatarios[id_destinatario]["nombre1"] = await context.dispatch("primera_letra_mayuscula", destinatario[1]);
                  } else if (destinatario[0] == "nombre2") {
                    context.state.remitente.destinatarios[id_destinatario]["nombre2"] = await context.dispatch("primera_letra_mayuscula", destinatario[1]);
                  } else if (destinatario[0] == "apellido1") {
                    context.state.remitente.destinatarios[id_destinatario]["apellido1"] = await context.dispatch("primera_letra_mayuscula", destinatario[1]);
                  } else if (destinatario[0] == "apellido2") {
                    context.state.remitente.destinatarios[id_destinatario]["apellido2"] = await context.dispatch("primera_letra_mayuscula", destinatario[1]);
                  } else if (destinatario[0] == "id") {
                    context.state.remitente.destinatarios[id_destinatario]["id"] = destinatario[1];

                    let forma_entrega = [];
                    forma_entrega = await context.dispatch("traer_formas_entrega", destinatario[1]);

                    forma_entrega.forEach(async (for_ent) => {
                      let for_ent_array = Object.entries(for_ent);
                      let for_ent_id_index = for_ent_array.findIndex(item => { return item[0] == "id" });
                      let for_ent_id = "";
                      if (for_ent_id_index > -1) {
                        for_ent_id = for_ent_array[for_ent_id_index][1];
                      } else {
                        return;
                      }
                      if (!context.state.remitente.destinatarios[id_destinatario].detalle_entrega)
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega = {};
                      context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id] = {};
                      // let f_e_index = for_ent_array.findIndex(f_e => { return f_e[0] == "forma_entrega_id" });
                      // let f_e = "";
                      // if (f_e_index > -1) {
                      //   f_e = for_ent_array[f_e_index][1];
                      // }

                      let moneda_x;
                      let numero_cuenta;
                      let banco;
                      for_ent_array.forEach(async (detalle) => {

                        if (detalle[0] == "forma_entrega_id") {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["forma_entrega_id"] = detalle[1];
                        } else if (detalle[0] == "monedas_id") {
                          moneda_x = detalle[1];

                          // 
                        } else if (detalle[0] == "comunas_id") {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["comuna_caja"] = detalle[1];


                        } else if (detalle[0] == "bancos_id") {
                          banco = detalle[1];
                          // if (f_e == "1") {
                          //   // context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["id_banco_destino"] = detalle[1];
                          //   // context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["banco"] = detalle[1];
                          // } else if (f_e == "2") {
                          //   // context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["banco_caja"] = detalle[1];
                          // }


                        } else if (detalle[0] == "bancos_tipo_cuentas_id") {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["tipo_cuenta"] = detalle[1];
                        } else if (detalle[0] == "nro_cuenta") {
                          numero_cuenta = detalle[1];
                          // context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["numero_cuenta"] = detalle[1];
                        } else if (detalle[0] == "banco_sucursal_id") {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["sucursales"] = detalle[1];
                        }
                        // else this.if(detalle[0] == "banco_sucursal_id"){
                        //   context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["sucursales"] = detalle[1];
                        // } 
                        else if (detalle[0] == "region_id") {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["region_caja"] = detalle[1];
                        }
                        else if (detalle[0] == "comuna_id") {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["comuna_caja"] = detalle[1];
                        }
                        else {
                          context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id][detalle[0]] = detalle[1];
                        }




                      });
                      if (context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["forma_entrega_id"] == 1) {
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["moneda_destino_id"] = moneda_x;
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["numero_cuenta"] = numero_cuenta;
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["id_banco_destino"] = banco;
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["banco"] = banco;
                      } else if (context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["forma_entrega_id"] == 2) {
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["moneda_caja"] = moneda_x;
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["banco_caja"] = banco;
                      } else if (context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["forma_entrega_id"] == 6) {
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["moneda_destino_id"] = moneda_x;
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["numero_cuenta_wallet"] = numero_cuenta;
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id]["bco_wallet"] = banco;
                      }

                      if (id_destinatario == "74" && for_ent_id == "89") {
                        context.state.remitente.destinatarios[id_destinatario].detalle_entrega[for_ent_id].region_caja = "397"
                      }

                    });


                  } else if (destinatario[0] != "pais_id" && destinatario[0] != "moneda_id") {
                    context.state.remitente.destinatarios[id_destinatario][destinatario[0]] = destinatario[1];

                  }
                });
              }


            }



          } else {
            // console.log("traer_destinatarios", response.data);
            context.state.remitente.destinatarios = [];
            context.dispatch("send_errors", {
              error: response.data,
              controlador: `${context.state.link_controlador}destinatarios/buscar`,
              data: context.state.remitente.id
            });
          }

        }

      }).catch(err => {
        console.log(err);
        context.dispatch("send_errors", {
          error: err,
          controlador: `${context.state.link_controlador}destinatarios/buscar`,
          data: context.state.remitente.id
        });
      });
    },

    //   function capitalizeFirstLetterOfEachWord(str) {
    //     return str.toLowerCase().split(' ').map(function(word) {
    //         return word.charAt(0).toUpperCase() + word.substring(1);
    //     }).join(' ');
    // }

    // console.log(capitalizeFirstLetterOfEachWord('hello world')); // Outputs: "Hello World"

    async traer_regiones_tipo_docs(context, pais_destino) {

      context.commit("update_datos_consultados", { pais: pais_destino, status: false, regiones_bancos: "regiones" });

      let destinos_relacionados = [];
      let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
      for (let w = 0; w < destinos_relacionados_array.length; w++) {
        if (destinos_relacionados_array[w][0].split("_")[0] == pais_destino) {
          destinos_relacionados.push(destinos_relacionados_array[w][0]);
        }
      }
      let regiones = await context.dispatch("traer_regiones", pais_destino);
      regiones.sort((a, b) => {
        return Intl.Collator().compare(a.nombre, b.nombre);
      });
      for (let index1 = 0; index1 < regiones.length; index1++) {
        const region = regiones[index1];
        for (let x = 0; x < destinos_relacionados.length; x++) {
          const destino_relacionado = destinos_relacionados[x];
          await context.state.configuracion.pais[destino_relacionado].region.push([
            region.id, await context.dispatch("primera_letra_mayuscula", region.nombre), destino_relacionado
          ]);
        }
      }

      let tipo_doc = await context.dispatch("traer_tipo_doc", pais_destino);

      tipo_doc.sort((a, b) => {
        return Intl.Collator().compare(a.nombre, b.nombre);
      });

      for (let x = 0; x < destinos_relacionados.length; x++) {
        const destino_relacionado = destinos_relacionados[x];
        context.state.configuracion.pais[destino_relacionado].tipo_documento_id = [];
        for (let index = 0; index < tipo_doc.length; index++) {
          const element = tipo_doc[index];
          context.state.configuracion.pais[destino_relacionado].tipo_documento_id.push([
            element.id, await context.dispatch("primera_letra_mayuscula", element.nombre), 0
          ]);
        }
      }




      context.commit("update_datos_consultados", { pais: pais_destino, status: true, regiones_bancos: "regiones" });

    },

    async traer_bancos_pais_destino(context, pais_) {
      // console.log("----------------------------------------------------");

      context.commit("update_datos_consultados", { pais: pais_, status: false, regiones_bancos: "bancos" });

      let destinos_relacionados = [];
      let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
      let bancos_pais = [];
      let moneda_ = "";
      for (let w = 0; w < destinos_relacionados_array.length; w++) {
        if (destinos_relacionados_array[w][0].split("_")[0] == pais_) {
          if (moneda_ == "") moneda_ = destinos_relacionados_array[w][0].split("_")[1];
          destinos_relacionados.push(destinos_relacionados_array[w][0]);
        }
      }
      // console.log(pais_, moneda_, context.state.datos_consultados);
      for (let j = 0; j < context.state.configuracion.pais[`${pais_}_${moneda_}`].forma_entrega_id.length; j++) {
        const forma_ent = context.state.configuracion.pais[`${pais_}_${moneda_}`].forma_entrega_id[j];
        bancos_pais = await context.dispatch("traer_bancos", { pais: pais_, forma_entrega: forma_ent[0] });
        bancos_pais.sort((a, b) => {
          return Intl.Collator().compare(a.banco_nombre, b.banco_nombre);
        });

        for (let w = 0; w < bancos_pais.length; w++) {
          const banco = bancos_pais[w];
          if (forma_ent[0] == "1") {

            for (let m = 0; m < destinos_relacionados.length; m++) {
              const destin = destinos_relacionados[m];
              context.state.configuracion.pais[destin].banco.push([banco.banco_id, banco.banco_nombre, 0, forma_ent[0]]);
              let tipos_cuentas = [];
              // console.log({ banco: banco.banco_id, moneda: destin.split("_")[1] }, bancos_pais);
              tipos_cuentas = await context.dispatch("traer_cuentas_banco", { banco: banco.banco_id, moneda: destin.split("_")[1] });
              if (tipos_cuentas.length > 0) {
                for (let z = 0; z < tipos_cuentas.length; z++) {
                  const tipo = tipos_cuentas[z];
                  context.state.configuracion.pais[destin].tipo_cuenta.push([tipo.id, tipo.tipos_cuentas_nombre, destin.split("_")[1], banco.banco_id]);
                  for (let g = 0; g < destinos_relacionados.length; g++) {
                    const destin_repaso = destinos_relacionados[g];
                    if (destin_repaso != destin) {
                      context.state.configuracion.pais[destin_repaso].tipo_cuenta.push([tipo.id, tipo.tipos_cuentas_nombre, destin.split("_")[1], banco.banco_id]);
                    }
                  }
                }
              } else {
                let bco_index = context.state.configuracion.pais[destin].banco.findIndex(bco => { return bco[0] == banco.banco_id });
                if (bco_index > -1) {
                  context.state.configuracion.pais[destin].banco.splice(bco_index, 1);
                }
              }

            }

          }
          //  else if (forma_ent[0] == "2") {

          // }
        }
      }

      // }
      context.commit("update_datos_consultados", { pais: pais_, status: true, regiones_bancos: "bancos" });
    },

    async traer_bancos_pais_destino_v2(context, data) {
      // console.log("traer_bancos_pais_destino_v2", data.form_ent);
      let pais_ = data.pais;

      let destinos_relacionados = [];
      let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
      let bancos_pais = [];
      let moneda_ = "";
      for (let w = 0; w < destinos_relacionados_array.length; w++) {
        if (destinos_relacionados_array[w][0].split("_")[0] == pais_) {
          if (moneda_ == "") moneda_ = destinos_relacionados_array[w][0].split("_")[1];
          destinos_relacionados.push(destinos_relacionados_array[w][0]);
        }
      }

      bancos_pais = await context.dispatch("traer_bancos", { pais: pais_, forma_entrega: data.form_ent });
      bancos_pais.sort((a, b) => {
        return Intl.Collator().compare(a.banco_nombre, b.banco_nombre);
      });

      if (data.form_ent == "1") {
        if (!context.state.datos_consultados[pais_]) {
          context.state.datos_consultados[pais_] = {};
        }
        if (!context.state.datos_consultados[pais_].banco) {
          context.state.datos_consultados[pais_].banco = {};
        }

      } else if (data.form_ent == "2") {
        if (!context.state.datos_consultados[pais_]) {
          context.state.datos_consultados[pais_] = {};
        }
        if (!context.state.datos_consultados[pais_].banco_caja) {
          context.state.datos_consultados[pais_].banco_caja = {};
        }

        for (let p = 0; p < destinos_relacionados.length; p++) {
          const destin = destinos_relacionados[p];
          if (!context.state.configuracion.pais[destin].sucursales)
            context.state.configuracion.pais[destin].sucursales = [];
          if (!context.state.configuracion.pais[destin].banco_caja)
            context.state.configuracion.pais[destin].banco_caja = [];

          context.state.configuracion.pais[destin].sucursales = [];
          context.state.configuracion.pais[destin].banco_caja = [];
        }

      } else if (data.form_ent == "6") {
        // console.log(data.form_ent, pais_);
        if (!context.state.datos_consultados[pais_]) {
          context.state.datos_consultados[pais_] = {};
        }
        if (!context.state.datos_consultados[pais_].bco_wallet) {
          context.state.datos_consultados[pais_].bco_wallet = [];
        }


        for (let p = 0; p < destinos_relacionados.length; p++) {
          const destin = destinos_relacionados[p];
          if (!context.state.configuracion.pais[destin].bco_wallet)
            context.state.configuracion.pais[destin].bco_wallet = [];

          context.state.configuracion.pais[destin].bco_wallet = [];
        }
      }

      // console.log(bancos_pais);
      for (let w = 0; w < bancos_pais.length; w++) {
        const banco = bancos_pais[w];
        if (data.form_ent == "1") {

          for (let m = 0; m < destinos_relacionados.length; m++) {
            const destin = destinos_relacionados[m];
            context.state.configuracion.pais[destin].banco.push([banco.banco_id, banco.banco_nombre, 0, data.form_ent]);
            let tipos_cuentas = [];

            tipos_cuentas = await context.dispatch("traer_cuentas_banco", { banco: banco.banco_id, moneda: destin.split("_")[1] });
            // console.log(tipos_cuentas);
            if (tipos_cuentas.length > 0) {
              for (let z = 0; z < tipos_cuentas.length; z++) {
                const tipo = tipos_cuentas[z];
                context.state.configuracion.pais[destin].tipo_cuenta.push([tipo.id, tipo.tipos_cuentas_nombre, destin.split("_")[1], banco.banco_id]);
                for (let g = 0; g < destinos_relacionados.length; g++) {
                  const destin_repaso = destinos_relacionados[g];
                  if (destin_repaso != destin) {
                    context.state.configuracion.pais[destin_repaso].tipo_cuenta.push([tipo.id, tipo.tipos_cuentas_nombre, destin.split("_")[1], banco.banco_id]);
                  }
                }
              }
            }
          }

        } else if (data.form_ent == "2") {


          for (let t = 0; t < context.state.configuracion.pais[`${pais_}_${moneda_}`].comuna_caja.length; t++) {
            const comuna_caja_general = context.state.configuracion.pais[`${pais_}_${moneda_}`].comuna_caja[t];
            let sucursales = await context.dispatch("traer_sucursales", { banco: banco.banco_id, comuna_caja: comuna_caja_general[0] });



            for (let p = 0; p < destinos_relacionados.length; p++) {
              const destin = destinos_relacionados[p];
              if (!context.state.configuracion.pais[destin].banco_caja) {
                context.state.configuracion.pais[destin].banco_caja = [];
              }

              for (let p = 0; p < sucursales.length; p++) {
                const sucursal = sucursales[p];
                if (sucursal.activo == "1") {
                  context.state.configuracion.pais[destin].sucursales.push([sucursal.id, sucursal.nombre, sucursal.comuna_id, sucursal.banco_id]);

                }
              }
              context.state.configuracion.pais[destin].banco_caja.push([banco.banco_id, banco.banco_nombre, banco.pagador_id, comuna_caja_general[0]]);
            }
          }
        } else if (data.form_ent == "6") {
          // console.log(banco);
          for (let m = 0; m < destinos_relacionados.length; m++) {
            const destin = destinos_relacionados[m];
            context.state.configuracion.pais[destin].bco_wallet.push([banco.banco_id, banco.banco_nombre, 0, destin.split("_")[1], data.form_ent]);

          }

        }


      }

      if (data.form_ent == "1") {
        context.state.datos_consultados[pais_].banco.time = new Date().getTime();
      } else if (data.form_ent == "2") {
        for (let p = 0; p < destinos_relacionados.length; p++) {
          const destin = destinos_relacionados[p];
          context.state.configuracion.pais[destin].sucursales.sort((a, b) => {
            return Intl.Collator().compare(a[1], b[1]);
          });
        }

        context.state.datos_consultados[pais_].banco_caja.time = new Date().getTime();
      } else if (data.form_ent == "6") {
        context.state.datos_consultados[pais_].bco_wallet.time = new Date().getTime();
      }

    },

    async traer_bancos_caja(context, data) {
      
      let pais_ = data.pais;

      let destinos_relacionados = [];
      let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
      let bancos_pais = [];
      let moneda_ = "";
      for (let w = 0; w < destinos_relacionados_array.length; w++) {
        if (destinos_relacionados_array[w][0].split("_")[0] == pais_) {
          if (moneda_ == "") moneda_ = destinos_relacionados_array[w][0].split("_")[1];
          destinos_relacionados.push(destinos_relacionados_array[w][0]);
        }
      }

      bancos_pais = await context.dispatch("traer_bancos", { pais: pais_, forma_entrega: data.form_ent });
      bancos_pais.sort((a, b) => {
        return Intl.Collator().compare(a.banco_nombre, b.banco_nombre);
      });

      if (data.form_ent == "2") {
        if (!context.state.datos_consultados[pais_]) {
          context.state.datos_consultados[pais_] = {};
        }
        if (!context.state.datos_consultados[pais_].banco_caja) {
          context.state.datos_consultados[pais_].banco_caja = {};
        }
        if (!context.state.datos_consultados[pais_].banco_caja[`${data.comuna}`]) {
          context.state.datos_consultados[pais_].banco_caja[`${data.comuna}`] = {};
        }

        for (let p = 0; p < destinos_relacionados.length; p++) {
          const destin = destinos_relacionados[p];
          if (!context.state.configuracion.pais[destin].sucursales)
            context.state.configuracion.pais[destin].sucursales = [];
          if (!context.state.configuracion.pais[destin].banco_caja)
            context.state.configuracion.pais[destin].banco_caja = [];

        }

      }

      for (let w = 0; w < bancos_pais.length; w++) {
        const banco = bancos_pais[w];
        if (data.form_ent == "2") {


          for (let t = 0; t < context.state.configuracion.pais[`${pais_}_${moneda_}`].comuna_caja.length; t++) {
            const comuna_caja_general = context.state.configuracion.pais[`${pais_}_${moneda_}`].comuna_caja[t];
            let sucursales = await context.dispatch("traer_sucursales", { banco: banco.banco_id, comuna_caja: comuna_caja_general[0] });

            let existe_sucursal_vigente = false;

            for (let p = 0; p < destinos_relacionados.length; p++) {
              const destin = destinos_relacionados[p];

              for (let p = 0; p < sucursales.length; p++) {
                const sucursal = sucursales[p];
                if (sucursal.activo == "1") {
                  existe_sucursal_vigente = true;
                  console.log(sucursal.id, sucursal.nombre, sucursal.comuna_id, sucursal.banco_id);
                  let sucursal_existe = -1;
                  for (let index = 0; index < context.state.configuracion.pais[destin].sucursales.length; index++) {
                    const sucur = context.state.configuracion.pais[destin].sucursales[index];
                    if (sucur[0] == sucursal.id && sucur[3] == sucursal.banco_id) sucursal_existe = index;
                  }
                  if (sucursal_existe > -1) {
                    context.state.configuracion.pais[destin].sucursales[sucursal_existe] = [sucursal.id, sucursal.nombre, sucursal.comuna_id, sucursal.banco_id];
                  } else {
                    context.state.configuracion.pais[destin].sucursales.push([sucursal.id, sucursal.nombre, sucursal.comuna_id, sucursal.banco_id]);
                  }
                }
              }
              if (existe_sucursal_vigente) {
                let banco_existe = -1;
                for (let index = 0; index < context.state.configuracion.pais[destin].banco_caja.length; index++) {
                  const bco = context.state.configuracion.pais[destin].banco_caja[index];
                  if (bco[0] == banco.banco_id && bco[2] == banco.pagador_id && bco[3] == comuna_caja_general[0]) banco_existe = index;
                }
                if (banco_existe > -1) {
                  console.log("Ya existe bco", banco.banco_id, banco.banco_nombre, banco.pagador_id, comuna_caja_general[0]);
                  context.state.configuracion.pais[destin].banco_caja[banco_existe] = [banco.banco_id, banco.banco_nombre, banco.pagador_id, comuna_caja_general[0]];
                } else {
                  console.log("Bco nuevo", banco_existe, banco.banco_id, banco.banco_nombre, banco.pagador_id, comuna_caja_general[0]);
                  context.state.configuracion.pais[destin].banco_caja.push([banco.banco_id, banco.banco_nombre, banco.pagador_id, comuna_caja_general[0]]);
                }
              }

            }
          }
        }

      }

      if (data.form_ent == "2") {
        for (let p = 0; p < destinos_relacionados.length; p++) {
          const destin = destinos_relacionados[p];
          context.state.configuracion.pais[destin].sucursales.sort((a, b) => {
            return Intl.Collator().compare(a[1], b[1]);
          });
        }

        context.state.datos_consultados[pais_].banco_caja[`${data.comuna}`].time = new Date().getTime();
      }

    },

    send_errors(context, data) {
      const params = new URLSearchParams();
      params.append("mensaje_error", data.error);
      params.append("controlador", data.controlador);
      params.append("codigo_error", "");
      params.append("data", data.data);
      params.append("envia_email", true);
      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}auxiliares/enviaErrores`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
        },
        data: params
      }).then(async (response) => {
        console.log(response.data, data);
      }).catch(err => {
        console.log(err);
        return [];
      });
      // context.dispatch("send_errors",{
      //   error:errX,
      //   controlador:"X",
      //   data:dataX
      // });
    },
    async traer_monedas_caja(context, pais) {

      const params = new URLSearchParams();
      params.append("pais_id", pais.split("_")[0]);
      return Axios({
        method: 'POST',
        url: `${context.state.link_controlador}auxiliares/bancoMonedas`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log(response.data);
        if (!response.data.error) {
          if (!context.state.datos_consultados[pais]) {
            context.state.datos_consultados[pais] = {};
          }
          if (!context.state.datos_consultados[pais].moneda_caja) {
            context.state.datos_consultados[pais].moneda_caja = {};
          }
          if (!context.state.datos_consultados[pais].banco_caja) {
            context.state.datos_consultados[pais].banco_caja = {};
          }

          let destinos_relacionados = [];
          let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);
          let monedas_pais = [];
          let bancos_moneda_pais = [];

          for (let w = 0; w < destinos_relacionados_array.length; w++) {
            if (destinos_relacionados_array[w][0].split("_")[0] == pais) {
              destinos_relacionados.push(destinos_relacionados_array[w][0]);
            }
          }

          for (let index = 0; index < response.data.monedas.length; index++) {
            const moneda_bco = response.data.monedas[index];
            let moneda_registrada = false;
            for (let index = 0; index < monedas_pais.length; index++) {
              const mon = monedas_pais[index];
              if (mon[0] == moneda_bco.moneda_id) {
                moneda_registrada = true;
                break;
              }
            }
            if (!moneda_registrada) {
              let moneda_index = context.state.configuracion.monedas.findIndex(mon => { return mon.id == moneda_bco.moneda_id });
              if (moneda_index > -1) {
                monedas_pais.push([moneda_bco.moneda_id, context.state.configuracion.monedas[moneda_index].nombre_corto, context.state.configuracion.monedas[moneda_index].sigla, context.state.configuracion.monedas[moneda_index].cantidad_decimales, 2]);
              }
            }
            bancos_moneda_pais.push([moneda_bco.id, moneda_bco.banco_nombre, moneda_bco.moneda_id]);
          }
          for (let index = 0; index < destinos_relacionados.length; index++) {
            const element = destinos_relacionados[index];
            context.state.configuracion.pais[element].moneda_caja = [];
            context.state.configuracion.pais[element].moneda_caja = monedas_pais;

            context.state.configuracion.pais[element].banco_caja = [];
            context.state.configuracion.pais[element].banco_caja = bancos_moneda_pais;
          }

          context.state.datos_consultados[pais].moneda_caja.time = new Date().getTime();
          context.state.datos_consultados[pais].banco_caja.time = new Date().getTime();
          return true;
        } else {
          context.dispatch("send_errors", {
            error: response.data,
            controlador: `${context.state.link_controlador}auxiliares/bancoMonedas`,
            data: pais
          });
          return false;
        }
      }).catch(error => {
        console.log(error);
        context.dispatch("send_errors", {
          error: error,
          controlador: `${context.state.link_controlador}auxiliares/bancoMonedas`,
          data: pais
        });
        return false;
      });

    },

    async traer_formas_entrega(context, id_destinatario) {
      // let
      // console.log(context.state.remitente.token, context.state.remitente.id);
      const params = new URLSearchParams();
      params.append("destinatario_id", id_destinatario);
      return Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/formasEntregaDestinatario`,
        // withCredentials: false,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        // console.log("/",response.data.comunas);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.data.length > 0) {
            return response.data.data;
          } else {
            return [];
          }

        }

      }).catch(err => {
        console.log(err);
        context.dispatch("send_errors", {
          error: err,
          controlador: `${context.state.link_controlador}destinatarios/formasEntregaDestinatario`,
          data: id_destinatario
        });
        return [];
      });
    },

    traer_transacciones(context) {
      
      const params = new URLSearchParams();
      params.append("cliente", context.state.remitente.id);
      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}transacciones/ultimasTransacciones`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.transacciones.length > 0) {
           
            if (context.state.remitente.destinatarios) {
              let desti_array = Object.entries(context.state.remitente.destinatarios);
              desti_array.forEach(desti => {
                desti[1].ultimas_transacciones = [];
              });
            }

            for (let index = 0; index < response.data.transacciones.length; index++) {
              let tran = response.data.transacciones[index];

              if (!context.state.remitente.destinatarios[tran.destinatario_id].ultimas_transacciones) context.state.remitente.destinatarios[tran.destinatario_id].ultimas_transacciones = [];
              context.state.remitente.destinatarios[tran.destinatario_id].ultimas_transacciones.push(tran);
            }

          } else {
            console.log(response.data);
          }
        }
      }).catch(err => {
        console.log(err);
      });
    },

    // ultimasTransacciones(context){

    // },

    async traer_bancos(context, pais_moneda) {

      const params = new URLSearchParams();
      params.append("pais", pais_moneda.pais);
      params.append("formaEntrega", pais_moneda.forma_entrega);

      if (context.state.remitente.token) {
        return Axios({
          method: 'POST',
          url: `${context.state.link_controlador}Auxiliares/bancosFormaEntrega`,
          headers: {
            'Access-Control-Allow-Headers': 'Authorization',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${context.state.remitente.token}`,
          },
          data: params
        }).then(async (response) => {

          if (typeof response.data != "undefined" && response.data != null) {
            if (!response.data.error && response.data.bancos.length > 0) {
              return response.data.bancos;

            } else {
              // console.log("traer_bancos", response.data);
              // context.state.configuracion.nada = {};
              return [];
            }

          }

        }).catch(err => {
          context.state.configuracion.nada = { data: "nada" };
          console.log(err);
          return [];
        });
      }
    },

    async traer_cuentas_banco(context, banco_moneda) {
      const params = new URLSearchParams();
      params.append("banco", banco_moneda.banco);
      params.append("moneda", banco_moneda.moneda);
      return Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/cuentasBancos`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.cuentas.length > 0) {
            return response.data.cuentas;

          } else {
            return [];
          }

        } else {
          return [];
        }

      }).catch(err => {
        console.log(err, banco_moneda);
        return [];
      });
    },

    async traer_cuentas_banco_v2(context, banco_moneda) {
      const params = new URLSearchParams();
      params.append("banco", banco_moneda.banco);
      params.append("moneda", banco_moneda.moneda);
      return Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/cuentasBancos`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.cuentas.length > 0) {
            // return response.data.cuentas;
            let tipos_cuentas = response.data.cuentas;
            let destinos_relacionados = [];
            let destinos_relacionados_array = Object.entries(context.state.configuracion.pais);

            for (let w = 0; w < destinos_relacionados_array.length; w++) {
              if (destinos_relacionados_array[w][0].split("_")[0] == banco_moneda.pais) {
                destinos_relacionados.push(destinos_relacionados_array[w][0]);
              }
            }

            if (tipos_cuentas.length > 0) {
              for (let z = 0; z < tipos_cuentas.length; z++) {
                const tipo = tipos_cuentas[z];

                for (let g = 0; g < destinos_relacionados.length; g++) {
                  const destin_repaso = destinos_relacionados[g];

                  context.state.configuracion.pais[destin_repaso].tipo_cuenta.push([tipo.id, tipo.tipos_cuentas_nombre, banco_moneda.moneda, banco_moneda.banco]);

                }
              }
            }

            return true;
          }
        } else {
          return false;
        }

      }).catch(err => {
        console.log(err, banco_moneda);
        return [];
      });
    },

    async traer_sucursales(context, banco_comuna) {

      const params = new URLSearchParams();
      params.append("banco", banco_comuna.banco);
      params.append("comuna", banco_comuna.comuna_caja);
      return Axios({
        method: 'POST',
        url: `${context.state.link_controlador}Auxiliares/sucursales`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error && response.data.sucursales.length > 0) {
            return response.data.sucursales;

          }
          else {
            // console.log(response.data, banco_comuna.banco, banco_comuna.comuna_caja);
            return [];
          }
        }

      }).catch(err => {
        console.log(err);
        return [];
      });
    },


    async tieneErroresNivel(context, data) {
      //{tipo_persona: aaa, id_persona: bbb, nivel: ccc}
      let tipo_persona = data.tipo_persona;
      let id_persona = data.id_persona;
      let nivel = data.nivel;

      let datos = await context.dispatch("calcularDatosFaltantes", {
        tipo_persona: tipo_persona,
        id_persona: id_persona,
        nivel: nivel,
        incluyedependientes: true,
      });

      if (context.state.configuracion.dest_menu === true) {
        return;
      }
      // console.log(datos);
      for (let i = 0; i < datos.length; i++) {
        if (datos[i][0] < 2) return true;
      }

      return false;
    },
    async tieneErroresNivelDetalle(context, data) {
      //{tipo_persona: aaa, id_persona: bbb, nivel: ccc}
      let tipo_persona = data.tipo_persona;
      let id_persona = data.id_persona;
      let nivel = data.nivel;

      //manejo_distaintas_formas_entrega
      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let datos = await context.dispatch("calcularDatosFaltantes", {
        tipo_persona: tipo_persona,
        id_persona: id_persona,
        nivel: nivel,
        incluyedependientes: true,
        edit_destinatario,
      });

      if (context.state.configuracion.dest_menu === true) {
        return;
      }
      // console.log(datos)
      let resp = false;
      for (let i = 0; i < datos.length; i++) {
        if (datos[i][0] < 2) return { resultado: true, detalle: datos };
      }

      return { resultado: resp, detalle: datos };
    },

    async calcularDatosFaltantes(context, data) {

      let tipo_persona = data.tipo_persona;
      let id_persona = data.id_persona;
      let nivel = data.nivel;
      let incluyedependientes = data.incluyedependientes;

      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let datosNecesarios = await context.dispatch("calcularDatosNecesarios", {
        tipo_persona: tipo_persona,
        nivel: nivel,
        incluye_dependientes: incluyedependientes,
        id_persona: id_persona,
      });

      let datosRespuesta = [];
      for (let i = 0; i < datosNecesarios.length; i++) {
        let datoBuscar = datosNecesarios[i][4];
        let validaciones = datosNecesarios[i][8];
        let objeto = {};


        if (tipo_persona == "Remitente")
          objeto =
            Object.keys(context.state.remitenteTemp).length > 0
              ? context.state.remitenteTemp
              : context.state.remitente;

        if (tipo_persona == "Destinatario") {
          if (id_persona == 0) {
            if (
              context.state.remitente.destinatarios !== null &&
              typeof context.state.remitente.destinatarios != "undefined"
            ) {
              if (
                typeof context.state.remitente.destinatarios[id_persona] !==
                "object"
              ) {
                context.state.remitente.destinatarios[id_persona] = {};
              }
            } else {
              context.state.remitente.destinatarios = {};
              context.state.remitente.destinatarios[id_persona] = {};
            }
          }

          if (edit_destinatario) {
            if (
              datoBuscar == "forma_entrega_id" || datoBuscar == "moneda_destino_id" ||
              datoBuscar == "tipo_cuenta" || datoBuscar == "bco_wallet" ||
              datoBuscar == "banco" ||
              datoBuscar == "id_banco_destino" || datoBuscar == "banco_caja" || datoBuscar == "region_caja" || datoBuscar == "comuna_caja" || datoBuscar == "sucursales" || datoBuscar == "moneda_caja" ||
              ((datoBuscar == "numero_cuenta" || datoBuscar == "numero_cuenta_wallet") && edit_destinatario.editFE)
            ) {

              objeto =
                context.state.remitente.destinatarios["0"]
                  .detalle_entrega["0"];
            } else {
              objeto = context.state.remitente.destinatarios[id_persona];
            }
          } else {
            objeto = context.state.remitente.destinatarios[id_persona];
          }
        }
        if (tipo_persona == "Transaccion") objeto = context.state.transaccion;

        if (validaciones.length == 0) {
          datosRespuesta.push([2, datoBuscar]);
        } else {
          // if (
          //   typeof objeto[datoBuscar] != "undefined" &&
          //   objeto[datoBuscar] != ""
          // ) {
          if (
            typeof objeto[datoBuscar] != "undefined"
          ) {
            let resultado = await context.dispatch("validarString", {
              string: objeto[datoBuscar],
              validaciones: validaciones,
            });
            if (resultado.length == 0) datosRespuesta.push([2, datoBuscar]);
            else datosRespuesta.push([1, datoBuscar, resultado]);
          } else {
            datosRespuesta.push([0, datoBuscar]);
          }
        }

      }
      // console.log(data, datosNecesarios, datosRespuesta);
      return datosRespuesta;
    },

    async calcularDatosNecesarios(context, data) {
      //{tipo_persona: aaa, nivel: bbb, incluye_dependientes: ccc}

      let tipo_persona = data.tipo_persona;
      let nivel = data.nivel;
      let incluye_dependientes = data.incluye_dependientes;

      //TODO ingreso variable id_persona para tema de destinatario con datos completos
      let id_persona = data.id_persona;

      let ObjetoDatos = [];
      let forma_entrega_id = context.state.transaccion["forma_entrega_id"];
      let pais_destinatario = "";
      let tipo_pago = context.state.transaccion["tipo_pago"];
      let residencia = "";
      if (tipo_persona == "Remitente" && context.state.remitenteTemp) {
        residencia = context.state.remitenteTemp["residencia"];
      }
      // Obtener un arreglo de elementos que cumplan con mis condiciones actuales de transaccion
      let paisid;

      let monto = 0;
      if (typeof context.state.transaccion.monto_origen !== "undefined") {
        monto = context.state.transaccion.monto_origen;
        // console.log(monto);
        monto = monto.replaceAll(".", "");
        // console.log(parseInt(monto));
      }
      let tpersona = 0;
      if (typeof context.state.remitente["tipo_persona"] != "undefined")
        tpersona = context.state.remitente["tipo_persona"];
      let reglas;

      switch (tipo_persona) {
        case "Remitente":
          reglas = context.state.configuracion.reglasFormularioRemitente;
          paisid = context.state.transaccion.pais_remitente;
          break;
        case "Destinatario":
          reglas = context.state.configuracion.reglasFormularioDestinatario;
          paisid = context.state.transaccion.pais_destino; // ojo, se debe buscar en destinatarios[id]
          //TODO para controlar validaciones en destinatario con datos completos
          if (id_persona != null) {

            // forma_entrega_id =
            //   context.state.remitente.destinatarios[id_persona].forma_entrega_id;
            if (!context.state.remitente.destinatarios[id_persona]) {
              context.state.remitente.destinatarios[id_persona] = {};
            }
            if (!context.state.remitente.destinatarios[id_persona].detalle_entrega) {
              context.state.remitente.destinatarios[id_persona].detalle_entrega = {};
            }
            // if (!context.state.remitente.destinatarios[id_persona].detalle_entrega["0"]) {
            //   context.state.remitente.destinatarios[id_persona].detalle_entrega["0"] = {};
            // }
            // forma_entrega_id =
            //   context.state.remitente.destinatarios[id_persona].detalle_entrega["0"].forma_entrega_id;
            if (!context.state.remitente.destinatarios["0"].detalle_entrega["0"]) {
              context.state.remitente.destinatarios["0"].detalle_entrega["0"] = {};
            }
            forma_entrega_id =
              context.state.remitente.destinatarios["0"].detalle_entrega["0"].forma_entrega_id;

            if (context.state.remitente.destinatarios["0"].pais_moneda_destino_id)
              pais_destinatario = context.state.remitente.destinatarios["0"].pais_moneda_destino_id.split("_")[0];
          }
          break;
        case "Transaccion":
          reglas = context.state.configuracion.reglasFormularioTransaccion;
          paisid = context.state.transaccion.pais_destino;

          break;
        // default:
        //   console.log(tipo_persona)
        //   break;
      }
      // console.log("!!!!!!!!!data", data, "reglas",reglas, "paisid",paisid, "monto",monto, "nivel", nivel, "incluye_dependientes", incluye_dependientes, "tpersona", tpersona, "forma_entrega_id", forma_entrega_id, "tipo_pago", tipo_pago, "residencia", residencia, "remitenteTemp", context.state.remitenteTemp);
      // console.log(context.state.transaccion.pais_destino);
      //let marca = 'esta marca';
      // console.log(tipo_persona, id_persona, forma_entrega_id, incluye_dependientes, tipo_pago, "transaccion: ", context.state.transaccion, "remitente: ", context.state.remitente, "reglas: ", reglas);
      for (let i = 0; i < reglas.length; i++) {
        // console.log(tipo_persona, "variable:", reglas[i][4], "forma_entrega_id:", forma_entrega_id, "reglas[i][14]: ", reglas[i][14], incluye_dependientes);
        // console.log(tipo_persona, reglas[i][14], residencia, paisid);
        //Si el pais corresponde
        if (reglas[i][0] == 0 || reglas[i][0] == paisid) {
          //Si el monto lo amerita
          if (reglas[i][2] <= monto) {
            //Si es el nivel que corresponde a la llamada
            if (reglas[i][3] == nivel || nivel == 0) {
              //Si no es dependendiente de otra (si lo es la invoca su padre)
              if (reglas[i][10] == 0 || incluye_dependientes)
                if (tpersona <= reglas[i][1]) {
                  if (tipo_persona == "Remitente") {

                    if (reglas[i][14] == 0 || reglas[i][14] == residencia)
                      ObjetoDatos.push(reglas[i]);
                  } else {
                    //Revisar el tipo de persona (si existe y coincide)
                    if (reglas[i][14] == 0 || reglas[i][14] == forma_entrega_id) {
                      if (reglas[i][13] == 0 || reglas[i][13] == tipo_pago) {
                        ObjetoDatos.push(reglas[i]);
                      }
                    } else if (reglas[i][4] == "cuil" && reglas[i][14] == pais_destinatario) {
                      ObjetoDatos.push(reglas[i]);
                    }
                    //Si es una transaccion debe verificar forma_entrega_id
                    // if (reglas[i][13] == 0 || reglas[i][13] == tipo_pago)
                    //   //Si es una transaccion debe verificar tipo_pago
                    //   ObjetoDatos.push(reglas[i]);


                    //Si es una transaccion debe verificar tipo_pago

                  }
                }


            }
          }
        }
      }
      // console.log("ObjetoDatos NECESARIOS:", ObjetoDatos, reglas, monto, nivel, tpersona);
      return ObjetoDatos;
    },


    // revisarCodigoRegis(context, data) {
    //   context.commit("setshowSpinner", true);
    //   context.commit("updateRespCodigoValidaMailRegis", {
    //     status: false,
    //     resp: false,
    //   });
    //   if (data == "1234") {
    //     setTimeout(() => {
    //       context.commit("setshowSpinner", false);
    //       context.commit("updateRespCodigoValidaMailRegis", {
    //         status: true,
    //         resp: true,
    //       });
    //     }, 1000);
    //   } else {
    //     setTimeout(() => {
    //       context.commit("setshowSpinner", false);
    //       context.commit("updateRespCodigoValidaMailRegis", {
    //         status: true,
    //         resp: false,
    //       });
    //     }, 500);
    //   }
    // },

    register(context, dataUser) {
      console.log("register: ", dataUser);

      // context.commit("set_user_temp",{
      //   rut:dataUser.usuario,
      //   correo:dataUser.correo
      // });
      context.state.remitente.numero_documento = dataUser.usuario;
      context.state.remitente.correo = dataUser.correo;
      context.state.remitente.validacion_mensual = { estado: true, maximo_clp: 5000000 };
      context.state.remitente.residencia = "152";

      context.commit("set_resp_pre_registro", {
        status: false,
        resp: false,
        msg: "",
      });


      const params = new URLSearchParams();
      params.append('numero_documento', dataUser.usuario);
      params.append('correo', dataUser.correo);
      params.append('clave', dataUser.contrasena);
      params.append('tipo_documento_id', "1");

      // context.state.remitente.destinatarios = {};

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/registrar`,
        data: params,
        withCredentials: false,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((resp) => {
        console.log("register", resp);
        if (!resp.data.error) {
          context.commit("updateSessionConfirm", 1);
          context.commit("set_resp_pre_registro", {
            status: true,
            resp: true,
            msg: "",
          });
        } else {
          context.commit("set_resp_pre_registro", {
            status: true,
            resp: false,
            msg: resp.data.codigo,
          });
        }

      })
        .catch(err => {
          console.log(err);
        });
    },
    validar_codigo_registro(context, codigo) {

      context.commit("set_resp_validar_codigo_registro", {
        status: false,
        resp: false,
        mge: ""
      });

      const params = new URLSearchParams();
      params.append('codigo', codigo);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/validaCodigo`,
        data: params,
        withCredentials: false,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((resp) => {
        // console.log(resp);
        const response = resp.data;

        if (response && response.cliente && response.token) {

          // context.dispatch("callFloidVerificaRut", context.state.remitente.numero_documento);
          // context.dispatch("traer_ocupaciones");
          // context.dispatch("traer_regiones", "152");

          context.commit("set_resp_validar_codigo_registro", {
            status: true,
            resp: true,
            msg: "",
          });

          let obj = {
            activo: response.cliente.activo,
            id: response.cliente.id,
            token: response.token.token,
            fecha_loin: response.token.fecha
          }
          context.commit("updateRemitente", obj);

        } else {
          context.commit("set_resp_validar_codigo_registro", {
            status: true,
            resp: false,
            msg: resp.data,
          });
        }

      })
        .catch(err => {
          console.log(err);
          context.commit("set_resp_validar_codigo_registro", {
            status: true,
            resp: false,
            msg: err,
          });
        });
    },

    async formatNumber(context, numerito) {
      if (numerito) {
        return await numerito
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
      // console.log(numerito);

    },



    async dibujarNodosNivel(context, data) {

      //{tipo_persona: aaa, id_persona: bbb, nivel: ddd}
      // console.log("2", data)
      let tipo_persona = data.tipo_persona;
      let id_persona = data.id_persona;

      //manejo_distaintas_formas_entrega
      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let nivel = data.nivel;

      // context.state.listaElementosDomTemp = [];
      context.commit("updateListaElementosDomTemp", []);
      // if (context.state.flujo.pasoActual == 6) {
      //     console.log("******Salida de dibujarNodosNivel");
      //     if (typeof context.state.transaccion.pais_destino == 'undefined') {
      //         await context.dispatch("df_continuar_VUE");
      //         return;
      //     }
      //     context.state.remitente.destinatarios[context.state.transaccion.destinatario].pais_moneda_destino_id = context.state.transaccion.pais_destino;
      // }

      // let datos = await context.dispatch("calcularDatosFaltantes", { tipo_persona: tipo_persona, id_persona: id_persona, nivel: nivel, incluyedependientes: false });

      //manejo_distaintas_formas_entrega
      let datos = await context.dispatch("calcularDatosFaltantes", {
        tipo_persona: tipo_persona,
        id_persona: id_persona,
        nivel: nivel,
        incluyedependientes: false,
        edit_destinatario,
      });

      let pais = await context.dispatch("paisDato", {
        tipo_persona: tipo_persona,
        id_persona: id_persona,
      });
      // console.log("--------", tipo_persona, id_persona, pais);
      let nombre = "";

      if (tipo_persona == "Remitente") {
        // TODO utilizar remitente temporal
        // nombre = context.state.remitente['nombre'];
        nombre = `${context.state.remitenteTemp["nombre1"]} ${context.state.remitenteTemp["nombre2"]}` ;
      }
      if (tipo_persona == "Destinatario") {
        nombre = `${context.state.remitente.destinatarios[id_persona]["nombres"]} ${context.state.remitente.destinatarios[id_persona]["apellido1"]} ${context.state.remitente.destinatarios[id_persona]["apellido2"]}`;
        // nombre = context.state.remitente.destinatarios[id_persona]['nombres'] + " " + context.state.remitente.destinatarios[id_persona]['apellidos'];
      }

      context.state.infoNodos = [];

      context.commit("updateListaElementosDom", []);
      let aux = [];
      for (let i = 0; i < datos.length; i++) {
        aux.push({
          elementoDom: "elemento",
          tipo_persona: tipo_persona,
          id_persona: id_persona,
          variable: datos[i][1],
          pais: pais,
          // textoTitulo:
          //   context.state.configuracion.data.textos_nivel_titulo[tipo_persona][
          //   nivel
          //   ],
          nivel: nivel,
          // textoCuerpo:
          //   context.state.configuracion.data.textos_nivel_cuerpo[tipo_persona][
          //   nivel
          //   ],
          nombre: nombre,
        });
      }
      // console.log(aux, datos);
      context.commit("updateInfoNodos", aux);
    },
    async paisDato(context, data) {
      //{tipo_persona: aaa, id_persona: bbb}
      let tipo_persona = data.tipo_persona;
      let id_persona = data.id_persona ? data.id_persona : null;

      let paisConsultar = null;
      // console.log(tipo_persona, id_persona, context.state.transaccion);
      switch (tipo_persona) {
        case "Remitente":
          paisConsultar = context.state.transaccion.pais_remitente;
          break;
        case "Destinatario":
          // console.log(context.state.remitente.destinatarios['0'], tipo_persona, id_persona, data.id_persona);
          if (id_persona)
            paisConsultar =
              context.state.remitente.destinatarios[id_persona].pais_moneda_destino_id;
          else {
            paisConsultar = context.state.transaccion.pais_destino;

            // TODO
            if (
              context.state.desde == "beneficiarioNuevo" ||
              context.state.desde == "miBeneficiarioDetalle"
            ) {
              paisConsultar =
                context.state.remitente.destinatarios[0].pais_moneda_destino_id;
            }
          }
          break;
        case "Transaccion":
          paisConsultar = context.state.transaccion.pais_destino;
          break;
      }
      // console.log("//////", id_persona, context.state.remitente);
      return paisConsultar;
    },

    async crearNodoInput(context, data) {
      //{elementoDom: aaa, tipo_persona: bbb, id_persona: ccc, variable: ddd, pais: eee}
      // let elementoDom = data.elementoDom;
      //  console.log(data);
      let tipo_persona = data.tipo_persona;
      let id_persona = data.id_persona;
      let variable = data.variable;
      let pais = data.pais;
      // console.log("//*//", variable, tipo_persona, id_persona, pais);
      //manejo_distaintas_formas_entrega
      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let datos =
        context.state.configuracion["reglasFormulario" + tipo_persona];
      let indice = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tipo_persona,
        variable: variable,
      });

      if (indice < 0) {
        console.log("error");
        return;
      }
      let nombre_elemento = tipo_persona + "-" + id_persona + "-" + variable;

      let strPlaceHolder = datos[indice][5];
      let valor = "";
      let isFilledTag = "";
      let validaciones = datos[indice][8];
      if (tipo_persona == "Remitente") {
        if (typeof context.state.remitenteTemp[variable] !== "undefined") {
          valor = context.state.remitenteTemp[variable];
          isFilledTag = "is-filled";
        }
      }
      if (variable == "ciudad") {
        // console.log(valor, context.state.remitenteTemp);
      }
      // console.log(variable, valor, context.state.remitenteTemp);
      if (tipo_persona == "Destinatario") {

        if (edit_destinatario) {
          if (
            variable == "forma_entrega_id" || variable == "moneda_destino_id" ||
            variable == "tipo_cuenta" || variable == "bco_wallet" ||
            variable == "banco" ||
            variable == "id_banco_destino" || variable == "banco_caja" || variable == "region_caja" || variable == "comuna_caja" || variable == "sucursales" || variable == "moneda_caja" ||
            ((variable == "numero_cuenta" || variable == "numero_cuenta_wallet") && edit_destinatario.editFE)
          ) {

            valor =
              context.state.remitente.destinatarios["0"].detalle_entrega["0"][variable];
          } else {
            valor = context.state.remitente.destinatarios[id_persona][variable];
          }
        } else {
          if (variable == "banco") {
            valor =
              context.state.remitente.destinatarios[id_persona][
              "id_banco_destino"
              ];
          } else {
            valor = context.state.remitente.destinatarios[id_persona][variable];
          }
        }

      }

      if (tipo_persona == "Transaccion") {
        if (typeof context.state.transaccion[variable] !== "undefined") {
          valor = context.state.transaccion[variable];
          isFilledTag = "is-filled";
        }
      }

      if (datos[indice][7] == "selectFecha") {
        let objetoElementoDom = {
          tipo: "selectFecha",
          conIcono: false,
          claseIcon: "",
        };
        let clasetienedependiente = "";
        if (datos[indice][9] != "") {
          clasetienedependiente = "tieneDependiente_" + datos[indice][9];
        } else {
          clasetienedependiente = "master";
        }

        objetoElementoDom.claseIcon = datos[indice][6];
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.strPlaceHolder = strPlaceHolder;
        objetoElementoDom.clasetienedependiente = clasetienedependiente;
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.tipo_persona = tipo_persona;
        objetoElementoDom.isFilledTag = isFilledTag;
        objetoElementoDom.variable = variable;
        objetoElementoDom.valor = valor;
        objetoElementoDom.tieneError = false;

        context.state.listaElementosDomTemp.push(objetoElementoDom);
      }

      if (datos[indice][7] == "selectPais") {
        let objetoElementoDom = {
          tipo: "selectPais",
          conIcono: false,
          claseIcon: "",
        };
        let clasetienedependiente = "";
        if (datos[indice][9] != "") {
          clasetienedependiente = "tieneDependiente_" + datos[indice][9];
        } else {
          clasetienedependiente = "master";
        }

        objetoElementoDom.claseIcon = datos[indice][6];
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.strPlaceHolder = strPlaceHolder;
        objetoElementoDom.clasetienedependiente = clasetienedependiente;
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.tipo_persona = tipo_persona;

        let aux = [];
        context.state.configuracion.data.auxiliares.pais_moneda_destino_id.forEach(
          (element) => {
            let auxiliar = { ...element };
            aux.push(auxiliar);
          }
        );
        let auxiliar = { elementos: aux };
        objetoElementoDom = { ...objetoElementoDom, ...auxiliar };

        objetoElementoDom.isFilledTag = isFilledTag;
        objetoElementoDom.variable = variable;

        objetoElementoDom.elementos.forEach((element) => {
          if (
            element.pais_val ==
            context.state.remitente.destinatarios[id_persona].pais_moneda_destino_id
          ) {
            element.selectTxt = "selected";
          } else {
            element.selectTxt = "";
          }
        });

        objetoElementoDom.tieneError = false;

        context.state.listaElementosDomTemp.push(objetoElementoDom);
      }

      if (datos[indice][7] == "select") {
        let clasetienedependiente = "";

        let objetoElementoDom = {
          tipo: "select",
          conIcono: false,
          claseIcon: "",
        };

        if (datos[indice][6] != "") {
          objetoElementoDom.conIcono = true;
        }

        if (datos[indice][9] != "") {
          clasetienedependiente = "tieneDependiente_" + datos[indice][9];
        } else {
          clasetienedependiente = "master";
        }

        if (tipo_persona == "Destinatario") objetoElementoDom.pais = pais;

        objetoElementoDom.claseIcon = datos[indice][6];
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.strPlaceHolder = strPlaceHolder;
        objetoElementoDom.clasetienedependiente = clasetienedependiente;
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.tipo_persona = tipo_persona;

        objetoElementoDom = {
          ...objetoElementoDom,
          ...(await context.dispatch("poblarOpcionesElemento", {
            tipo_persona: tipo_persona,
            id_depersona: id_persona,
            pais: pais,
            variable: variable,
            edit_destinatario,
          })),
        };

        objetoElementoDom.isFilledTag = isFilledTag;
        objetoElementoDom.variable = variable;

        objetoElementoDom.tieneError = false;

        context.state.listaElementosDomTemp.push(objetoElementoDom);
      }

      if (datos[indice][7] == "selectDir") {
        let aux = await context.dispatch("poblarOpcionesElementoDir", {
          tipo_persona: tipo_persona,
          variable: variable,
          valor: valor,
        });


        if (datos[indice][6] != "") {
          aux.iconoMostrar = true;
          aux.iconoData = datos[indice][6];
        } else {
          aux.iconoMostrar = false;
        }
        aux.isFilledTag = isFilledTag;
        aux.variable = variable;
        aux.tipo = "selectDir";
        aux.elementos[0].tieneError = false;
        context.state.listaElementosDomTemp.push(aux);
      }

      if (
        datos[indice][7] == "text" ||
        datos[indice][7] == "tel" ||
        datos[indice][7] == "number"
      ) {
        let aux = await context.dispatch("crearInputText", {
          identificador: `ipt-${nombre_elemento}`,
          tipo: datos[indice][7],
          icono_principal: datos[indice][6],
          etiqueta: strPlaceHolder,
          texto_ayuda: datos[indice][12],
          valor: valor,
          tipo_persona: tipo_persona,
          validaciones: validaciones,
          variable: variable,
        });

        aux.tieneError = false;
        // console.log(variable, validaciones, aux);
        if (validaciones.includes("notempty")) {
          aux.obligatorio = true;
        } else {
          aux.obligatorio = false;
        }
        context.state.listaElementosDomTemp.push(aux);
      }

      if (
        datos[indice][7] == "text_hijo" ||
        datos[indice][7] == "tel_hijo" ||
        datos[indice][7] == "number_hijo"
      ) {
        let aux = await context.dispatch("crearInputText_hijo", {
          identificador: `ipt-${nombre_elemento}`,
          tipo: datos[indice][7],
          icono_principal: datos[indice][6],
          etiqueta: strPlaceHolder,
          texto_ayuda: datos[indice][12],
          valor: valor,
          tipo_persona: tipo_persona,
          validaciones: validaciones,
          variable: variable,
          id_persona: data.id_persona,
          edit_destinatario,
        });
        aux.tieneError = false;
        context.state.listaElementosDomTemp.push(aux);

      }

      if (datos[indice][7] == "buttons") {
        let aux = await context.dispatch("crearInputButtons", {
          pais: pais,
          nombreVariable: variable,
          nombre_elemento: nombre_elemento,
          texto_ayuda: datos[indice][12],
          valor: valor,
        });

        aux.forEach((element) => {
          context.state.listaElementosDomTemp.push(element);
        });
      }

      if (datos[indice][7] == "checkBox") {
        let objetoElementoDom = {
          tipo: "checkBox",
          conIcono: false,
          claseIcon: datos[indice][6],
        };
        let clasetienedependiente = "";
        if (datos[indice][9] != "") {
          clasetienedependiente = "tieneDependiente_" + datos[indice][9];
        } else {
          clasetienedependiente = "master";
        }

        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.strPlaceHolder = strPlaceHolder;
        objetoElementoDom.clasetienedependiente = clasetienedependiente;
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.tipo_persona = tipo_persona;
        objetoElementoDom.isFilledTag = isFilledTag;
        objetoElementoDom.variable = variable;
        objetoElementoDom.valor = valor;
        objetoElementoDom.tieneError = false;
        objetoElementoDom.validaciones = validaciones;

        context.state.listaElementosDomTemp.push(objetoElementoDom);
      }

      if (datos[indice][7] == "radioBtns") {
        let objetoElementoDom = {
          tipo: "radioBtns",
          conIcono: false,
          claseIcon: datos[indice][6],
        };
        let clasetienedependiente = "";
        if (datos[indice][9] != "") {
          clasetienedependiente = "tieneDependiente_" + datos[indice][9];
        } else {
          clasetienedependiente = "master";
        }

        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.strPlaceHolder = strPlaceHolder;
        objetoElementoDom.clasetienedependiente = clasetienedependiente;
        objetoElementoDom.nombre_elemento = nombre_elemento;
        objetoElementoDom.tipo_persona = tipo_persona;
        objetoElementoDom.isFilledTag = isFilledTag;
        objetoElementoDom.variable = variable;
        objetoElementoDom.valor = valor;
        objetoElementoDom.tieneError = false;
        objetoElementoDom.validaciones = validaciones;

        context.state.listaElementosDomTemp.push(objetoElementoDom);
      }

      // Iterar Recursivamente si tengo un elemento que depende de mi.
      if (datos[indice][9] != "") {
        let dependiente_simple = [];
        if (typeof datos[indice][9] == "string") {
          dependiente_simple.push(datos[indice][9]);
        } else {
          datos[indice][9].forEach(dependiente => {
            dependiente_simple.push(dependiente);
          });
        }
        // datos[indice][9].split(",");
        dependiente_simple.forEach(dependiente => {
          context.dispatch("crearNodoInput", {
            tipo_persona: tipo_persona,
            id_persona: id_persona,
            variable: dependiente,
            pais: pais,
            edit_destinatario,
          });
        });




      }
      // else{
      //     context.commit("updateListaElementosDom", context.state.listaElementosDomTemp);
      // }
      // console.log(data, context.state.listaElementosDomTemp)
    },
    async buscarReglaConfiguracion(context, data) {
      //{tipo_persona: aaa, variable: bbb, lugar: ccc}
      let tipo_persona = data.tipo_persona;
      let variable = data.variable;
      let lugar = data.lugar;
      // console.log(data)
      let indiceBuscar = 4;
      if (typeof lugar != "undefined" && lugar == "dependiente")
        indiceBuscar = 9;

      let datos =
        context.state.configuracion["reglasFormulario" + tipo_persona];

      /* Recorrer el arreglo buscando variable*/
      for (let i = 0; i < datos.length; i++) {
        if (typeof datos[i][indiceBuscar] == "string") {
          // console.log("string");
          if (datos[i][indiceBuscar] == variable) {
            return i;
          }
        } else {

          let index = datos[i][indiceBuscar].findIndex(item => {
            return item == variable;
          });
          if (index > -1) {
            // console.log("No string", i);
            return i;
          }
        }

      }
      return -1;
    },
    async crearInputText(context, data) {
      //{identificador: aaa, tipo: bbb, icono_principal: ccc, etiqueta: ddd, texto_ayuda: eee, valor: fff, tipo_persona: ggg, validaciones: hhh}
      let identificador = data.identificador;
      let tipo = data.tipo;
      let icono_principal = data.icono_principal;
      let etiqueta = data.etiqueta;
      let texto_ayuda = data.texto_ayuda;
      let valor = data.valor;
      let tipo_persona = data.tipo_persona;
      let validaciones = data.validaciones;
      let variable = data.variable ? data.variable : "";
      // console.log("variable", variable, "valor", valor);
      let objInput = {};

      let strClase = "";
      let ocultar = "";
      if (valor == null) {
        valor = "";
      }

      if (valor.length > 0) {
        strClase = "is-filled";
      }

      let errores = await context.dispatch("validarString", {
        string: valor,
        validaciones: validaciones,
      });

      if (errores.length > 0) {
        strClase += " has-danger";
      }

      if (etiqueta === "RUT" && valor != "") {
        ocultar = "disabled";
      }

      objInput = {
        icono_principal: icono_principal,
        strClase: strClase,
        identificador: identificador,
        etiqueta: etiqueta,
        ocultar: ocultar,
        tipo_persona: tipo_persona,
        tipo: tipo,
        valor: valor,
        texto_ayuda: texto_ayuda,
        variable: variable,
      };
      return objInput;
    },

    async crearInputText_hijo(context, data) {
      //{identificador: aaa, tipo: bbb, icono_principal: ccc, etiqueta: ddd, texto_ayuda: eee, valor: fff, tipo_persona: ggg, validaciones: hhh, id_persona: iii}
      let identificador = data.identificador;
      let tipo = data.tipo;
      let icono_principal = data.icono_principal;
      let etiqueta = data.etiqueta;
      let texto_ayuda = data.texto_ayuda;
      let valor = data.valor;
      let tipo_persona = data.tipo_persona;
      let validaciones = data.validaciones;
      let variable = data.variable ? data.variable : "";
      let id_persona = data.id_persona;
      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let objInput = {};

      let strClase = "";
      let ocultar = "";
      if (valor == null) {
        valor = "";
      }

      if (valor.length > 0) {
        strClase = "is-filled";
      }

      let errores = await context.dispatch("validarString", {
        string: valor,
        validaciones: validaciones,
      });

      if (errores.length > 0) {
        strClase += " has-danger";
      }

      if (etiqueta === "RUT" && valor != "") {
        ocultar = "disabled";
      }

      let padre_ok = false;
      let valorPadre = "";
      let variablePadre;
      let errores_padre = [];
      let indice_padre = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tipo_persona,
        variable: variable,
        lugar: "dependiente",
      });
      if (indice_padre > -1) {
        variablePadre =
          context.state.configuracion["reglasFormulario" + tipo_persona][
          indice_padre
          ][4];
        valorPadre = "";
        let objPadre = {};
        if (tipo_persona == "Remitente") {
          objPadre = context.state.remitenteTemp;
        } else if (tipo_persona == "Destinatario") {

          if (edit_destinatario) {
            if (
              variable == "forma_entrega_id" || variable == "moneda_destino_id" ||
              variable == "tipo_cuenta" || variable == "bco_wallet" ||
              variable == "banco" ||
              variable == "id_banco_destino" || variable == "banco_caja" || variable == "region_caja" || variable == "comuna_caja" || variable == "sucursales" || variable == "moneda_caja" ||
              ((variable == "numero_cuenta" || variable == "numero_cuenta_wallet") && edit_destinatario.editFE)
            ) {

              objPadre =
                context.state.remitente.destinatarios["0"].detalle_entrega["0"];
            } else {
              objPadre = context.state.remitente.destinatarios["0"];
            }
          } else {
            objPadre = context.state.remitente.destinatarios[id_persona];
          }
        } else if (tipo_persona == "Transaccion") {
          objPadre = context.state.transaccion;
        }
        if (typeof objPadre[variablePadre] != "undefined")
          valorPadre = objPadre[variablePadre];

        // console.log("-->", tipo_persona, "edit_destinatario", edit_destinatario,"variablePadre", variablePadre, "objPadre", objPadre, "valorPadre", valorPadre);

        // if (variable == "numero_cuenta_wallet") {
        //   let indice_propio = await context.dispatch("buscarReglaConfiguracion", {
        //     tipo_persona: tipo_persona,
        //     variable: variable,
        //   });
        //   let gatillador = context.state.configuracion["reglasFormulario" + tipo_persona][indice_propio][14];
        //   console.log(variable, valor, variablePadre, valorPadre, indice_propio, gatillador, gatillador != "", gatillador != valorPadre);
        //   if (gatillador != "" && gatillador != valorPadre) {
        //     errores_padre = [""];
        //   }
        // } else {
        errores_padre = await context.dispatch("validarString", {
          string: valorPadre,
          validaciones:
            context.state.configuracion["reglasFormulario" + tipo_persona][
            indice_padre
            ][8],
        });
        // }

        // console.log(errores_padre, { string: valorPadre, validaciones: context.state.configuracion["reglasFormulario" + tipo_persona][indice_padre][8] }, objPadre);
        // console.log(variable, errores_padre, valorPadre, variablePadre, objPadre, indice_padre, context.state.remitente.destinatarios["0"], "id_persona", id_persona);
        if (errores_padre.length == 0) {
          padre_ok = true;
        }

        // let indice_propio = await context.dispatch("buscarReglaConfiguracion", {
        //   tipo_persona: tipo_persona,
        //   variable: variable,
        // });
        // let gatillador = context.state.configuracion["reglasFormulario" + tipo_persona][indice_propio][14];
        // console.log(variable, valor, variablePadre, valorPadre, indice_propio, gatillador, gatillador != "", gatillador != valorPadre);
        // if (gatillador != "" && gatillador != valorPadre) {
        //   return "";
        // }
        // console.log("crearInputText_hijo *", variable, padre_ok, "variablePadre", variablePadre, "valorPadre", valorPadre, "indice_padre", indice_padre);
      }

      objInput = {
        icono_principal: icono_principal,
        strClase: strClase,
        identificador: identificador,
        etiqueta: etiqueta,
        ocultar: ocultar,
        tipo_persona: tipo_persona,
        tipo: tipo,
        valor: valor,
        texto_ayuda: texto_ayuda,
        variable: variable,
        padre_ok: padre_ok,
      };


      return objInput;
    },

    async cambioElementoVue(context, data) {

      let elemento = data.elemento;
      let valor = data.valor;
      let accion = data.accion;
      let tipo_persona = data.tipo_persona;
      let clasetienedependiente = data.clasetienedependiente;
      let borrarValorHijo = data.borrarValorHijo ? data.borrarValorHijo : false;

      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let objetoDataNueva = {};
      if (!context.state.remitente.destinatarios) context.state.remitente.destinatarios = {};
      if (!context.state.remitente.destinatarios["0"]) context.state.remitente.destinatarios["0"] = {};
      if (!context.state.remitente.destinatarios["0"].detalle_entrega) {
        context.state.remitente.destinatarios["0"].detalle_entrega = {};
        context.state.remitente.destinatarios["0"].detalle_entrega["0"] = {};
      } else if (!context.state.remitente.destinatarios["0"].detalle_entrega["0"]) {
        context.state.remitente.destinatarios["0"].detalle_entrega["0"] = {};
      }

      if (context.state.transaccion.pais_destino == "604") {
        context.state.configuracion.reglasFormularioTransaccion[3][8][1] =
          "charmin-20";
        context.state.configuracion.reglasFormularioTransaccion[3][8][2] =
          "charmax-20";
      } else if (context.state.transaccion.pais_destino == "900") {
        context.state.configuracion.reglasFormularioTransaccion[3][8].splice(
          2,
          1
        );
        context.state.configuracion.reglasFormularioTransaccion[3][8][1] =
          "charmin-22";
        context.state.configuracion.reglasFormularioTransaccion[3][5] =
          "Numero de cuenta CBU/Alias";
      } else if (context.state.transaccion.pais_destino == "332") {
        context.state.configuracion.reglasFormularioDestinatario[3][8][2] =
          "charmax-8";
      } else {
        context.state.configuracion.reglasFormularioTransaccion[3][8][1] =
          "charmin-6";
      }

      if (valor != null && valor.toString().length > 0) {
        objetoDataNueva.isFilledTag = "is-filled";
      } else {
        objetoDataNueva.isFilledTag = "";
      }

      let eid = elemento;
      let partes_nombre = eid.split("-");
      let nombreVariable = partes_nombre[3];
      let tp = partes_nombre[1];
      let idp = partes_nombre[2];
      //  console.log(nombreVariable, idp, accion, tipo_persona, borrarValorHijo);   
      if (partes_nombre[0] == "ipt") {

        if (tp == "Remitente")
          context.state.remitenteTemp[nombreVariable] = valor;

        if (tp == "Destinatario") {

          if (edit_destinatario) {

            if (
              nombreVariable == "forma_entrega_id" || nombreVariable == "moneda_destino_id" ||
              nombreVariable == "tipo_cuenta" || nombreVariable == "bco_wallet" ||
              nombreVariable == "banco" ||
              nombreVariable == "id_banco_destino" || nombreVariable == "banco_caja" || nombreVariable == "region_caja" || nombreVariable == "comuna_caja" || nombreVariable == "sucursales" || nombreVariable == "moneda_caja" ||
              ((nombreVariable == "numero_cuenta" || nombreVariable == "numero_cuenta_wallet") && edit_destinatario.editFE)
            ) {

              context.state.remitente.destinatarios["0"].detalle_entrega["0"][nombreVariable] = valor;
              if (nombreVariable == "banco")
                context.state.remitente.destinatarios["0"].detalle_entrega["0"]["id_banco_destino"] = valor;

            } else {

              context.state.remitente.destinatarios["0"][nombreVariable] =
                valor;

            }
          } else {

            context.state.remitente.destinatarios["0"][nombreVariable] = valor;
            if (nombreVariable == "banco")
              context.state.remitente.destinatarios["0"]["id_banco_destino"] =
                valor;
          }
        }
        if (tp == "Transaccion")
          context.state.transaccion[nombreVariable] = valor;
      }
      if (partes_nombre[0] == "slct") {

        if (tp == "Remitente")
          context.state.remitenteTemp[nombreVariable] = valor;

        if (tp == "Destinatario") {

          if (edit_destinatario) {

            if (
              nombreVariable == "forma_entrega_id" || nombreVariable == "moneda_destino_id" ||
              nombreVariable == "tipo_cuenta" || nombreVariable == "bco_wallet" ||
              nombreVariable == "banco" ||
              nombreVariable == "id_banco_destino" || nombreVariable == "banco_caja" || nombreVariable == "region_caja" || nombreVariable == "comuna_caja" || nombreVariable == "sucursales" || nombreVariable == "moneda_caja" ||
              ((nombreVariable == "numero_cuenta" || nombreVariable == "numero_cuenta_wallet") && edit_destinatario.editFE)
            ) {

              context.state.remitente.destinatarios["0"].detalle_entrega["0"][nombreVariable] = valor;
              if (nombreVariable == "banco")
                context.state.remitente.destinatarios["0"].detalle_entrega["0"]["id_banco_destino"] = valor;

            } else {

              context.state.remitente.destinatarios["0"][nombreVariable] =
                valor;

            }
          } else {

            context.state.remitente.destinatarios["0"][nombreVariable] = valor;
            if (nombreVariable == "banco")
              context.state.remitente.destinatarios["0"]["id_banco_destino"] =
                valor;
          }
        }
        if (tp == "Transaccion")
          context.state.transaccion[nombreVariable] = valor;
      }

      let indiceVariable = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tp,
        variable: nombreVariable,
      });

      let validaciones =
        context.state.configuracion["reglasFormulario" + tp][indiceVariable][8];

      let errores = await context.dispatch("validarString", {
        string: valor,
        validaciones: validaciones,
      });

      objetoDataNueva.tieneError = false;
      if (errores.length > 0) {
        objetoDataNueva.tieneError = true;
        objetoDataNueva.errores = [...errores];
      }

      let nombreDependiente = "";

      let regla;

      if (clasetienedependiente.length > 0) {
        nombreDependiente = clasetienedependiente.substring(17);

        let tipoComponenteDelDependiente = "";
        regla = context.state.configuracion[`reglasFormulario${tp}`].find(
          (entrada) => entrada[4] == nombreDependiente
        );

        if (regla) {
          tipoComponenteDelDependiente = regla[7];
          if (
            tipoComponenteDelDependiente == "text" ||
            tipoComponenteDelDependiente == "number" ||
            tipoComponenteDelDependiente == "tel"
          ) {
            nombreDependiente = "";
          }
        }
      }

      if (nombreDependiente != "") {

        let indice_elemento = context.state.configuracion[
          "reglasFormulario" + tipo_persona
        ].findIndex((elemento) => {
          return elemento[4] == nombreDependiente;
        });

        let tipo_elemento =
          context.state.configuracion["reglasFormulario" + tipo_persona][
          indice_elemento
          ][7];

        let reglas = await context.dispatch("calcularDatosNecesarios", {
          tipo_persona: tipo_persona,
          nivel: regla[3],
          incluye_dependientes: true,
          id_persona: idp,
        });
        let variable_necesaria = reglas.findIndex(regla => { return regla[4] == nombreDependiente });
        // console.log("@@@@@@@@@@", nombreDependiente, tipo_elemento, reglas, variable_necesaria);
        if (
          tipo_elemento == "text_hijo" ||
          tipo_elemento == "tel_hijo" ||
          tipo_elemento == "number_hijo"
        ) {
          if (variable_necesaria > -1) {

            // console.log(reglas, nombreDependiente, variable_necesaria);


            objetoDataNueva.dataNuevaPasada = await context.dispatch(
              "crearInputText_hijo",
              {
                identificador: `ipt-${tipo_persona}-${idp}-${nombreDependiente}`,
                tipo: tipo_elemento,
                icono_principal:
                  context.state.configuracion["reglasFormulario" + tipo_persona][
                  indice_elemento
                  ][6],
                etiqueta:
                  context.state.configuracion["reglasFormulario" + tipo_persona][
                  indice_elemento
                  ][5],
                texto_ayuda:
                  context.state.configuracion["reglasFormulario" + tipo_persona][
                  indice_elemento
                  ][12],
                valor: "",
                tipo_persona: tipo_persona,
                validaciones:
                  context.state.configuracion["reglasFormulario" + tipo_persona][
                  indice_elemento
                  ][8],
                variable: nombreDependiente,
                id_persona: idp,
                edit_destinatario,
              }
            );
            objetoDataNueva.dataNuevaPasada.tieneError = false;
            objetoDataNueva.cargarDataAlElementoDom = false;
            objetoDataNueva.tipo_elemento = "input";
            // console.log(objetoDataNueva);
          }
          else {
            let index = context.state.listaElementosDom.findIndex(
              (componente) => componente.variable == nombreDependiente
            );
            if (index > -1) {
              // console.log(context.state.listaElementosDom[index]);
              context.state.listaElementosDom[index].padre_ok = false;
              // setTimeout(() => {
              //   context.state.listaElementosDom.splice(index, 1);
              // }, 100);
            }

            if ((tipo_persona == "Destinatario" || tipo_persona == "Remitente") && borrarValorHijo) {
              await context.dispatch("buscarSubDepediente", {
                tipo_persona: tipo_persona,
                variable: nombreDependiente,
                id_depersona: idp,
                edit_destinatario: edit_destinatario
              });
            }

          }

        } else {


          if (variable_necesaria > -1) {
            let paisConsultar = await context.dispatch("paisDato", {
              tipo_persona: tipo_persona,
              id_persona: idp,
            });

            let opciones = await context.dispatch("poblarOpcionesElemento", {
              tipo_persona: tp,
              id_depersona: idp,
              pais: paisConsultar,
              variable: nombreDependiente,
              borrarValorHijo: borrarValorHijo,
              edit_destinatario,
            });
            objetoDataNueva.elementos = opciones;
            objetoDataNueva.dataNuevaPasada = {
              elemento: `slct-${tp}-${idp}-${nombreDependiente}`,
              valor: "",
              accion: accion,
              tipo_persona: tipo_persona,
              clasetienedependiente: "",
            };
            objetoDataNueva.cargarDataAlElementoDom = true;
            // console.log(nombreDependiente, opciones);
          } else {
            // console.log("borrar", nombreDependiente);
            let index = context.state.listaElementosDom.findIndex(
              (componente) => componente.variable == nombreDependiente
            );
            if (index > -1) {
              if (context.state.listaElementosDom[index].elementos) {
                context.state.listaElementosDom[index].elementos = [];
              }
            }

            if ((tipo_persona == "Destinatario" || tipo_persona == "Remitente") && borrarValorHijo) {
              await context.dispatch("buscarSubDepediente", {
                tipo_persona: tipo_persona,
                variable: nombreDependiente,
                id_depersona: idp,
                edit_destinatario: edit_destinatario
              });
            }
          }
        }
      }

      return objetoDataNueva;
    },

    async cambioElementoCheckBox(context, data) {
      //{value: aaa, valizaciones: bbb, tipoPersona: ccc}
      let valor = data.valor;
      let validaciones = data.validaciones;
      let tipo_persona = data.tipo_persona;
      let variable = data.variable;

      let objetoDataNueva = {};
      let errores = await context.dispatch("validarString", {
        string: valor,
        validaciones: validaciones,
      });
      objetoDataNueva.tieneError = false;
      if (errores.length > 0) {
        objetoDataNueva.tieneError = true;
        // objetoDataNueva.errores = [...errores];
      }

      if (tipo_persona == "Remitente") {
        context.state.remitenteTemp[variable] = valor;
      }
      // console.log(context.state.remitenteTemp);
      return objetoDataNueva;
    },

    async cambioElementoRadioBtns(context, data) {
      //{value: aaa, valizaciones: bbb, tipoPersona: ccc}
      let valor = data.valor;
      let validaciones = data.validaciones;
      let tipo_persona = data.tipo_persona;
      let variable = data.variable;

      let objetoDataNueva = {};
      let errores = await context.dispatch("validarString", {
        string: valor,
        validaciones: validaciones,
      });
      objetoDataNueva.tieneError = false;
      if (errores.length > 0) {
        objetoDataNueva.tieneError = true;
        // objetoDataNueva.errores = [...errores];
      }

      if (tipo_persona == "Remitente") {
        context.state.remitenteTemp[variable] = valor;
      }
      // console.log(context.state.remitenteTemp, data);
      return objetoDataNueva;
    },

    async poblarOpcionesElementoDir(context, data) {

      let tipo_persona = data.tipo_persona;
      let variable = data.variable;
      let valor = data.valor;
      let objElementos = {};
      let strClase = "";
      let tieneError = false;
      if (typeof valor == "string" || typeof valor == "number") {
        valor = parseInt(valor);
      } else if (valor !== null) {
        valor = parseInt(valor.value);
      } else {
        valor = 0;
      }

      let validaciones = await context.dispatch("obtenerValidaciones", {
        tipo_persona: tipo_persona,
        nombreVariable: variable,
      });

      let errores = await context.dispatch("validarString", {
        string: valor,
        validaciones: validaciones,
      });

      if (errores.length > 0) {
        strClase += "has-danger";
        tieneError = true;
      }
      objElementos.strClase = strClase;
      objElementos.tieneError = tieneError;
      let controles = context.state.configuracion.data.auxiliares[variable];
      let ruta = await context.dispatch("buscarDatoId", {
        variable: variable,
        id: valor,
      });

      let rindice = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tipo_persona,
        variable: variable,
      });

      let strPlaceHolder =
        context.state.configuracion["reglasFormulario" + tipo_persona][
        rindice
        ][5];

      if (ruta[ruta.length - 1] != 0) {
        if (tipo_persona == "Remitente") {

          context.state.remitenteTemp[variable] = valor;

        }
        // if (tipo_persona == "Destinatario") {

        // }
        if (tipo_persona == "Transaccion") {
          if (typeof context.state.transaccion[variable] !== "undefined") {
            context.state.transaccion[variable] = valor;

          }
        }
      }

      objElementos.elementos = [];

      for (let c = 0; c < controles.length; c++) {
        let opciones = controles[c];

        let objetoElementos = {};

        let tieneElementos = false;
        if (c > 0) {
          for (let i = 0; i < opciones.length; i++) {
            let indicepadre = opciones[i][2];
            if (indicepadre == ruta[c - 1]) {
              tieneElementos = true;
              break;
            }
          }
        }

        if (tieneElementos || c == 0) {
          let nombre_elemento = tipo_persona + "-" + "el" + "-" + variable;
          let selectTxt = ruta[c] === 0 ? "selected" : "";

          objetoElementos.nombre_elemento = nombre_elemento;
          objetoElementos.selectTxt = selectTxt;
          objetoElementos.strPlaceHolder = strPlaceHolder;
          objetoElementos.tipo_persona = tipo_persona;
          objetoElementos.variable = variable;
          objetoElementos.id = `slct-${nombre_elemento}${c}`;
          objetoElementos.opciones = [];
          objetoElementos.strClase = strClase;
          objetoElementos.tieneError = tieneError;

          let objOpciones = [];
          for (let i = 0; i < opciones.length; i++) {
            let indicepadre = opciones[i][2];
            let indice = opciones[i][0];
            if (indice == ruta[c]) {
              selectTxt = "selected";
            } else {
              selectTxt = "";
            }
            if (c == 0 || indicepadre == ruta[c - 1]) {
              objOpciones.push({
                selectTxt: selectTxt,
                indice: indice,
                text: opciones[i][1],
              });
            }
          }
          objetoElementos.opciones = objOpciones;
        }

        objElementos.elementos.push(objetoElementos);
      }
      // console.log("+++!!!+++", variable, ruta, context.state.remitenteTemp, objElementos);
      // console.log(objElementos);
      return objElementos;
    },

    async poblarOpcionesElemento(context, data) {

      let tipo_persona = data.tipo_persona;
      let id_depersona = data.id_depersona;
      let pais = data.pais;
      let variable = data.variable;
      let borrarValorHijo = data.borrarValorHijo ? data.borrarValorHijo : false;

      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let objetoElementoDom = {};

      let indice = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tipo_persona,
        variable: variable,
      });

      let indicePadre = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tipo_persona,
        variable: variable,
        lugar: "dependiente",
      });

      let variablePadre = "";
      if (indicePadre != -1) {
        variablePadre =
          context.state.configuracion["reglasFormulario" + tipo_persona][
          indicePadre
          ][4];
      }

      let strPlaceHolder =
        context.state.configuracion["reglasFormulario" + tipo_persona][
        indice
        ][5];
      let valor = "";

      let valorPadre = -1;
      let objPadre = {};
      switch (tipo_persona) {
        case "Remitente":
          objPadre = context.state.remitenteTemp;
          break;
        case "Destinatario":

          if (edit_destinatario) {
            if (
              variable == "forma_entrega_id" || variable == "moneda_destino_id" ||
              variable == "tipo_cuenta" || variable == "bco_wallet" ||
              variable == "banco" ||
              variable == "id_banco_destino" || variable == "banco_caja" || variable == "region_caja" || variable == "comuna_caja" || variable == "sucursales" || variable == "moneda_caja" ||
              ((variable == "numero_cuenta" || variable == "numero_cuenta_wallet") && edit_destinatario.editFE)
            ) {

              objPadre =
                context.state.remitente.destinatarios["0"].detalle_entrega["0"];
            } else {
              objPadre = context.state.remitente.destinatarios["0"];
            }
          } else {
            objPadre = context.state.remitente.destinatarios[id_depersona];
          }

          break;
        case "Transaccion":
          objPadre = context.state.transaccion;
          break;
      }

      let listaOpciones = [];
      if (pais) {
        if (variable == "region_caja" && context.state.remitente.destinatarios["0"] && context.state.remitente.destinatarios["0"].detalle_entrega["0"] && context.state.remitente.destinatarios["0"].detalle_entrega["0"].moneda_caja) {

          listaOpciones = context.state.configuracion.pais[`${pais.split("_")[0]}_${context.state.remitente.destinatarios["0"].detalle_entrega["0"].moneda_caja}`][variable];
        } else {
          listaOpciones = context.state.configuracion.pais[pais][variable];
        }
      }


      if (indicePadre >= 0) {
        if (variablePadre == "banco") {
          variablePadre = "id_banco_destino";
        }
        if (typeof objPadre[variablePadre] != "undefined") {
          valorPadre = objPadre[variablePadre];
        }
      }
      if (indicePadre >= 0) {
        if (variablePadre == "bco_wallet") {
          variablePadre = "bco_wallet";
        }

        if (typeof objPadre[variablePadre] != "undefined") {
          valorPadre = objPadre[variablePadre];
        }
      }

      if (variable == "banco") {
        if (typeof objPadre["id_banco_destino"] !== "undefined")
          valor = objPadre["id_banco_destino"];
      } else if (variable == "bco_wallet") {
        if (typeof objPadre["bco_wallet"] !== "undefined")
          valor = objPadre["bco_wallet"];
      }

      else {
        valor = objPadre[variable];
      }

      objetoElementoDom.strPlaceHolderOp = strPlaceHolder;

      objetoElementoDom.elementos = [];

      if (tipo_persona == "Destinatario" && borrarValorHijo) {
        if (edit_destinatario && edit_destinatario.editFE) {
          context.state.remitente.destinatarios["0"][variable] =
            variable == "forma_entrega_id"
              ? context.state.remitente.destinatarios["0"][variable]
              : "";
        } else {
          context.state.remitente.destinatarios[id_depersona][variable] =
            variable == "forma_entrega_id"
              ? context.state.remitente.destinatarios[id_depersona][variable]
              : "";
        }

        let index = context.state.listaElementosDom.findIndex(
          (componente) => componente.variable == variable
        );
        if (index > -1) {
          if (context.state.listaElementosDom[index].elementos) {
            context.state.listaElementosDom[index].elementos = [];
          }
        }
      }

      // console.log(`${variable}(${tipo_persona})`, "objPadre", objPadre, "indicePadre", indicePadre, "valorPadre", valorPadre, "valor", valor, borrarValorHijo, "listaOpciones", listaOpciones, "pais", pais, "variablePadre", variablePadre);




      if (listaOpciones && listaOpciones.length > 0 && (
        indicePadre == -1 ||
        valorPadre > 0 ||
        (variablePadre == "pais_moneda_destino_id" && valorPadre != "") || (variablePadre == "residencia" && valorPadre != ""))
      ) {
        for (let j = 0; j < listaOpciones.length; j++) {
          let selectTxt = "";
          if (typeof listaOpciones[j] !== "undefined") {
            if (listaOpciones[j][0] == valor) selectTxt = "selected";

            if (variable == "forma_entrega_id") {
              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 1] ||
                valorPadre == -1
              ) {
                // console.log("1");
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            } else if (variable == "banco") {
              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 1] ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            } else if (variable == "bco_wallet") {
              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 1] ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            }

            else if (variable == "banco_caja") {
              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 1] ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            } else if (variable == "moneda_caja") {
              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 1] ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            } else if (variable == "region_caja") {
              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 1] ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            } else if (variable == "comuna_caja") {

              if (
                (valorPadre == listaOpciones[j][listaOpciones[j].length - 1]) ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            } else if (variable == "sucursales") {
              // console.log("poblarOpcionesElemento; sucursales");
              // let comuna_caja = context.state.remitente.destinatarios[id_depersona].detalle_entrega[0].comuna_caja;

              if (
                valorPadre == listaOpciones[j][listaOpciones[j].length - 2] ||
                valorPadre == -1
              ) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: `${listaOpciones[j][1]} (${listaOpciones[j][2]})` ,
                });
                // console.log("ok sucursales", objetoElementoDom);
              }
            } else if (variable == "tipo_documento_id") {
              objetoElementoDom.elementos.push({
                selectTxt: selectTxt,
                value: listaOpciones[j][0],
                text: listaOpciones[j][1],
              });
            } else if (tipo_persona == "Destinatario" && variable == "region") {
              objetoElementoDom.elementos.push({
                selectTxt: selectTxt,
                value: listaOpciones[j][0],
                text: listaOpciones[j][1],
              });
            } else if ((variable == "tipo_cuenta")) {
              if (valorPadre == listaOpciones[j][3] || valorPadre == -1) {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }

            } else {
              // console.log("-+-+-+-+", variable, (valorPadre == listaOpciones[j][2] || valorPadre == -1), (tipo_persona == "Remitente" && variable == "region" && valorPadre == "152"));
              if (valorPadre == listaOpciones[j][2] || valorPadre == -1) {

                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
              else if (tipo_persona == "Remitente" && variable == "region" && valorPadre == "152") {
                objetoElementoDom.elementos.push({
                  selectTxt: selectTxt,
                  value: listaOpciones[j][0],
                  text: listaOpciones[j][1],
                });
              }
            }
          }
        }
      }

      if ((tipo_persona == "Destinatario" || tipo_persona == "Remitente") && borrarValorHijo) {
        await context.dispatch("buscarSubDepediente", {
          tipo_persona: tipo_persona,
          variable: variable,
          id_depersona: id_depersona,
        });
      }
      // console.log(variable, valorPadre, objetoElementoDom);
      return objetoElementoDom;
    },

    async buscarSubDepediente(context, data) {
      //{tipo_persona: tipo_persona, variable: variable}
      // console.log(data);
      let tipo_persona = data.tipo_persona;
      let variable = data.variable;
      let id_depersona = data.id_depersona;

      let edit_destinatario = data.edit_destinatario
        ? data.edit_destinatario
        : null;

      let regla = context.state.configuracion[
        `reglasFormulario${tipo_persona}`
      ].find((entrada) => entrada[4] == variable);
      let existenSubDependientes = false;
      do {

        regla = context.state.configuracion[
          `reglasFormulario${tipo_persona}`
        ].find((entrada) => entrada[4] == variable);
        if (regla[9] != "") {

          existenSubDependientes = true;
          let index = context.state.listaElementosDom.findIndex(
            (componente) => componente.variable == regla[9]
          );

          if (index > -1) {
            if (context.state.listaElementosDom[index].elementos) {
              context.state.listaElementosDom[index].elementos = [];
            }
            if ("padre_ok" in context.state.listaElementosDom[index]) {

              context.state.listaElementosDom[index].padre_ok = false;

            }

            if (tipo_persona == "Destinatario") {
              context.state.remitente.destinatarios[id_depersona][variable] = 0;

              if (edit_destinatario) {
                if (
                  variable == "forma_entrega_id" || variable == "moneda_destino_id" ||
                  variable == "tipo_cuenta" || variable == "bco_wallet" ||
                  variable == "banco" ||
                  variable == "id_banco_destino" || variable == "banco_caja" || variable == "region_caja" || variable == "comuna_caja" || variable == "sucursales" || variable == "moneda_caja" ||
                  ((variable == "numero_cuenta" || variable == "numero_cuenta_wallet") && edit_destinatario.editFE)
                ) {

                  delete context.state.remitente.destinatarios["0"].detalle_entrega["0"][variable];
                  if (variable == "comuna_caja") {
                    delete context.state.remitente.destinatarios["0"].detalle_entrega["0"].sucursales;
                  }
                }
              }
            } else if (tipo_persona == "Remitente") {

              context.state.remitenteTemp[regla[9]] = "";
            }
          }
        } else {
          existenSubDependientes = false;
        }
        variable = regla[9];

      } while (existenSubDependientes);

    },

    async obtenerValidaciones(context, data) {
      //{tipo_persona: aaa, nombreVariable: bbb}
      let tipo_persona = data.tipo_persona;
      let nombreVariable = data.nombreVariable;

      let indiceVariable = await context.dispatch("buscarReglaConfiguracion", {
        tipo_persona: tipo_persona,
        variable: nombreVariable,
      });
      return context.state.configuracion.reglasFormularioRemitente[
        indiceVariable
      ][8];
    },

    async buscarDatoId(context, data) {
      //{variable: aaa, id: bbb}
      let variable = data.variable;
      let id = data.id;
      // console.log(data);
      let auxiliar = context.state.configuracion.data.auxiliares[variable];
      // console.log("||||||", variable, id, auxiliar)
      let ruta = [];
      for (let c = auxiliar.length - 1; c >= 0; c--) {
        let datoruta = 0;
        let campo = auxiliar[c];

        for (let dato = 0; dato < campo.length; dato++) {
          if (campo[dato][0] == id) {
            datoruta = parseInt(id);
            id = campo[dato][2];
          }
        }
        ruta.push(datoruta);
      }
      return ruta.reverse();
    },

    async remitenteUpdate(context) {

      context.commit("updateRespRemitenteModif", {
        status: false,
        resp: false,
        msg: "",
      });
      let ObjetoResultados = context.state.remitenteTemp;
      // console.log(ObjetoResultados);
      let niveles = Math.max.apply(
        Math,
        context.state.configuracion.reglasFormularioRemitente.map((campo) => {
          return campo[3];
        })
      );

      let data = {};
      for (let nivelActual = 1; nivelActual <= niveles; nivelActual++) {

        let reglas = [];
        reglas = await context.dispatch("calcularDatosNecesarios", {
          tipo_persona: "Remitente",
          nivel: nivelActual,
          incluye_dependientes: true,
        });

        for (let i = 0; i < reglas.length; i++) {

          if (reglas[i][4] == "comuna_id") {
            data.comuna_id = ObjetoResultados[reglas[i][4]];
          } else if (reglas[i][4] == "numero_documento") {
            data.numero_documento = ObjetoResultados[reglas[i][4]];
          } else if (reglas[i][4] == "nacionalidad") {
            data.nacionalidad_pais_id = ObjetoResultados[reglas[i][4]];
          } else if (reglas[i][4] == "nombre1") {
            data.nombre1 = ObjetoResultados[reglas[i][4]].toUpperCase(); //await context.dispatch("primera_letra_mayuscula", ObjetoResultados[reglas[i][4]]);
          } else if (reglas[i][4] == "nombre2") {
            data.nombre2 = ObjetoResultados[reglas[i][4]].toUpperCase(); //await context.dispatch("primera_letra_mayuscula", ObjetoResultados[reglas[i][4]]);
          } else if (reglas[i][4] == "apellido1") {
            data.apellido1 = ObjetoResultados[reglas[i][4]].toUpperCase(); // await context.dispatch("primera_letra_mayuscula", ObjetoResultados[reglas[i][4]]);
          } else if (reglas[i][4] == "apellido2") {
            data.apellido2 = ObjetoResultados[reglas[i][4]].toUpperCase(); //await context.dispatch("primera_letra_mayuscula", ObjetoResultados[reglas[i][4]]);
          } else if (reglas[i][4] == "ocupacion") {
            data.ocupacion_id = ObjetoResultados[reglas[i][4]];
          } else if (reglas[i][4] == "residencia") {
            data.residencia_pais_id = ObjetoResultados[reglas[i][4]];
          } else if (reglas[i][4] == "ciudad") {
            data.ciudad = ObjetoResultados[reglas[i][4]].toUpperCase();
          } else if (reglas[i][4] != "region") {
            data[reglas[i][4]] = ObjetoResultados[reglas[i][4]];
          }
        }

        if (typeof data.numero_documento !== "undefined") {
          let penultima = data.numero_documento.length - 2;
          let guion = data.numero_documento.slice(penultima, -1);
          let largo = penultima + 1;
          let first = data.numero_documento.slice(0, -1);
          let second = data.numero_documento.slice(largo);
          if (second == "k") {
            second = second.toUpperCase();
            data.numero_documento = first + second;
          }
          if (guion !== "-") {
            data.numero_documento = first + "-" + second;
          }
        }
      }

      data.id = context.state.remitente.id;
      if (ObjetoResultados.fecha_nacimiento) {
        let fecha_nac_array = ObjetoResultados.fecha_nacimiento.split("-");
        data.fecha_nacimiento = `${fecha_nac_array[2]}-${fecha_nac_array[1]}-${fecha_nac_array[0]}`;
        data.genero = ObjetoResultados.genero;
      }

      // console.log(data);
      const entradasData = Object.entries(data);
      const params = new URLSearchParams();
      entradasData.forEach((entrada) => {
        params.append(entrada[0], entrada[1]);
      });
      context.dispatch("remitenteUpdateEjecutar", {
        params: params,
        cerrar: true,
      });

    },

    async remitenteUpdateEjecutar(context, data) {

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/editar`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: data.params,
      }).then(async (dataX) => {

        if (!dataX.data.error) {

          let propiedades = Object.entries(context.state.remitenteTemp);
          // console.log(context.state.remitenteTemp)
          for (let index = 0; index < propiedades.length; index++) {
            const prop = propiedades[index];
            if (prop[0] == "nombre1") {
              context.state.remitente.nombre1 = prop[1];
            } else  if (prop[0] == "nombre2") {
              context.state.remitente.nombre2 = prop[1];
            } else if (prop[0] == "nacionalidad_pais_id") {
              context.state.remitente.nacionalidad = prop[1];
            } else if (prop[0] == "ocupacion_id") {
              context.state.remitente.ocupacion = prop[1];
            } else if (prop[0] == "residencia_pais_id") {
              context.state.remitente.residencia = prop[1];
            }
            // else if (prop[0] == "region_id") {
            //   context.state.remitente.region = prop[1];
            // } 
            else if (prop[0] != "ultimas_transacciones") {
              context.state.remitente[prop[0]] = prop[1];
            }
          }

          let comuna_index = context.state.configuracion.pais["152_1"].comuna_id.findIndex(com => { return com[0] == context.state.remitenteTemp.comuna_id });
          if (comuna_index > -1) {
            context.state.remitente.comuna_nombre = context.state.configuracion.pais["152_1"].comuna_id[comuna_index][1];
          }
          let region_index = context.state.configuracion.pais["152_1"].region.findIndex(reg => { return reg[0] == context.state.remitenteTemp.region })
          if (region_index > -1) {
            context.state.remitente.region_nombre = context.state.configuracion.pais["152_1"].region[region_index][1];
          }

          if (data.cerrar) {
            context.commit("updateRespRemitenteModif", { status: true, resp: true, msg: '' });
            context.state.remitenteTemp = {};

            const d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            try {
              document.cookie = `gf_token=${context.state.remitente.token};${expires}; path=${context.state.cookie_domain}`;
              document.cookie = `gf_name=${context.state.remitente.nombre1} ${context.state.remitente.nombre2};${expires}; path=${context.state.cookie_domain}`;
              document.cookie = `gf_document=${context.state.remitente.numero_documento};${expires}; path=${context.state.cookie_domain}`;
            } catch (error) {
              console.log(error);
            }

          }
        } else {
          context.commit("updateRespRemitenteModif", { status: true, resp: false, msg: dataX.data.codigo });
        }
      })
        .catch(err => {
          console.log(err);
          context.commit("updateRespRemitenteModif", { status: true, resp: false, msg: err });
        });
    },

    forgotPassword(context, data) {
      console.log(data);
      context.commit("updateRespforgotPass", {
        status: false,
        resp: false,
        msj: "",
      });


      context.commit("setshowSpinner", true);

      const params = new URLSearchParams();
      params.append("rut", data);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/recuperarclave`,
        data: params,
        withCredentials: false,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }

      }).then((response) => {
        console.log("*** forgotPassword", response.data);
        context.commit("setshowSpinner", false);
        if (response.data) {
          if (!response.data.error) {
            context.state.rut_forgot_pass = data;
            context.commit("updateRespforgotPass", { status: true, resp: true, msj: response.data.message });
          } else {
            context.commit("updateRespforgotPass", { status: true, resp: false, msj: response.data.codigo });
          }
        } else {
          context.commit("updateRespforgotPass", { status: true, resp: false, msj: response.data.message });
          // console.log("Mal", response)
        }
      })
        .catch(err => {
          console.log(err);
          context.commit("updateRespforgotPass", { status: true, resp: false, msj: err });
          context.commit("setshowSpinner", false);
        });
    },



    changePassword(context, data) {
      context.commit("updateRespForgotPass_sin_log", {
        status: false,
        resp: false,
        msj: "",
      });

      const params = new URLSearchParams();
      params.append("rut", context.state.rut_forgot_pass);
      params.append("clave_nueva", data.newPassword);
      params.append("clave_nueva2", data.confirmPassword);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/cambioClaveSinToken`,
        data: params,
        withCredentials: false,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then((response) => {
        console.log("**3*", response.data);
        if (response.data && !response.data.error) {

          context.commit("updateRespForgotPass_sin_log", { status: true, resp: true, msj: response.data.message });
          context.state.rut_forgot_pass = "";

        } else {
          context.commit("updateRespForgotPass_sin_log", { status: true, resp: false, msj: response.data.message });
          // console.log("Mal", response)
        }

      })
        .catch(err => {
          console.log(err);
          context.commit("updateRespForgotPass_sin_log", { status: true, resp: false, msj: err });
        });
    },

    changePassword_logued(context, data) {
      context.commit("updateRespforgotPass3", {
        status: false,
        resp: false,
        msj: "",
      });
      // console.log(data, context.state.remitente.id, context.state.remitente.numero_documento);
      const params = new URLSearchParams();
      params.append("id", context.state.remitente.id);
      params.append("rut", context.state.remitente.numero_documento);
      params.append("clave_antigua", data.clave_antigua);
      params.append("clave_nueva", data.clave_nueva);
      params.append("clave_nueva2", data.clave_nueva2);


      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/cambioClave`,
        data: params,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
      }).then((response) => {
        console.log("**changePassword_logued*", response.data);
        if (response.data && !response.data.error) {

          context.commit("updateRespforgotPass3", { status: true, resp: true, msj: response.data.message });
          context.state.rut_forgot_pass = "";

        } else {
          context.commit("updateRespforgotPass3", { status: true, resp: false, msj: response.data.codigo });
          // console.log("Mal", response)
        }

      })
        .catch(err => {
          console.log(err);
          context.commit("updateRespforgotPass3", { status: true, resp: false, msj: err });
        });
    },

    async validarDestinoSelected(context, data) {
      // console.log(data);
      await context.commit("updateTransaccion", {
        pais_destino: data.pais_val,
      });

      await context.dispatch("chargeExchangeRateAndCoins");
      await context.dispatch("chargeShippingCost");

    },

    async chargeExchangeRateAndCoins(context) {
      let objTransaccion = {};

      for (let index = 0; index < context.state.configuracion.costosEnvio.length; index++) {
        const costo = context.state.configuracion.costosEnvio[index];

        if (costo.pais_destino == context.state.transaccion.pais_destino.split("_")[0]) {
          let valor_minimo_origen = costo.valor_minimo_origen;
          let valor_maximo_origen = costo.valor_maximo_origen;
          if (typeof valor_minimo_origen == 'string') {
            valor_minimo_origen.replaceAll(".", "");
            valor_minimo_origen = parseInt(valor_minimo_origen)
          }
          if (typeof valor_maximo_origen == 'string') {
            valor_maximo_origen.replaceAll(".", "");
            valor_maximo_origen = parseInt(valor_maximo_origen)
          }
          objTransaccion.valor_minimo_origen = valor_minimo_origen;
          objTransaccion.valor_maximo_origen = valor_maximo_origen;
        }
      }
      for (
        let index = 0;
        index < context.state.configuracion.tasasCambio.length;
        index++
      ) {
        if (
          context.state.configuracion.tasasCambio[index].id_pais_destino ==
          context.state.transaccion.pais_destino
        ) {
          objTransaccion.moneda_origen_id =
            context.state.configuracion.tasasCambio[index].id_moneda_origen;
          objTransaccion.moneda_destino_id =
            context.state.configuracion.tasasCambio[index].id_moneda_destino;

          objTransaccion.tasa_transaccion_id = context.state.configuracion.tasasCambio[index].tasa_transaccion_id

          for (let i = 0; i < context.state.configuracion.monedas.length; i++) {
            if (
              context.state.configuracion.tasasCambio[index]
                .id_moneda_destino == context.state.configuracion.monedas[i].id
            ) {
              // console.log("id_moneda_destino", context.state.configuracion.monedas[i].id);
              objTransaccion.moneda_destino_nombre =
                context.state.configuracion.monedas[i].nombre_corto;
              objTransaccion.moneda_destino_sigla =
                context.state.configuracion.monedas[i].sigla;
              objTransaccion.moneda_destino_simbolo =
                context.state.configuracion.monedas[i].simbolo;
              objTransaccion.moneda_destino_cantidad_decimales =
                context.state.configuracion.monedas[i].cantidad_decimales;
              objTransaccion.tasa_invertida = parseInt(
                context.state.configuracion.monedas[i].mostrartasainvertida
              );
            }
            if (
              context.state.configuracion.tasasCambio[index].id_moneda_origen ==
              context.state.configuracion.monedas[i].id
            ) {
              objTransaccion.moneda_origen_nombre =
                context.state.configuracion.monedas[i].nombre_largo;
              objTransaccion.moneda_origen_sigla =
                context.state.configuracion.monedas[i].sigla;
              objTransaccion.moneda_origen_simbolo =
                context.state.configuracion.monedas[i].simbolo;
              objTransaccion.moneda_origen_cantidad_decimales =
                context.state.configuracion.monedas[i].cantidad_decimales;
            }
          }

          if (objTransaccion.tasa_invertida) {
            objTransaccion.valor_cambio = parseFloat(
              context.state.configuracion.tasasCambio[index].valor_cambio
            );
          } else {
            objTransaccion.valor_cambio = parseFloat(
              context.state.configuracion.tasasCambio[index].valor_cambio
            );
          }
        }
      }
      await context.commit("updateTransaccion", objTransaccion);
      return true;
    },

    async chargeShippingCost(context) {
      let costos_paisTemp = [];
      let costo = {};

      for (let i = 0; i < context.state.configuracion.costosEnvio.length; i++) {
        let aux = context.state.transaccion.pais_destino.split("_")[0];
        if (context.state.configuracion.costosEnvio[i].pais_destino == aux) {
          costo.monto_inicio =
            context.state.configuracion.costosEnvio[i].monto_inicio;
          costo.monto_fin =
            context.state.configuracion.costosEnvio[i].monto_fin;
          costo.valor_envio =
            context.state.configuracion.costosEnvio[i].valor_envio;
          costo.valor_porcentual = context.state.configuracion.costosEnvio[i].valor_porcentual;
          costo.costos_servicio_id = context.state.configuracion.costosEnvio[i].costos_servicio_id
          costos_paisTemp.push(costo);
          costo = {};
        }
      }

      await context.commit("updateCostoPais", costos_paisTemp);

    },

    validarCupon(context, codigo) {
      // console.log(context.state.transaccion.pais_destino.split("_")[0]);
      context.commit("updateRespCupon", { status: false, resp: false, msg: "", obj: null });
      //   8431D5owV4rP6U
      const params = new URLSearchParams();
      params.append("codigo_cupon", codigo);
      params.append("cliente_id", context.state.remitente.id);
      params.append("pais_destino_id", context.state.transaccion.pais_destino.split("_")[0]);

      context.commit("updateRespCupon", {
        resp: true,
        msg: "",
        obj: { porcentaje_descuento: 40 },
      });


      Axios({
        method: 'POST',
        data: params,
        url: `${context.state.link_controlador}cupones/validaCupon`,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        }
      }).then(async (resp) => {

        const response = resp.data;
        // console.log(response);

        if (!response.error) {
          if (response.porcentaje_descuento && response.porcentaje_descuento != "") {
            context.commit("updateRespCupon", {
              status: true, resp: true, msg: "", obj: {
                tipo_descuento: "1",
                porcentaje_descuento: parseInt(response.porcentaje_descuento),
                valor_descuento: 0,
                id: "",
                cupon: codigo,
              }
            });
          } else if (response.valor_descuento && response.valor_descuento != "") {
            context.commit("updateRespCupon", {
              status: true, resp: true, msg: "", obj: {
                tipo_descuento: "2",
                porcentaje_descuento: 0,
                valor_descuento: parseInt(response.valor_descuento),
                id: "",
                cupon: codigo,
              }
            });
          } else {
            context.commit("updateRespCupon", { status: true, resp: false, msg: response.message, obj: null });
          }

        } else {
          context.commit("updateRespCupon", { status: true, resp: false, msg: response.message, obj: null });
        }

      })
        .catch(err => {
          console.log(err);
        });
    },

    async valida_monthly_amount(context, simulador_origen) {
      const params = new URLSearchParams();
      params.append("monto_inicio", simulador_origen);
      params.append("cliente", context.state.remitente.id);

      context.commit("updateRespCupon", {
        resp: true,
        msg: "",
        obj: { porcentaje_descuento: 40 },
      });

      return await Axios({
        method: 'POST',
        data: params,
        url: `${context.state.link_controlador}transacciones/getMontosMaximos`,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        }
      }).then(async (resp) => {
        console.log(resp.data);
        if (!resp.data.error) {
          return { resp: true };
        } else {
          return {
            resp: false,
            msg: resp.data.mensaje,
            cod: resp.data.codigo,
            max: resp.data.maximo_posible
          };
        }
      })
        .catch(err => {
          console.log(err);
          return { resp: false }
        });
    },

    async validacionesAux(context, data) {
      let objetoClearTransaccion = {};

      objetoClearTransaccion.porcentaje_descuento = parseInt(
        data.porcentaje_descuento
      );
      objetoClearTransaccion.valor_descuento = parseInt(
        data.valor_descuento
      );
      objetoClearTransaccion.tipo_descuento = data.tipo_descuento;
      objetoClearTransaccion.cupon = data.cupon;
      objetoClearTransaccion.descuento = data.descuento;

      objetoClearTransaccion.giro_gastos_envio =
        context.state.transaccion.giro_gastos_envio -
        context.state.transaccion.descuento;

      data.cupon
        ? (objetoClearTransaccion.cupon =
          data.cupon)
        : false;

      if (context.state.transaccion.tasa_invertida) {

        objetoClearTransaccion.valor_cambio = parseFloat(
          context.state.transaccion.valor_cambio
        );

        let montoOrigen = context.state.transaccion.monto_origen.replace(
          ".",
          ""
        );

        objetoClearTransaccion.monto_pagar = parseInt(parseInt(montoOrigen));

      } else {
        objetoClearTransaccion.valor_cambio = parseFloat(
          context.state.transaccion.valor_cambio
        );

        objetoClearTransaccion.monto_pagar = parseInt(
          context.state.transaccion.monto_pagar
        );

      }
      // console.log(response);
      if (data.conservar_destinatario) {
        // let objetoClearTransaccion = {};
        if (data.tasa_mostrar)
          objetoClearTransaccion.tasa_mostrar = data.tasa_mostrar;
        if (context.state.transaccion.numero_cuenta)
          objetoClearTransaccion.numero_cuenta = "";
        if (context.state.transaccion.cuenta_corriente)
          objetoClearTransaccion.cuenta_corriente = data.cuenta_corriente;

        context.commit("updateTransaccion", objetoClearTransaccion);
        return;
      }
      // let objetoClearTransaccion = {};
      if (context.state.transaccion.banco) objetoClearTransaccion.banco = "";
      if (context.state.transaccion.id_banco_destino)
        objetoClearTransaccion.id_banco_destino = "";
      if (context.state.transaccion.tipo_cuenta)
        objetoClearTransaccion.tipo_cuenta = "";
      if (context.state.transaccion.cuenta_corriente)
        objetoClearTransaccion.cuenta_corriente = "";
      if (context.state.transaccion.motivo_transaccion)
        objetoClearTransaccion.motivo_transaccion = "";
      if (context.state.transaccion.numero_cuenta)
        objetoClearTransaccion.numero_cuenta = "";
      if (context.state.transaccion.id_persona)
        objetoClearTransaccion.id_persona = "";
      if (context.state.transaccion.forma_entrega_id)
        objetoClearTransaccion.forma_entrega_id = "";
      if (data.tasa_mostrar)
        objetoClearTransaccion.tasa_mostrar = data.tasa_mostrar;

      context.commit("updateTransaccion", objetoClearTransaccion);
    },

    async validarTarjetaBeneficiario(context, data) {
      // console.log(data);
      context.commit("updateRespSelectDestinatario", {
        status: false,
        resp: false,
        msj: "",
      });
      // console.log(context.state.transaccion);
      if (context.state.configuracion.pais[context.state.transaccion.pais_destino].pausa == "1") {
        context.commit("updateRespSelectDestinatario", {
          status: true,
          resp: false,
          msj: "País inhabilitado",
        });
        return false;
      }

      await context.commit("updateTransaccion", {
        banco: data.id_banco_destino,
        id_banco_destino: data.id_banco_destino,
        tipo_cuenta: data.tipo_cuenta,
        cuenta_corriente: data.cuenta_corriente,
        motivo_transaccion: data.motivo_transaccion,
        numero_cuenta: data.nro_cuenta,
        id_persona: data.id_persona,
        forma_entrega_id: data.forma_entrega_id,
        detalle_entrega_id: data.detalle_entrega_id,
      });
      // console.log(context.state.transaccion.tasa_mostrar);
      context.commit("updateRespSelectDestinatario", {
        status: true,
        resp: true,
        msj: "",
      });
    },

    async calcular_monto_origen(context, data) {

      let respuesta = {
        aConvertir: 0,
        origen_temp: 0,
        comision: 0,
        descuento: 0
      }
      let destino_temp = 0;
      let costo_servicio_encontrado = false;

      costo_servicio_encontrado = await context.dispatch("check_getShippingCost", data.destino / data.valor_cambio);
      // console.log(costo_servicio_encontrado);
      if (!costo_servicio_encontrado) {
        respuesta.comision = 0;
        respuesta.aConvertir = 0;
        respuesta.origen_temp = 0;
        respuesta.descuento = 0;
        return respuesta;
      }

      if (data.objRespCupon) {
        if (data.objRespCupon.tipo_descuento == "1") {

          respuesta.origen_temp = parseInt(
            data.destino /
            (data.valor_cambio *
              (1 - await context.dispatch("getShippingCost_factor", data.destino / data.valor_cambio) + (await context.dispatch("getShippingCost_factor", data.destino / data.valor_cambio) * (data.objRespCupon.porcentaje_descuento / 100))))
          );

        } else if (data.objRespCupon.tipo_descuento == "2") {

          let valor_desc_temp = data.objRespCupon.valor_descuento;
          if (parseInt(context.state.transaccion.giro_gastos_envio) <= valor_desc_temp) {
            valor_desc_temp = parseInt(context.state.transaccion.giro_gastos_envio);
          }

          respuesta.origen_temp = parseInt(
            ((data.destino / data.valor_cambio) - valor_desc_temp) / (1 - await context.dispatch("getShippingCost_factor", data.destino / data.valor_cambio))
          );
          respuesta.comision = parseInt(context.state.transaccion.giro_gastos_envio);
          if (respuesta.comision > data.objRespCupon.valor_descuento) {
            respuesta.descuento = data.objRespCupon.valor_descuento;
          } else {
            respuesta.descuento = respuesta.comision;
          }
        }

      } else {
        respuesta.origen_temp = parseInt(
          data.destino /
          (data.valor_cambio *
            (1 - await context.dispatch("getShippingCost_factor", data.destino / data.valor_cambio)))
        );
      }

      costo_servicio_encontrado = await context.dispatch("check_getShippingCost", respuesta.origen_temp);
      if (!costo_servicio_encontrado) {
        respuesta.comision = 0;
        respuesta.aConvertir = 0;
        return respuesta;
      }
      respuesta.comision = parseInt(context.state.transaccion.giro_gastos_envio);

      if (data.objRespCupon) {
        if (data.objRespCupon.tipo_descuento == "1") {
          respuesta.descuento = parseInt(
            respuesta.comision * (data.objRespCupon.porcentaje_descuento / 100)
          );
        } else if (data.objRespCupon.tipo_descuento == "2") {
          if (respuesta.comision > data.objRespCupon.valor_descuento) {
            respuesta.descuento = data.objRespCupon.valor_descuento;
          } else {
            respuesta.descuento = respuesta.comision;
          }
        }

      }

      if (context.state.transaccion.moneda_destino_id == "2") {
        destino_temp = (
          (respuesta.origen_temp - respuesta.comision + respuesta.descuento) *
          data.valor_cambio
        );
      } else {
        destino_temp = Math.floor(
          (respuesta.origen_temp - respuesta.comision + respuesta.descuento) *
          data.valor_cambio
        );
      }


      respuesta.origen_temp -= 5;

      do {
        respuesta.origen_temp += 1;

        costo_servicio_encontrado = await context.dispatch("check_getShippingCost", respuesta.origen_temp);
        if (!costo_servicio_encontrado) {
          respuesta.comision = 0;
          respuesta.aConvertir = 0;
          return respuesta;
        }

        await context.dispatch("getShippingCost", respuesta.origen_temp);

        respuesta.comision = parseInt(
          context.state.transaccion.giro_gastos_envio
        );
        respuesta.descuento = 0;
        if (data.objRespCupon && data.objRespCupon.tipo_descuento == "1") {
          respuesta.descuento = parseInt(
            respuesta.comision * (data.objRespCupon.porcentaje_descuento / 100)
          );
        } else if (data.objRespCupon && data.objRespCupon.tipo_descuento == "2") {
          if (respuesta.comision > data.objRespCupon.valor_descuento) {
            respuesta.descuento = data.objRespCupon.valor_descuento;
          } else {
            respuesta.descuento = respuesta.comision;
          }
        }

        if (context.state.transaccion.moneda_destino_id == "2") {
          destino_temp = (
            (respuesta.origen_temp - respuesta.comision + respuesta.descuento) *
            data.valor_cambio
          );
        } else {
          destino_temp = Math.floor(
            (respuesta.origen_temp - respuesta.comision + respuesta.descuento) *
            data.valor_cambio
          );
        }

        // console.log(destino_temp, respuesta.descuento, respuesta.descuento);
      } while ((destino_temp != data.destino && destino_temp <= data.destino) && !isNaN(destino_temp));

      respuesta.aConvertir =
        respuesta.origen_temp -
        (context.state.transaccion.giro_gastos_envio - respuesta.descuento);

      return respuesta;
    },

    async getShippingCost(context, valor) {
      if (typeof valor == 'string') {
        valor = valor.replaceAll(".", "");
        valor = parseInt(valor);
      }

      for (let i = 0; i < context.state.costos_pais.length; i++) {
        if (
          valor >= parseInt(context.state.costos_pais[i].monto_inicio) &&
          valor <= parseInt(context.state.costos_pais[i].monto_fin)
        ) {

          await context.commit("updateTransaccion", {
            giro_gastos_envio: parseInt(
              context.state.costos_pais[i].valor_porcentual * valor
            ),
            costos_servicio_id: context.state.costos_pais[i].costos_servicio_id
          });
          // console.log(context.state.transaccion);
        }
      }
    },
    async getShippingCost_factor(context, valor) {
      if (typeof valor == 'string') {
        valor = valor.replaceAll(".", "");
        valor = parseInt(valor);
      }
      let factor = 0;
      for (let i = 0; i < context.state.costos_pais.length; i++) {
        if (
          valor >= parseInt(context.state.costos_pais[i].monto_inicio) &&
          valor <= parseInt(context.state.costos_pais[i].monto_fin)
        ) {
          factor = context.state.costos_pais[i].valor_porcentual;

          await context.commit("updateTransaccion", {
            giro_gastos_envio: parseInt(
              context.state.costos_pais[i].valor_porcentual * valor
            ),
          });
        }
      }
      return factor;
    },
    async check_getShippingCost(context, valor) {
      // console.log(valor);
      if (typeof valor == 'string') {
        console.log("error");
        valor = valor.replaceAll(".", "");
        valor = parseInt(valor);
      }
      // valor = parseInt(valor);
      // console.log(valor);
      let encontrado = false;
      for (let i = 0; i < context.state.costos_pais.length; i++) {
        if (
          valor >= parseInt(context.state.costos_pais[i].monto_inicio) &&
          valor <= parseInt(context.state.costos_pais[i].monto_fin)
        ) {
          encontrado = true;

          await context.commit("updateTransaccion", {
            giro_gastos_envio: parseInt(
              context.state.costos_pais[i].valor_porcentual * valor
            ),
          });
        }
      }
      return encontrado;
    },
















    async formatCotizadorResumen(context) {

      try {
        let aux = [[], []];

        context.commit("setshowSpinner", true);

        await context.dispatch("traer_cuentas_banco_v2", {
          pais: context.state.transaccion.pais_destino.split("_")[0],
          banco: context.state.transaccion.banco,
          moneda: context.state.transaccion.moneda_destino_id
        });

        await context.dispatch("traer_tipo_doc_v2", {
          pais: context.state.transaccion.pais_destino.split("_")[0]
        });


        let montoOrigenValor =
          typeof context.state.transaccion.monto_origen == "string"
            ? context.state.transaccion.monto_origen.replaceAll(".", "")
            : context.state.transaccion.monto_origen;
        let descuentoValor = context.state.transaccion.descuento ?
          typeof context.state.transaccion.descuento == "string"
            ? context.state.transaccion.descuento.replaceAll(".", "")
            : context.state.transaccion.descuento : 0;
        let giro_gastos_envioValor =
          typeof context.state.transaccion.giro_gastos_envio == "string"
            ? context.state.transaccion.giro_gastos_envio.replaceAll(".", "")
            : context.state.transaccion.giro_gastos_envio;

        let montoOrigen = await context.dispatch(
          "formatNumber",
          montoOrigenValor
        );
        let descuento = await context.dispatch("formatNumber", descuentoValor);
        let giro_gastos_envio = await context.dispatch(
          "formatNumber",
          parseInt(giro_gastos_envioValor) + parseInt(descuentoValor)
        );
        // console.log(giro_gastos_envioValor, giro_gastos_envio, descuentoValor, context.state.transaccion.descuento);
        let monto_convertir = await context.dispatch(
          "formatNumber",
          parseInt(montoOrigenValor) - parseInt(giro_gastos_envioValor)
        );

        // let monto_destino = "";
        // if(context.state.transaccion.moneda_destino_id == "2"){
        //   let aux = context.state.transaccion.monto_destino.split(".");
        //   monto_destino
        // }else{
        //   monto_destino = await context.dispatch(
        //     "formatNumber",
        //     context.state.transaccion.monto_destino
        //   );
        // }
        // let monto_destino = await context.dispatch(
        //   "formatNumber",
        //   context.state.transaccion.monto_destino
        // );
        let monto_destino = context.state.transaccion.monto_destino;

        if (descuento > 0) {
          aux[0][0] = [
            "envias",
            `${montoOrigen} ${context.state.transaccion.moneda_origen_sigla}`,
            "1", true
          ];
          aux[0][1] = [
            "costo_envio",
            `${giro_gastos_envio} ${context.state.transaccion.moneda_origen_sigla}`,
            "0", true
          ];
          aux[0][2] = [
            "cupon",
            `- ${descuento} ${context.state.transaccion.moneda_origen_sigla}`,
            "0", true
          ];
          aux[0][3] = [
            "a_convertir",
            `${monto_convertir} ${context.state.transaccion.moneda_origen_sigla}`,
            "0",
          ];
          aux[0][4] = [
            "pais_destino",
            `${context.state.configuracion.pais[
              context.state.transaccion.pais_destino
            ].nombre
            } ${context.state.transaccion.moneda_destino_sigla}`,
            "0", true
          ];

          aux[0][5] = [
            "tipo_cambio",
            `${context.state.transaccion.tasa_mostrar}`,
            "0", true
          ];
          aux[0][6] = [
            "recibe",
            `${monto_destino} ${context.state.transaccion.moneda_destino_sigla}`,
            "1", true
          ];
        } else {
          aux[0][0] = [
            "envias",
            `${montoOrigen} ${context.state.transaccion.moneda_origen_sigla}`,
            "1", true
          ];
          aux[0][1] = [
            "costo_envio",
            `${giro_gastos_envio} ${context.state.transaccion.moneda_origen_sigla}`,
            "0", true
          ];
          aux[0][2] = [
            "a_convertir",
            `${monto_convertir} ${context.state.transaccion.moneda_origen_sigla}`,
            "0", true
          ];

          aux[0][3] = [
            "pais_destino",
            `${context.state.configuracion.pais[
              context.state.transaccion.pais_destino
            ].nombre
            } ${context.state.transaccion.moneda_destino_sigla}`,
            "0", true
          ];

          aux[0][4] = [
            "tipo_cambio",
            `${context.state.transaccion.tasa_mostrar}`,
            "0", true
          ];
          aux[0][5] = [
            "recibe",
            `${monto_destino} ${context.state.transaccion.moneda_destino_sigla}`,
            "1", true
          ];
        }

        // console.log(context.state.transaccion);
        let tipo_documento_nombreValor2 = Object.keys(
          context.state.configuracion.pais[
            context.state.transaccion.pais_destino
          ].tipo_documento_id
        );
        let tipo_documento_nombreValor3 = tipo_documento_nombreValor2.findIndex(
          (doc) => {
            return (
              context.state.configuracion.pais[
                context.state.transaccion.pais_destino
              ].tipo_documento_id[doc][0] ==
              context.state.remitente.destinatarios[
                context.state.transaccion.id_persona
              ].tipo_documento_id
            );
          }
        );
        let tipo_documento_nombreValor4 =
          tipo_documento_nombreValor2[tipo_documento_nombreValor3];

        let tipo_cuentaValor2 = context.state.configuracion.pais[context.state.transaccion.pais_destino].tipo_cuenta;
        let tipo_cuentaValor3 = tipo_cuentaValor2.findIndex((tCuenta) => {

          return (
            tCuenta[0] == context.state.transaccion.tipo_cuenta
          );
        });

        let tipo_cuentaValor4 = tipo_cuentaValor3;

        if (context.state.transaccion.forma_entrega_id == "1") {


          aux[1][0] = [
            "nombre",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].nombre1
            } ${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].apellido1
            } ${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].apellido2
            }`,
            "0", true
          ];
          aux[1][1] = [
            `${context.state.configuracion.pais[
              context.state.transaccion.pais_destino
            ].tipo_documento_id[tipo_documento_nombreValor4][1]
            }`,
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].numero_documento
            }`,
            "0", false
          ];
          aux[1][2] = [
            "correo",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].correo
            }`,
            "0", true
          ];
          aux[1][3] = [
            "telefono",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].telefono
            }`,
            "0", true
          ];

          aux[1][4] = [
            "nom_bco",
            `${context.state.remitente.destinatarios[context.state.transaccion.id_persona].detalle_entrega[context.state.transaccion.detalle_entrega_id].bancos_nombre
            }`,
            "0", true
          ];
          aux[1][5] = [
            "num_cta",
            `${context.state.transaccion.numero_cuenta}`,
            "0", true
          ];

          aux[1][6] = [
            "tipo_cta",
            `${context.state.configuracion.pais[
              context.state.transaccion.pais_destino
            ].tipo_cuenta[tipo_cuentaValor4][1]
            }`,
            "0", true
          ];



        } else if (context.state.transaccion.forma_entrega_id == "2") {
          aux[1][0] = [
            "nombre",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].nombre1
            } ${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].apellido1
            } ${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].apellido2
            }`,
            "0", true
          ];
          aux[1][1] = [
            `${context.state.configuracion.pais[
              context.state.transaccion.pais_destino
            ].tipo_documento_id[tipo_documento_nombreValor4][1]
            }`,
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].numero_documento
            }`,
            "0", false
          ];
          aux[1][2] = [
            "correo",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].correo
            }`,
            "0", true
          ];
          aux[1][3] = [
            "telefono",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].telefono
            }`,
            "0", true
          ];

          let banco_caja = context.state.remitente.destinatarios[context.state.transaccion.id_persona].detalle_entrega[context.state.transaccion.detalle_entrega_id].bancos_nombre;
          aux[1][4] = ["entrega_caja", banco_caja, "0", true];

        } else if (context.state.transaccion.forma_entrega_id == "6") {

          aux[1][0] = [
            "nombre",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].nombre1
            } ${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].apellido1
            } ${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].apellido2
            }`,
            "0", true
          ];
          aux[1][1] = [
            `${context.state.configuracion.pais[
              context.state.transaccion.pais_destino
            ].tipo_documento_id[tipo_documento_nombreValor4][1]
            }`,
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].numero_documento
            }`,
            "0", false
          ];
          aux[1][2] = [
            "correo",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].correo
            }`,
            "0", true
          ];

          aux[1][3] = [
            "telefono",
            `${context.state.remitente.destinatarios[
              context.state.transaccion.id_persona
            ].telefono
            }`,
            "0", true
          ];

          let banco_caja = context.state.remitente.destinatarios[context.state.transaccion.id_persona].detalle_entrega[context.state.transaccion.detalle_entrega_id].bancos_nombre;
          aux[1][4] = ["deposito_wallet", banco_caja, "0", true];

          aux[1][5] = [
            "num_cta",
            `${context.state.transaccion.numero_cuenta}`,
            "0", true
          ];


        }

        // context.state.dataResumen = aux;
        context.commit("set_dataResumen", aux);
        context.commit("setshowSpinner", false);
      } catch (error) {
        context.commit("setshowSpinner", false);
      }

    },

    async formarReciboTransferencia(context, data) {
      // console.log(data, context.state.remitente);

      let montoOrigen = await context.dispatch("formatNumber", data.monto_origen
      );

      let moneda_origen_sigla_index = context.state.configuracion.monedas.findIndex(moneda => { return moneda.id == "1" });
      let moneda_origen_sigla = "";
      if (moneda_origen_sigla_index > -1) {
        moneda_origen_sigla = context.state.configuracion.monedas[moneda_origen_sigla_index].sigla;
      }

      let moneda_destino_sigla_index = context.state.configuracion.monedas.findIndex(moneda => { return moneda.id == data.moneda_destino_id });
      let moneda_destino_sigla = "";
      if (moneda_destino_sigla_index > -1) {
        moneda_destino_sigla = context.state.configuracion.monedas[moneda_destino_sigla_index].sigla;
      }

      const options = {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let fecha_ingreso = new Date(`${data.fecha_ingreso} ${data.hora_ingreso}`).toLocaleDateString(
        `${context.state.idiomaPreferencia.cod}-${context.state.idiomaPreferencia.cod.toUpperCase()}`,
        options
      );

      let monto_entregado = await context.dispatch("formatNumber", data.monto_destino);

      let aux = [];
      aux[0] = [
        "monto_transferido",
        `$ ${montoOrigen} ${moneda_origen_sigla}`, false
      ];
      aux[1] = [
        "destinatario",
        `${data.destinatario_nombres} ${data.destinatario_apellido1} ${data.destinatario_apellido2}`, false
      ];
      aux[2] = ["fecha_transfer", `${fecha_ingreso}`, false];

      aux[3] = [
        "forma_entrega_id",
        `${data.pais_destino}[${data.forma_entrega_id}]`, true
      ];
      let tipo_cta = data.tipos_cuenta_nombre ? data.tipos_cuenta_nombre : '';
      let nro_cuenta = data.nro_cuenta ? `(${tipo_cta} N° ${data.nro_cuenta})` : '';
      aux[4] = [
        "bco_pago",
        `${data.destinatario_banco_destino_nombre} ${nro_cuenta}`, false
      ];
      aux[5] = [
        "monto_entregado",
        `$ ${monto_entregado} ${moneda_destino_sigla}`, false
      ];
      aux[6] = ["motivo_transaccion", data.motivo_transaccion, true];
      aux[7] = ["mensaje", data.mensaje_destinatario, false];
      let estado_giro = "";

      if (
        data.id_estado_pago == "3" ||
        data.id_estado_envio == "4" ||
        data.id_estado_envio == "5" ||
        data.id_estado_envio == "6" ||
        data.id_estado_retiro == "3"
      ) {
        estado_giro = "cancelado";
      } else if (data.id_estado_pago == "1" && (data.id_estado_envio == "1") && data.id_estado_retiro == "1") {
        estado_giro = `por_pagar`;
      } else if (data.id_estado_pago == "2" && (data.id_estado_envio == "1" || data.id_estado_envio == "2") && data.id_estado_retiro == "1") {
        estado_giro = `pagado`;
      } else if (data.id_estado_pago == "2" && (data.id_estado_envio == "3") && data.id_estado_retiro == "1") {
        estado_giro = `enviado`;
      } else if (data.id_estado_pago == "2" && (data.id_estado_envio == "3") && data.id_estado_retiro == "2") {
        if (
          data.forma_entrega_id == "1" ||
          data.forma_entrega_id == "6"
        ) {
          estado_giro = `entregado`;
        } else if (data.forma_entrega_id == "2") {
          estado_giro = `retirado`;
        }
      }

      aux[8] = ["estado_giro", `${estado_giro}`, true];
      // aux[8] = ["", ""];
      // aux[9] = ["identificador_interno", data.transaccion_id, false];

      context.state.detalleReciboTransferencia = [...aux];
    },


    async beneficiarioRegister(context, datos) {
      // console.log(context.state.remitente);
      context.commit("updateRespBeneficiarioModif", {
        status: false,
        resp: false,
        msg: "",
      });

      let data = {};
      let reglas = [];

      reglas = await context.dispatch("calcularDatosNecesarios", {
        tipo_persona: "Destinatario",
        nivel: 1,
        incluye_dependientes: true,
        id_persona: datos.id,
      });

      for (let i = 0; i < reglas.length; i++) {
        if (reglas[i][4] == "pais_moneda_destino_id") {
          let pais_moneda = context.state.remitente.destinatarios['0'][reglas[i][4]].split("_");
          data.pais_id = pais_moneda[0];
          data.moneda_id = pais_moneda[1];
        } else if (reglas[i][4] == "nombres") {
          data.nombre1 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase(); //await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "nombre1") {
          data.nombre1 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase(); // await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "nombre2") {
          data.nombre2 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase(); // await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "apellido1") {
          data.apellido1 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase(); // await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "apellido2") {
          data.apellido2 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase(); // await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "numero_documento") {
          data.numero_documento = context.state.remitente.destinatarios['0'][reglas[i][4]];
        } else if (reglas[i][4] == "correo") {
          data.correo = context.state.remitente.destinatarios['0'][reglas[i][4]];
        } else if (reglas[i][4] == "comuna_id") {
          data.comuna_id = context.state.remitente.destinatarios['0'][reglas[i][4]];
        } else if (reglas[i][4] != "region") {
          data[reglas[i][4]] = context.state.remitente.destinatarios['0'][reglas[i][4]];
        }
      }

      if (!data.nombre2 || typeof data.nombre2 == "undefined") {
        data.nombre2 = "";
      }
      if (!data.apellido2 || typeof data.apellido2 == "undefined") {
        data.apellido2 = "";
      }

      data.cliente_id = context.state.remitente.id;
      // console.log("*** new destinatario reglas", data, {
      //   tipo_persona: "Destinatario",
      //   nivel: 1,
      //   incluye_dependientes: true,
      //   id_persona: datos.id,
      // }, reglas, context.state.remitente);
      const entradasData = Object.entries(data);
      const params = new URLSearchParams();
      entradasData.forEach((entrada) => {
        params.append(entrada[0], entrada[1]);
      });

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/registrar`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null && !response.data.error) {
          
          //guardar datos en local
          context.state.remitente.destinatarios[response.data.id] = {};
          context.state.remitente.destinatarios[response.data.id] = { ...response.data.data };
          context.state.remitente.destinatarios[response.data.id].id = response.data.id;
          context.state.remitente.destinatarios[response.data.id].activo = "1";
          context.state.remitente.destinatarios[response.data.id].pais_moneda_destino_id = `${response.data.data.pais_id}_${response.data.data.moneda_id}`;
          context.state.remitente.destinatarios[response.data.id].id_cliente = response.cliente_id;

          //region
          let comuna_id_index = context.state.configuracion.pais[context.state.remitente.destinatarios['0'].pais_moneda_destino_id].comuna_id.findIndex(comuna => { return comuna[0] == response.data.data.comuna_id });
          // console.log(context.state.remitente.destinatarios['0'].pais_moneda_destino_id, response.data.data.comuna_id, comuna_id_index)
          if (comuna_id_index > -1) {
            context.state.remitente.destinatarios[response.data.id].region = context.state.configuracion.pais[context.state.remitente.destinatarios['0'].pais_moneda_destino_id].comuna_id[comuna_id_index][2];
          }

          context.dispatch("formaPagoRegistrar", { destinatario_id: response.data.id, destinatario_persona: "0" });

        }

      }).catch(err => {
        console.log(err);

      });
    },

    async primera_letra_mayuscula(context, str) {
      str = str.trim();
      return await str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    async destinatario_update(context, datos) {
      // console.log(context.state.remitente);
      context.commit("set_resp_destinatario_update", {
        status: false,
        resp: false,
        msg: "",
      });
      // fghdfghdfghfdgh
      let data = {};
      let reglas = [];

      reglas = await context.dispatch("calcularDatosNecesarios", {
        tipo_persona: "Destinatario",
        nivel: 1,
        incluye_dependientes: true,
        id_persona: datos.id,
      });

      for (let i = 0; i < reglas.length; i++) {
        if (reglas[i][4] == "pais_moneda_destino_id") {
          let pais_moneda = context.state.remitente.destinatarios['0'][reglas[i][4]].split("_");
          data.pais_id = pais_moneda[0];
          data.moneda_id = pais_moneda[1];
        } else if (reglas[i][4] == "nombres") {
          data.nombre1 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase();// await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "nombre1") {
          data.nombre1 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase();// await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "nombre2") {
          data.nombre2 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase();// await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "apellido1") {
          data.apellido1 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase();// await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "apellido2") {
          data.apellido2 = context.state.remitente.destinatarios['0'][reglas[i][4]].toUpperCase();// await context.dispatch("primera_letra_mayuscula", context.state.remitente.destinatarios['0'][reglas[i][4]]);
        } else if (reglas[i][4] == "numero_documento") {
          data.numero_documento = context.state.remitente.destinatarios['0'][reglas[i][4]];
        } else if (reglas[i][4] == "correo") {
          data.correo = context.state.remitente.destinatarios['0'][reglas[i][4]];
        } else if (reglas[i][4] == "comuna_id") {
          data.comuna_id = context.state.remitente.destinatarios['0'][reglas[i][4]];
        } else if (reglas[i][4] != "region") {
          data[reglas[i][4]] = context.state.remitente.destinatarios['0'][reglas[i][4]];
        }
      }

      data.cliente_id = context.state.remitente.id;
      data.id = datos.id;
      // console.log("*** new destinatario reglas", data, {
      //   tipo_persona: "Destinatario",
      //   nivel: 1,
      //   incluye_dependientes: true,
      //   id_persona: datos.id,
      // }, reglas, context.state.remitente);
      const entradasData = Object.entries(data);
      const params = new URLSearchParams();
      entradasData.forEach((entrada) => {
        params.append(entrada[0], entrada[1]);
      });
      // console.log("destinatario_update, data", data);
      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/editar`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        // console.log(response);

        //guardar datos en local
        let datos_array = Object.entries(response.data.data);
        datos_array.forEach(propiedad => {
          if (propiedad[0] != "moneda_id")
            context.state.remitente.destinatarios[data.id][propiedad[0]] = propiedad[1];
        });


        context.commit("set_resp_destinatario_update", {
          status: true,
          resp: true,
          msg: "xyz",
        });
      }).catch(err => {
        console.log(err);
        // context.commit("set_resp_destinatario_update", {
        //   status: false,
        //   resp: false,
        //   msg: "",
        // });
      });
    },

    async formaPagoRegistrar(context, remitente_destinatario) {
      // console.log(context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"]);
      context.commit("set_resp_forma_entrega_reg", {
        status: false,
        resp: false,
        msg: "",
      });

      let data = {};
      let reglas = [];
      let forma_entrega_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"].forma_entrega_id;

      reglas = await context.dispatch("calcularDatosNecesarios", {
        tipo_persona: "Destinatario",
        nivel: 2,
        incluye_dependientes: true,
        id_persona: remitente_destinatario.destinatario_persona,
      });

      for (let i = 0; i < reglas.length; i++) {
        if (reglas[i][4] == "banco") {
          data.bancos_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "numero_cuenta") {
          data.nro_cuenta = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "tipo_cuenta") {
          data.bancos_tipo_cuentas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "forma_entrega_id") {
          data.forma_entrega_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }
        else if (reglas[i][4] == "moneda_caja") {
          data.monedas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }
        else if (reglas[i][4] == "banco_caja") {
          data.bancos_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "comuna_caja") {
          data.comunas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "sucursales") {
          data.banco_sucursal_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }

        else if (reglas[i][4] == "bco_wallet") {
          data.bancos_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }

        else if (reglas[i][4] == "numero_cuenta_wallet") {
          data.nro_cuenta = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }

        else if (reglas[i][4] != "region_caja") {
          data[reglas[i][4]] = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }
      }
      data.destinatario_id = remitente_destinatario.destinatario_id;
      if (forma_entrega_id == "1") {
        let moneda_id_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].tipo_cuenta.findIndex(bco => { return bco[0] == data.bancos_tipo_cuentas_id });
        if (moneda_id_index > -1) {
          data.monedas_id = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].tipo_cuenta[moneda_id_index][2];
        }
      }
      if (forma_entrega_id == "6") {
        // data.monedas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"].bco_wallet[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"].bco_wallet.length - 1];
        let moneda_id_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet.findIndex(bco => { return bco[0] == data.bancos_id });
        if (moneda_id_index > -1) {
          data.monedas_id = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet[moneda_id_index][context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet[moneda_id_index].length - 2];
        }
      }

      console.log(data);
      const entradasData = Object.entries(data);
      const params = new URLSearchParams();
      entradasData.forEach((entrada) => {
        params.append(entrada[0], entrada[1]);
      });

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/agregarFormaEntrega`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log(response.data);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error) {
            let data_array = Object.entries(response.data.data);

            if (!context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega) context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega = {};
            if (!context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id]) {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id] = {};
            }
            context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].id = response.data.id;

            let banco_entrega;
            let moneda_entrega;
            let nro_cta_entrega;
            let comuna_entrega;

            await data_array.forEach((param) => {
              if (param[0] == "bancos_id") {
                banco_entrega = param[1];
              } else if (param[0] == "bancos_tipo_cuentas_id") {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].tipo_cuenta = param[1];
              } else if (param[0] == "forma_entrega_id") {
                // console.log("***********************");
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].forma_entrega_id = param[1];
              } else if (param[0] == "monedas_id") {
                moneda_entrega = param[1];
              } else if (param[0] == "nro_cuenta") {
                nro_cta_entrega = param[1];
              } else if (param[0] == "comunas_id") {
                comuna_entrega = param[1];
              } else if (param[0] == "banco_sucursal_id") {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].sucursales = param[1];
              } else {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id][param[0]] = param[1];
              }
            });
            context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].forma_entrega_id = forma_entrega_id;
            if (forma_entrega_id == "1") {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].id_banco_destino = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].banco = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].moneda_destino_id = moneda_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].numero_cuenta = nro_cta_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].cuenta_corriente = nro_cta_entrega;

              let bancos_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].banco.findIndex(bco => { return bco[0] == banco_entrega });
              if (bancos_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bancos_nombre = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].banco[bancos_index][1];
              }

            } else if (forma_entrega_id == "2") {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].banco_caja = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].moneda_caja = moneda_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].comuna_caja = comuna_entrega

              let bancos_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].banco_caja.findIndex(bco => { return bco[0] == banco_entrega });
              if (bancos_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bancos_nombre = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].banco_caja[bancos_index][1];
              }

              let region_caja_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].comuna_caja.findIndex(comuna => { return comuna[0] == comuna_entrega });
              if (region_caja_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].region_caja = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].comuna_caja[region_caja_index][2];
              }

            } else if (forma_entrega_id == "6") {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bco_wallet = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].moneda_destino_id = moneda_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].numero_cuenta_wallet = nro_cta_entrega;

              let bancos_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet.findIndex(bco => { return bco[0] == banco_entrega });
              if (bancos_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bancos_nombre = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet[bancos_index][1];
              }
            }

            context.commit("set_resp_forma_entrega_reg", {
              status: true,
              resp: true,
              destinatario_id: remitente_destinatario.destinatario_id,
              fe_id: response.data.id,
            });
          }

        }

      }).catch(err => {
        console.log(err);

      });

    },
    async forma_entrega_update(context, remitente_destinatario) {

      context.commit("set_resp_forma_entrega_update", {
        status: false,
        resp: false,
        msg: "",
      });

      let data = {};
      let reglas = [];
      let forma_entrega_id = "";

      reglas = await context.dispatch("calcularDatosNecesarios", {
        tipo_persona: "Destinatario",
        nivel: 2,
        incluye_dependientes: true,
        id_persona: remitente_destinatario.destinatario_persona,
      });

      for (let i = 0; i < reglas.length; i++) {
        if (reglas[i][4] == "banco") {
          data.bancos_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "bco_wallet") {
          data.bancos_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }
        else if (reglas[i][4] == "numero_cuenta") {
          data.nro_cuenta = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "numero_cuenta_wallet") {
          data.nro_cuenta = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "tipo_cuenta") {
          data.bancos_tipo_cuentas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "forma_entrega_id") {
          data.forma_entrega_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
          forma_entrega_id = data.forma_entrega_id;
        } else if (reglas[i][4] == "moneda_caja") {
          data.monedas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "banco_caja") {
          data.bancos_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "comuna_caja") {
          data.comunas_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] == "sucursales") {
          data.banco_sucursal_id = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        } else if (reglas[i][4] != "region_caja") {
          data[reglas[i][4]] = context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].detalle_entrega["0"][reglas[i][4]];
        }
      }
      data.id = remitente_destinatario.forma_entrega_id
      data.destinatario_id = remitente_destinatario.destinatario_id; //  
      if (forma_entrega_id == "1") {

        let moneda_id_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].tipo_cuenta.findIndex(bco => { return bco[0] == data.bancos_tipo_cuentas_id });
        if (moneda_id_index > -1) {
          data.monedas_id = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].tipo_cuenta[moneda_id_index][2];
        }
      }

      const entradasData = Object.entries(data);
      const params = new URLSearchParams();
      entradasData.forEach((entrada) => {
        params.append(entrada[0], entrada[1]);
      });

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/actualizarFormaEntrega`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error) {
            let data_array = Object.entries(response.data.data);
            if (!context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id]) {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id] = {};
            }

            let banco_entrega;
            let moneda_entrega;
            let nro_cta_entrega;
            let comuna_entrega;

            await data_array.forEach((param) => {
              if (param[0] == "bancos_id") {
                banco_entrega = param[1];
              } else if (param[0] == "bancos_tipo_cuentas_id") {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].tipo_cuenta = param[1];
              } else if (param[0] == "forma_entrega_id") {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].forma_entrega_id = param[1];
              } else if (param[0] == "monedas_id") {
                moneda_entrega = param[1];
              } else if (param[0] == "nro_cuenta") {
                nro_cta_entrega = param[1];
              } else if (param[0] == "comunas_id") {
                comuna_entrega = param[1];
              }
              else if (param[0] == "banco_sucursal_id") {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].sucursales = param[1];
              } else {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id][param[0]] = param[1];
              }
            });

            if (forma_entrega_id == "1") {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].id_banco_destino = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].banco = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].moneda_destino_id = moneda_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].numero_cuenta = nro_cta_entrega;
            } else if (forma_entrega_id == "2") {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].banco_caja = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].comuna_caja = comuna_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].moneda_caja = moneda_entrega;

              let bancos_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].banco_caja.findIndex(bco => { return bco[0] == banco_entrega });
              if (bancos_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bancos_nombre = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].banco_caja[bancos_index][1];
              }

              let region_caja_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].comuna_caja.findIndex(comuna => { return comuna[0] == comuna_entrega });
              if (region_caja_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[data.id].region_caja = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].comuna_caja[region_caja_index][3];
              }

            } else if (forma_entrega_id == "6") {
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bco_wallet = banco_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].moneda_destino_id = moneda_entrega;
              context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].numero_cuenta_wallet = nro_cta_entrega;

              let bancos_index = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet.findIndex(bco => { return bco[0] == banco_entrega });
              if (bancos_index > -1) {
                context.state.remitente.destinatarios[remitente_destinatario.destinatario_id].detalle_entrega[response.data.id].bancos_nombre = context.state.configuracion.pais[context.state.remitente.destinatarios[remitente_destinatario.destinatario_persona].pais_moneda_destino_id].bco_wallet[bancos_index][1];
              }
            }

            context.commit("set_resp_forma_entrega_update", {
              status: true,
              resp: true,
              msg: "",
            });
          }

        }

      }).catch(err => {
        console.log(err);

      });














    },

    eliminar_forma_entrega(context, data) {

      context.commit("set_resp_borrar_forma_entega", { status: false, resp: false, mge: "" });
      const params = new URLSearchParams();
      params.append("id", data.forma_entrega_id);
      // params.append("destinatario_id", data.destinatario_id);

      // console.log(data);
      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/borrarFormaEntrega`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log("/", response);

        //retirar forma de entrega de remitente.destinatarios
        delete context.state.remitente.destinatarios[data.destinatario_id].detalle_entrega[data.forma_entrega_id];
        context.commit("set_resp_borrar_forma_entega", { status: true, resp: true });

      }).catch(err => {
        console.log(err);
        context.commit("set_resp_borrar_forma_entega", { status: true, resp: false });
      });


    },

    async destinatario_borrar(context, id_destinatario) {
      // console.log(id_destinatario);
      context.commit("set_resp_borrar_destinatario", { status: false, resp: false });
      const params = new URLSearchParams();
      params.append("id", id_destinatario);
      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}destinatarios/borrar`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        // console.log("/",response.data.comunas);
        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error) {
            delete context.state.remitente.destinatarios[id_destinatario];
            context.commit("set_resp_borrar_destinatario", { status: true, resp: true });
            // console.log(context.state.remitente.destinatarios);
          } else {
            context.commit("set_resp_borrar_destinatario", { status: true, resp: false });
          }
        }
      }).catch(err => {
        console.log(err);
        context.commit("set_resp_borrar_destinatario", { status: true, resp: false });
      });
    },


    callFloidVerificaRut(context, rut) {

      const params = new URLSearchParams();
      params.append("rut", rut);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/floid`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params
      })
        .then(async (response) => {
          // console.log(response);
          let data = JSON.parse(response.data);
          // console.log(data);
          if (data && data.code == "200" && data.data) {
            let nombres_array = data.data.name.split(" ");
            for (let index = 0; index < nombres_array.length; index++) {
              nombres_array[index] = await context.dispatch("primera_letra_mayuscula", nombres_array[index]);
            }
            context.state.remitente.nombre1 = nombres_array[0];
            nombres_array.shift();
            // console.log(nombres_array);
            context.state.remitente.nombre2 = nombres_array.join(" ");
            context.state.remitente.apellido1 = await context.dispatch("primera_letra_mayuscula", data.data.father_lastname);
            context.state.remitente.apellido2 = await context.dispatch("primera_letra_mayuscula", data.data.mother_lastname);
            context.state.remitente.fecha_nacimiento = data.data.date_of_birth;
            context.state.remitente.genero = data.data.gender;

            let remoto = data.data.nationality.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
            if (remoto == "chilena" || remoto == "nacionalizada") {
              context.state.remitente.nacionalidad = "152";
            }
            context.state.remitente.marital_status = data.data.marital_status;

            context.commit("set_resp_floid_verifica_rut", {
              status: true,
              resp: true,
              msg: "OK",
            });


            // console.log(context.state.remitente);
            let ObjetoResultados = {};
            ObjetoResultados.id = context.state.remitente.id;
            ObjetoResultados.apellido1 = context.state.remitente.apellido1;
            ObjetoResultados.apellido2 = context.state.remitente.apellido2;
            ObjetoResultados.genero = context.state.remitente.genero;
            ObjetoResultados.estado_marital = context.state.remitente.marital_status;
            ObjetoResultados.nacionalidad_pais_id = context.state.remitente.nacionalidad;
            ObjetoResultados.nombre1 = context.state.remitente.nombre1;
            ObjetoResultados.nombre2 = context.state.remitente.nombre2;
            ObjetoResultados.residencia_pais_id = context.state.remitente.residencia;
            let fecha_nac_array = context.state.remitente.fecha_nacimiento.split("-");
            ObjetoResultados.fecha_nacimiento = `${fecha_nac_array[2]}-${fecha_nac_array[1]}-${fecha_nac_array[0]}`;
// console.log(ObjetoResultados);
            console.log(ObjetoResultados);
            const entradasData = Object.entries(ObjetoResultados);
            const params = new URLSearchParams();
            entradasData.forEach((entrada) => {
              params.append(entrada[0], entrada[1]);
            });
            Axios({
              method: 'POST',
              url: `${context.state.link_controlador}cliente/editar`,
              headers: {
                'Access-Control-Allow-Headers': 'Authorization',
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${context.state.remitente.token}`,
              },
              data: params,
            }).then(async (resp) => {
              console.log(resp.data);
            }).catch(err => {
              console.log(err);
            });


          } else {
            context.state.remitente.nombre1 = "";
            context.state.remitente.nombre2 = "";
            context.state.remitente.apellido1 = "";
            context.state.remitente.apellido2 = "";

            context.commit("set_resp_floid_verifica_rut", {
              status: true,
              resp: true,
              msg: `response: ${response}`,
            });
          }

        })
        .catch(function (error) {
          console.log(error);
          context.commit("set_resp_floid_verifica_rut", {
            status: true,
            resp: true,
            msg: `error: ${error}`,
          });
        });
    },

    limpiar_datos_web_enviar(context, dato) {
      document.cookie = `${dato}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
    },


    signOff(context) {

      document.cookie = `gf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
      document.cookie = `gf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
      document.cookie = `gf_token=; path=${context.state.cookie_domain}`;
      document.cookie = `gf_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
      document.cookie = `gf_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
      document.cookie = `gf_name=; path=${context.state.cookie_domain}`;
      document.cookie = `gf_document=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
      document.cookie = `gf_document=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${context.state.cookie_domain}`;
      document.cookie = `gf_document=; path=${context.state.cookie_domain}`;

      context.commit("set_state_inicial");

      setTimeout(() => {

        context.commit("updateBloquearNavegacion", false);
        context.dispatch("traer_paises");
        context.dispatch("traer_datos_simulacion");
        context.commit("setHeigthScreen", window.innerHeight);
        // context.commit("set_current_domain", window.location.origin);
        if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "dev") {
          context.commit("set_url_base_web", "http://localhost:3000");
        } else {
          context.commit("set_url_base_web", "https://girofacil.com");
        }
      }, 300);

      setTimeout(() => {
        context.commit("set_resp_signOff", {
          status: true,
          resp: true
        });
        context.commit("setshowSpinner", false);
      }, 600);

    },

    callFintoc() {
      Axios({
        method: 'GET',
        url: 'https://api.fintoc.com/v1/links/link_zwA3XWYiZMgQkg4o_token_B6NxV2TSKXpttd6Cnsa6Y1ES',
        headers: {
          "Authorization": 'sk_test_8JLgRVoxGNzpuqHgXyHjsUkxCXnMgABj',
          'Content-Type': 'application/json',
        }
      }).then(async (response) => {
        console.log("1", response);


      }).catch(err => {
        console.log(err);

      });
    },

    createBankTransfer(context, datos) {

      context.commit("setFintocPaymentIntent", { status: false, resp: false, widget_token: '' });
      const paymentIntent = {
        amount: datos.amount,
        currency: 'clp',
        recipient_account: {
          holder_id: '416148503',
          number: '123123123',
          type: 'checking_account',
          institution_id: 'cl_banco_scotiabank',

        },
      };
      Axios({
        method: 'POST',
        url: 'https://api.fintoc.com/v1/payment_intents',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'sk_test_8JLgRVoxGNzpuqHgXyHjsUkxCXnMgABj',
        },
        data: JSON.stringify(paymentIntent),
      }).then(async (response) => {
        // console.log("createBankTransfer: ", response);
        context.commit("setFintocPaymentIntent", { status: true, resp: true, widget_token: response.data.widget_token });

      }).catch(err => {
        console.log(err);
        context.commit("setFintocPaymentIntent", { status: true, resp: false, widget_token: '' });
      });
    },

    async padTo2Digits(context, num) {
      return num.toString().padStart(2, '0');
    },

    async formatDate(context, date) {
      return (
        [
          await date.getFullYear(),
          await context.dispatch("padTo2Digits", date.getMonth() + 1),
          await context.dispatch("padTo2Digits", date.getDate()),
        ].join('-') +
        ' ' +
        [
          await context.dispatch("padTo2Digits", date.getHours()),
          await context.dispatch("padTo2Digits", date.getMinutes()),
          await context.dispatch("padTo2Digits", date.getSeconds()),
        ].join(':')
      );
    },

    async confirmTransaction(context) {

      let descuento_enviar = context.state.transaccion.descuento ? context.state.transaccion.descuento : 0;
      let monto_destino_enviar;
      monto_destino_enviar = ((context.state.transaccion.monto_pagar - (context.state.transaccion.giro_gastos_envio + descuento_enviar)) * context.state.transaccion.valor_cambio).toFixed(2);

      let obj_transaccion = {
        cliente: context.state.remitente.id,
        destinatario: context.state.transaccion.id_persona,
        monto_inicio: context.state.transaccion.monto_pagar,
        monto_destino: monto_destino_enviar,
        moneda_origen: context.state.transaccion.moneda_origen_id,
        moneda_destino: context.state.transaccion.moneda_destino_id,
        tasa_cliente_id: context.state.transaccion.tasa_transaccion_id,
        tasa_cliente_valor: context.state.transaccion.valor_cambio,
        pais_origen: context.state.transaccion.pais_remitente.split("_")[0],
        pais_destino: context.state.transaccion.pais_destino.split("_")[0],
        nombre_sucursal_argenper: "",
        codigo_sucursal_argenper: "",
        destinatario_forma_entrega: context.state.transaccion.detalle_entrega_id,
        formas_pago: "1",
        cupon_id: "",
        motivo_transaccion_id: context.state.transaccion.motivo_transaccion,
        mensaje_destinatario: context.state.transaccion.mensaje,
        costo_servicio_id: context.state.transaccion.costos_servicio_id,
        costo_servicio: context.state.transaccion.giro_gastos_envio,

        cupon: context.state.transaccion.cupon,
        descuento: descuento_enviar,
      }

      // console.log(context.state.transaccion,  obj_transaccion);

      const entradasData = Object.entries(obj_transaccion);
      const params = new URLSearchParams();
      entradasData.forEach((entrada) => {
        params.append(entrada[0], entrada[1]);
      });

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}transacciones/agregar`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {

        if (typeof response.data != "undefined" && response.data != null) {
          if (!response.data.error) {
            // console.log(response.data);

            context.commit("updateRespInscribeGiro", { status: true, resp: true, msg: "" });
          } else {
            // console.log(response.data);

            context.commit("updateRespInscribeGiro", { status: true, resp: false, msg: "err" });
          }

        }

      }).catch(err => {
        console.log(err);
        context.commit("updateRespInscribeGiro", { status: true, resp: false, msg: err });
      });

    },

    // async getCookie(context, cname) {
    //   let name = cname + "=";
    //   let decodedCookie = await decodeURIComponent(document.cookie);
    //   let ca = decodedCookie.split(";");
    //   for (let i = 0; i < ca.length; i++) {
    //     let c = ca[i];
    //     while (c.charAt(0) == " ") {
    //       c = c.substring(1);
    //     }
    //     if (c.indexOf(name) == 0) {
    //       return c.substring(name.length, c.length);
    //     }
    //   }
    //   return "";
    //   // setTimeout(() => {
    //   //   return "";
    //   // }, 550);

    // },

    async download(context, archivo) {
      // let archivo = "";
      // if(doc == "manual_modelo_prev_delito"){
      //   archivo = "Manual del Modelo de Prevención de Delitos";
      // }else if(doc=='cod_etica_conducta'){
      //   archivo = "Código de Ética y Conducta";
      // }
      // else if(doc=='form_decla_fondos'){
      //   archivo = "DECLARACIÓN ORIGEN DE FONDOS";
      // }
      // else if(doc=='form_pep'){
      //   archivo = "Declaración PEP";
      // }

      Axios(`/src/assets/data/${archivo}.pdf`, {
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    enviar_mensaje_sac(context, data) {
      // const entradasData = Object.entries(obj_transaccion);
      const params = new URLSearchParams();
      params.append("rut", context.state.remitente.numero_documento);
      params.append("mensaje", data);


      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}auxiliares/mensaje`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log(response);
        // if (typeof response.data != "undefined" && response.data != null) {
        //   if (!response.data.error) {

        //     context.commit("updateRespInscribeGiro", { status: true, resp: true, msg: "" });
        //   } else {
        //     console.log(response.data);

        //     context.commit("updateRespInscribeGiro", { status: true, resp: false, msg: "err" });
        //   }

        // }

      }).catch(err => {
        console.log(err);
        // context.commit("updateRespInscribeGiro", { status: true, resp: false, msg: err });
      });
    },

    send_cod_change_mail(context) {
      context.commit("set_resp_send_cod_change_mail", {
        status: false,
        resp: false
      });
      const params = new URLSearchParams();
      params.append("rut", context.state.remitente.numero_documento);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/codigocorreo`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        if (!response.data.error) {
          console.log(response);
          context.commit("set_resp_send_cod_change_mail", {
            status: true,
            resp: true
          });
        } else {
          context.commit("set_resp_send_cod_change_mail", {
            status: true,
            resp: true,
            msg: response.data.codigo
          });
        }
      }).catch(err => {
        console.log(err);

      });
    },

    verificar_codigo_logued(context, data) {
      // console.log(data);

      context.commit("set_respCodigoValidaMail", {
        status: false,
        resp: false,
        msj: "",
      });

      context.commit("setshowSpinner", true);

      const params = new URLSearchParams();
      params.append("rut", context.state.remitente.numero_documento);
      params.append("codigo", data);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/verificaCodigoCorreo`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log(response);
        context.commit("set_respCodigoValidaMail", {
          status: true,
          resp: true,
          msj: "",
        });
      }).catch(err => {
        console.log(err);
        context.commit("set_respCodigoValidaMail", {
          status: true,
          resp: false,
          msj: "",
        });
      });


    },
    re_send_cod_registro(context) {
      const params = new URLSearchParams();
      params.append("rut", context.state.remitente.numero_documento);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/codigoregistro`,
        headers: {
          // 'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          // Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log(response);

      }).catch(err => {
        console.log(err);

      });
    },
    re_send_cod_recover_pass(context) {
      // console.log(context.state.rut_forgot_pass);

      const params = new URLSearchParams();
      params.append("rut", context.state.rut_forgot_pass);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/recuperarclave`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params
      }).then(async (response) => {
        console.log(response);

      }).catch(err => {
        console.log(err);

      });
    },
    verificar_codigo_recover_pass(context, codigo) {
      context.commit("updateRespforgotPass2", {
        status: false,
        resp: false,
        msj: "",
      });

      context.commit("setshowSpinner", true);

      const params = new URLSearchParams();
      params.append("rut", context.state.rut_forgot_pass);
      params.append("codigo", codigo);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/verificaCodigo`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        // console.log("rut", context.state.rut_forgot_pass, "codigo", codigo, response.data);
        if (!response.data.error) {
          context.commit("updateRespforgotPass2", {
            status: true,
            resp: true,
            msj: response.data.message,
          });
        } else {
          context.commit("updateRespforgotPass2", {
            status: true,
            resp: false,
            msj: response.data.codigo,
          });
        }

      }).catch(err => {
        console.log(err);
        context.commit("updateRespforgotPass2", {
          status: true,
          resp: false,
          msj: "",
        });
      });
    },
    re_send_cod_cambio_mail(context) {
      const params = new URLSearchParams();
      params.append("rut", context.state.remitente.numero_documento);

      Axios({
        method: 'POST',
        url: `${context.state.link_controlador}cliente/codigocorreo`,
        headers: {
          'Access-Control-Allow-Headers': 'Authorization',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${context.state.remitente.token}`,
        },
        data: params
      }).then(async (response) => {
        console.log(response);

      }).catch(err => {
        console.log(err);

      });
    },

  },

  mutations: {
    set_state(state, data) {
      let prop = data.prop;
      state[prop] = data.val;
    },
    set_state_inicial(state) {

      const ObjGiroFacil_temp = ObjGiroFacil();
      Object.keys(ObjGiroFacil_temp).forEach(key => { state[key] = ObjGiroFacil_temp[key] })

    },
    set_to_web(state, data) {
      state.to_web = data;
    },

    set_resp_signOff(state, data) {
      state.resp_signOff = data;
    },
    load_paises_obj_gf_base(state, paises) {
      state.obj_gf_base.paises = paises;
    },
    load_data_base_obj_gf_base(state, data_base) {
      state.obj_gf_base.data_base = data_base;
    },
    set_pre_values_cotizador(state, valores) {
      if (valores.monto_inicio) {
        state.monto_inicio = valores.monto_inicio;
      }
      if (valores.pais_destino_inicial) {
        state.pais_destino_inicial = valores.pais_destino_inicial;
      }
    },
    set_pre_values_cotizador_BD(state, valores) {
      if (valores.monto_inicio) {
        state.monto_inicio_BD = valores.monto_inicio;
      }
      if (valores.pais_destino_inicial) {
        state.pais_destino_inicial_BD = valores.pais_destino_inicial;
      }
    },
    set_resp_create_config_others(state, obj) {
      state.resp_create_config_others = obj;
    },
    set_resp_forma_entrega_update(state, obj) {
      state.resp_forma_entrega_update = obj;
    },
    set_resp_destinatario_update(state, obj) {
      state.resp_destinatario_update = obj;
    },
    set_resp_forma_entrega_reg(state, obj) {
      state.resp_forma_entrega_reg = obj;
    },
    set_resp_borrar_forma_entega(state, obj) {
      state.resp_borrar_forma_entega = obj;
    },
    set_resp_validar_codigo_registro(state, obj) {
      state.resp_validar_codigo_registro = obj;
    },
    set_resp_floid_verifica_rut(state, data) {
      state.resp_floid_verifica_rut = data;
    },
    update_datos_consultados(state, data) {
      if (!state.datos_consultados[data.pais]) {
        state.datos_consultados[data.pais] = {};
      }
      if (!state.datos_consultados[data.pais][data.regiones_bancos]) {
        state.datos_consultados[data.pais][data.regiones_bancos] = {};
      }
      let time_stop = new Date().getTime();
      state.datos_consultados[data.pais][data.regiones_bancos].last_refresh = time_stop;
      state.datos_consultados[data.pais][data.regiones_bancos].status = data.status;
    },
    // set_current_domain(state, domain) {
    //   state.current_domain = domain;
    // },
    set_respCodigoValidaMail(state, obj) {
      state.respCodigoValidaMail = obj;
    },

    set_dataResumen(state, data) {
      state.dataResumen = data;
    },
    set_resp_reload_tasas_costo_serv_pausa(state, estado) {
      state.resp_reload_tasas_costo_serv_pausa = estado;
    },
    //--------------------------------------------------------
    updateDesde(state, desd) {
      state.desde = desd;
    },
    setshowSpinner(state, value) {
      state.showSpinner = value;
    },
    updateConfiguracion(state, objConfiguracion) {
      state.configuracion = { ...state.configuracion, ...objConfiguracion };
    },
    updateCardBodyPaises(state, html) {
      state.cardBodyPaises = html;
    },

    updateIdiomaPreferencia(state, idioma) {
      state.idiomaPreferencia = idioma;
    },
    updateRespLogIn(state, respuesta) {
      state.respLogIn = respuesta;
    },
    updateRemitente(state, data) {
      state.remitente = { ...state.remitente, ...data };
    },
    limpiarRemitente(state, data) {
      state.remitente = data;
    },
    updateFaltanDatos(state, estado) {
      state.faltanDatos = estado;
    },
    updateSessionConfirm(store, number) {
      store.session_confirm = store.session_confirm + number;
    },
    updateBloquearNavegacion(state, estado) {
      state.bloquearNavegacion = estado;
    },
    updateRespCodigoValidaMailLogin(state, data) {
      state.respCodigoValidaMailLogin = data;
    },
    updateRespCodigoValidaMailRegis(state, data) {
      state.respCodigoValidaMailRegis = data;
    },
    updateListaElementosDom(state, lista) {
      state.listaElementosDom = lista;
    },
    updateListaElementosDomSpPropiedad(state, data) {

      state.listaElementosDom[data.index][data.propiedad] = data.valor;
    },
    updateListaElementosDomSpPropiedadDirTodo(state, data) {

      state.listaElementosDom[data.index].elementos = data.valor;
    },
    updateListaElementosDomSpPropiedadDirCero(state, data) {

      state.listaElementosDom[data.index].elementos[0][
        data.propiedad
      ] = data.valor;
      console.log(data, state.listaElementosDom[data.index]);
    },
    updateListaElementosDomSpElemento(state, data) {

      state.listaElementosDom[data.index] = data.valor;
    },
    set_resp_pre_registro(state, data) {
      state.resp_pre_registro = data;
    },
    updateFocus(state, data) {
      state.focusGlobal = data;
    },

    updateInfoNodos(state, info) {
      state.infoNodos = info;
    },
    setRemitenteTemp(state) {
      let remitente = Object.entries(state.remitente);
      remitente.forEach((item) => {
        if (
          item[0] != "destinatarios" &&
          item[0] != "ultimas_transacciones" &&
          item[0] != "validacion_mensual"
        ) {
          state.remitenteTemp[item[0]] = item[1];
        }
      });
    },
    updateRespRemitenteModif(state, estado) {
      state.respRemitenteModif = estado;
    },
    dropRemitenteTemp(state) {
      state.remitenteTemp = {};
    },
    updateRespforgotPass(state, resp) {
      state.respForgotPass = resp;
    },
    updateRespforgotPass2(state, resp) {
      state.respForgotPass2 = resp;
    },
    updateRespforgotPass3(state, resp) {
      state.respForgotPass3 = resp;
    },
    updateRespForgotPass_sin_log(state, resp) {
      state.respForgotPass_sin_log = resp;
    },
    reset_transaccion(state) {
      state.transaccion = {
        porcentaje_descuento: 0,
        descuento: 0,
        pais_remitente: "152_1",
        region: 0,
        region_nombre: "",
        comuna_nombre: "",
        entidades_nombre: "",
        codigo_agencia: "0",
        tipo_cuenta_nombre: "",
        tipo_documento_nombre: "",
        codigo_agencia_brasil: "",
        motivo_transaccion: "",
        tipo_pago: 1,
        mensaje: ""
      };
    },
    async updateTransaccion(state, objTransaccion) {

      let newData = Object.entries(objTransaccion);
      newData.forEach((element) => {
        state.transaccion[element[0]] = element[1];
      });

    },
    updateCostoPais(state, costos_paisTemp) {
      state.costos_pais = costos_paisTemp;

    },
    updateRespCupon(state, respuesta) {
      state.respCupon = respuesta;
    },
    updateRespSelectDestinatario(state, resp) {
      state.respSelectDestinatario = resp;
    },
    updateRemitenteDestinatarios(state, data) {
      if (state.remitente && state.remitente.destinatarios) {
        state.remitente.destinatarios['0'] = data;
      }

    },
    updateRemitenteDestinatariosFormeEntrega(state, data) {

      if (!state.remitente.destinatarios[data.destinatario_id].detalle_entrega) {
        state.remitente.destinatarios[data.destinatario_id].detalle_entrega = {};
      }
      state.remitente.destinatarios[data.destinatario_id].detalle_entrega['0'] = data.valor;
    },
    async destinatarioDatosUnificados(state) {
      for (
        let index = 0;
        index < state.configuracion.reglasFormularioDestinatario.length;
        index++
      ) {
        state.configuracion.reglasFormularioDestinatario[index][3] = 1;
      }
    },
    updateDataNewDestinatarioRegistrado(state, data) {
      state.dataNewDestinatarioRegistrado = data;
    },
    updateProcesoGeneral(state, etapa) {
      state.procesoGeneral = etapa;
    },
    updateMenuSpDesde(state, desde) {
      state.menuSpDesde = desde;
    },
    updateMenuSpDesde_menu_sp(state, menuSp) {
      state.menuSpDesde.menuSp = menuSp;
    },
    updateDataDestinatarioSelected(state, data) {
      state.dataDestinatarioSelected = { ...data };
    },
    updateDataDestinSelected(state, data) {
      state.dataDestinSelected = data;
    },
    setDestinatarioTemp(state, data) {

      state.remitente.destinatarios["0"] = {};
      let entradas = Object.entries(state.remitente.destinatarios[data]);
      entradas.forEach((element) => {
        if (state.dataDestinSelected.indexFE && element[0] == "detalle_entrega") {
          if (!state.remitente.destinatarios["0"].detalle_entrega) state.remitente.destinatarios["0"].detalle_entrega = {};
          if (!state.remitente.destinatarios["0"].detalle_entrega["0"]) state.remitente.destinatarios["0"].detalle_entrega["0"] = {};
          if (state.dataDestinSelected.indexFE != "0") {
            let detalle = Object.entries(state.remitente.destinatarios[data].detalle_entrega[state.dataDestinSelected.indexFE]);
            detalle.forEach(det => {
              state.remitente.destinatarios["0"].detalle_entrega["0"][det[0]] = det[1];
            });
          }
        } else {
          let valor = element[1];
          state.remitente.destinatarios["0"][element[0]] = valor;
        }

      });

    },
    updateRespBeneficiarioModif(state, estado) {
      state.respBeneficiarioModif = estado;
    },
    updateListaElementosDomTemp(state, lista) {
      state.listaElementosDomTemp = lista;
    },
    setFintocPaymentIntent(state, data) {
      state.fintocPaymentIntent = data;
    },
    setHeigthScreen(state, vh) {
      state.heigthScreen = vh;
    },
    setWidthScreen(state, vw) {
      state.widthScreen = vw;
    },
    set_accept_term_cond(state, estado) {
      state.accept_term_cond = estado;
    },
    set_accept_poli_priv(state, estado) {
      state.accept_poli_priv = estado;
    },
    set_accept_consulta_reg_civil(state, estado) {
      state.accept_consulta_reg_civil = estado;
    },
    updateRespInscribeGiro(state, data) {
      state.respInscribeGiro = data;
    },
    set_nav_stack(state, obj) {
      state.nav_stack = obj;
    },
    set_plataforma(state, plat) {
      state.plataforma = plat;
    },
    set_resp_borrar_destinatario(state, data) {
      state.resp_borrar_destinatario = data;
    },
    set_data_mostrar_error(state, data) {
      state.data_mostrar_error = data;
    },
    // set_registro_diclaimer(state, data){
    //   state[data.propiedad] = data.valor;
    // },
    set_resp_send_cod_change_mail(state, data) {
      state.resp_send_cod_change_mail = data;
    },
    set_url_base_web(state, data) {
      state.url_base_web = data;
    },
    set_url_base_app(state, data) {
      state.url_base_app = data;
    },
  },
});
